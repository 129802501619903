<template>
  <div class="login">
    <img class="topBg" src="../assets/img/login/topBg.png" />
    <img class="navBack" @click="goOther('/home')" src="../assets/img/navBack.png" />
    <img class="customer" @click="goOther('/customerService')" src="../assets/img/customer.png" />
    <div v-if="baseInfo && baseInfo.loginUrl" class="head">
      <img v-real-img="baseInfo.loginUrl">
      <p>{{ getDomain(baseInfo.floorUrl) }}</p>
    </div>

    <div class="entryMain">
      <div class="login_title">
        <div>{{$t("login.title")}}</div>
      </div>
      <div class="login_normalContent">
        <div class="login_inputBox">
          <van-form @submit="login">
            <van-field v-model.trim="loginForm.username" :maxlength="10" autocomplete="off" :placeholder="`${$t('login.userAccountTips')}`"/>
            <van-field v-model.trim="loginForm.password" :maxlength="16" v-show="isShowPsw" autocomplete="off" type="password" @click-right-icon="changePswType(false)" 
              :right-icon="require('../assets/img/eyeClose.png')" :placeholder="`${$t('login.userPasswordTips')}`"/>
            <van-field  v-model.trim="loginForm.password" :maxlength="16" autocomplete="off" v-show="!isShowPsw" @click-right-icon="changePswType(true)" 
            :right-icon="require('../assets/img/eyeOpen.png')"  :placeholder="`${$t('login.userPasswordTips')}`"/>
            <div class="login_otherOperate flexCenterBL">
              <div @click="forgotpsw">{{$t("login.forgetPsw")}}</div>
              <div @click="goOther('/register')">{{$t("register.title")}}</div>
            </div>
            <div class="submitBtnBox">
              <van-button class="submitBtn" round v-show="!isloginGo" block color="#eb457e" native-type="submit">{{$t("login.title")}}</van-button>
              <van-button v-show="isloginGo" round block color="#eb457e" native-type="submit">{{$t("login.title")}}</van-button>
            </div>
          </van-form>
        </div>
      </div>
    </div>

    <img class="bottomBg" src="../assets/img/login/bottomBg.png" />
  </div>
</template>

<script>
import { phoneLogin, emailLogin, getInfo, phoneIsRegiste, emailIsRegiste } from "@/api/index";
import md5 from 'js-md5';
import utils from "@/utils/index";
import { mapState } from 'vuex';
export default {
  data() {
    return {
      loginForm: {
        password: "",
        username: ""
      },
      isShowPsw: true,
      pswIcon: require("../assets/img/password.png"),
      userIcon: require("../assets/img/userIcon.png"),
      isremeber: true
    }
  },
  created() {
    this.$store.commit("setState", { entry: this.$route.path });
    if (localStorage.getItem('dlInfo')) {
      let dlInfo = JSON.parse(localStorage.getItem('dlInfo'));
      this.loginForm.username = dlInfo.account;
    }
  },
  computed: {
    ...mapState(['baseInfo', 'configBadge']),
    isloginGo() {
      if (this.loginForm.password && this.loginForm.username) {
        return true;
      }
      return false;
    },
    isLogin() {
      return this.$store.getters.isLogin
    }
  },
  methods: {
    // 域名处理
    getDomain(val) {
      let index = val.indexOf('http://'), index1 = val.indexOf('https://'), str = val;
      if (index != -1) {
        str = str.substr(7);
      }

      if (index1 != -1) {
        str = str.substr(8);
      }
      return str;
    },
    // 忘记密码
    forgotpsw() {
      let phonerReg = /^0\d{9}$/, phoneFlag = false, 
        emailReg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/, emailFlag = false,
        type = 1;
      if(phonerReg.test(this.loginForm.username)) {
        phoneFlag = true;
      }

      if(emailReg.test(this.loginForm.username)) {
        emailFlag = true;
        type = 2;
      }

      if (!phoneFlag && !emailFlag) {
        this.$toast(this.$t("login.loginAccountError"));
      } else {
        this.isRegiste(type);
      }
    },
    // 手机号/ 邮箱 是否注册
    isRegiste(type) {
      // type 1 手机 2邮箱
      let fun = null, param = {};
      if (type == 1) {
        param.mobile = this.loginForm.username;
        fun = phoneIsRegiste;
      } else {
        param.email = this.loginForm.username;
        fun = emailIsRegiste;
      }
      fun(param).then(res => {
        if (res.code === 0 || res.code === '0') {
          if (res.data == 1) {
            this.goOther(`/findPassword?username=${this.loginForm.username}`);
          } else {
            this.$toast(this.$t("login.noHasAccount"));
          } 
        } else {
          if (res.msg) this.$toast(res.msg);
        }
      }).catch(() => {})
    },
    goOther(path) {
      this.$router.push(path);
    },
    // 密码登录
    login() {
      // 账号校验
      let phonerReg = /^0\d{9}$/, phoneFlag = false, 
        emailReg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/, emailFlag = false;
      if(phonerReg.test(this.loginForm.username)) {
        phoneFlag = true;
      }

      if(emailReg.test(this.loginForm.username)) {
        emailFlag = true;
      }

      if (!phoneFlag && !emailFlag) return this.$toast(this.$t("login.loginAccountError"));

      if (!this.loginForm.password) return;

      let uid = localStorage.getItem('uid') || utils.guid(), fun = null;
      let param = {
        os: 0,
        sign: md5(`${uid}jgyh,kasd${new Date().getTime()}`),
        timestamp: new Date().getTime(),
        udid: localStorage.getItem('uid') || utils.guid(),
        model: utils.getMobileOperatingSystem(),
        password: this.loginForm.password,
        version: "1.0.2",
        softVersion: "1.1.3",
        language: 'THAI'
      }
      // 手机号
      if (phoneFlag) {
        param.mobile = this.loginForm.username;
        fun = phoneLogin;
      } else {
        param.email = this.loginForm.username;
        fun = emailLogin;
      }

      this.$store.commit("setState", { isLoadForLoad: true, loginAccount: this.loginForm.username });
      fun(param).then(res => {
        if (res.code === 0 || res.code === '0') {
          let dlInfo = {
            account: this.loginForm.username
          },
          liveAE = {
            randomKey: res.data.randomKey,
            randomVector: res.data.randomVector
          }
          sessionStorage.setItem("token", res.data.token);
          localStorage.setItem('dlInfo', JSON.stringify(dlInfo));

          this.$store.commit("setState", { liveAE });

          setTimeout(() => {
            this.getInfo();
            sessionStorage.removeItem("showAdvertise");
          }, 500);
        } else {
          if (res.code === 1040) {
            this.$router.push('/accountSecurity')
          } else {
            if (res.msg) this.$toast(res.msg);
          }

          this.$store.commit("setState", { isLoadForLoad: false });
        }
      }).catch(() => {
        this.$store.commit("setState", { isLoadForLoad: false });
      });
    },
    // 获取用户详情
    getInfo() {
      getInfo().then(res => {
        if (res.code === 0 || res.code === '0') {
          let member = JSON.parse(JSON.stringify(res.data));
          // 这里回导致下边代码 进行不下去 , badgeShowList = []
          // if (member.badgeList && member.badgeList.length > 0 && this.configBadge.length > 0) {
          //   badgeShowList = utils.matchBadgeList(member.badgeList, this.configBadge);
          // }
          // member.badgeShowList = JSON.parse(JSON.stringify(badgeShowList));
          this.$store.commit("setState", { member });
          let that = this;
          setTimeout(() => {
            if (that.$store.state.tim) {
              that.$store.dispatch("loginTm");
            } else {
              that.$store.dispatch("initTm");
            }
          }, 100)
          this.$router.push("/home");
        } else {
          if (res.msg) this.$toast(res.msg);
        }
        this.$store.commit("setState", { isLoadForLoad: false });
      }).catch(err => {
        console.log(err);
        this.$store.commit("setState", { isLoadForLoad: false });
      });
    },
    onSubmit() {
      console.log(666);
    },
    changePswType(val) {
      this.isShowPsw = val;
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
    width: 100%;
    min-height: 100vh;
    -webkit-transition: .3s;
    transition: .3s;
    overflow-x: hidden;
    overflow-y: auto;
    background-size: cover;
    background-image: url("../assets/img/login/loginbg.png");
    overflow: hidden;
    position: relative;
    padding-top: 2rem;

    .customer {
      width: 0.5rem;
      position: absolute;
      top: 0.36rem;
      right: 0.24rem;
      z-index: 2;
    }

    .navBack {
      width: 0.44rem;
      position: absolute;
      top: 0.36rem;
      left: 0.24rem;
      z-index: 2;
    }

    .head {
      width: 100%;
      box-sizing: border-box;
      display: block!important;
      font-size: 0.28rem;
      color: #fff;
      position: relative;
      z-index: 2;

      img {
        height: 1.04rem;
      }
    }

    .topBg {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
    }

    .entryMain {
      position: relative;
      width: 100vw;
      width: 7.02rem;
      margin: 0.4rem auto 0;
      border-radius: 0.2rem;
      overflow: hidden;
      z-index: 2;
      // background-color: rgba(0,0,0,.4);

      .login_title {
        line-height: 0.96rem;
        text-align: center;
        font-size: .36rem;
        color: #fff;
        font-weight: 550;
        display: -webkit-box; /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
        display: -moz-box; /* Firefox 17- */
        display: -webkit-flex; /* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
        display: -moz-flex; /* Firefox 18+ */
        display: -ms-flexbox; /* IE 10 */
        display: flex; /* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */
        // border-bottom: 2px solid rgba(216,216,216,.4);

        align-items:center;
        -webkit-align-items:center;
        box-align:center;
        -moz-box-align:center;
        -webkit-box-align:center;

        div {
          -webkit-flex: 1 1;
          flex: 1 1;

          &.active {
            background-color: rgba(255,255,255,.3);
          }
        }
      }

      .login_normalContent {
        box-sizing: border-box;
        border-radius: 0.2rem;
        padding-top: 0.2rem;

        .login_inputBox {
          .suTitle {
            font-size: 0.28rem;
            color: #fff;

            span {
              flex: 1;
              text-align: center;

              &.active {
                color: #5e91ff;
              }
            }
          }
          .login_otherOperate {
            width: 6.1rem;
            box-sizing: border-box;
            font-size: .2rem;
            color: #fff;
            margin: 0.22rem auto 0.4rem;
            font-size: 0.28rem;
            letter-spacing: .02rem;
            position: relative;
            text-align: left;

            div:nth-child(1) {
              padding-right: 0.2rem;
              border-right: 2px solid #eb457e;
            }

            div:nth-child(2) {
              padding-left: 0.2rem;
            }
          }
        }
      }
    }

    .submitBtn {
      opacity: 0.6;
    }

    .submitBtnBox {
      margin: 0 auto;
      width: 4.24rem;
    }

  .bottomBg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}
</style>

<style lang="scss">
  .login {
    .van-cell {
      margin: 0.2rem 0.4rem 0;
      padding: 0.2rem 0.25rem;
      box-sizing: border-box;
      width: calc(100% - 0.8rem);
      background-color: transparent;
      color: #fff;
      border-bottom: 1px solid #eb457e;

      &.vcode {
        padding: 0.1rem 0.2rem;
      }

      &::after {
        border-color: transparent;
      }

      .van-cell__value--alone {
        color: #fff;
      }

      .van-field__control {
        color: #fff;

        &:-webkit-autofill , textarea:-webkit-autofill, select:-webkit-autofill {
          color: #fff!important;
          caret-color: #fff!important;
          box-shadow: 0 0 0px 1000px transparent  inset !important;
          -webkit-box-shadow: 0 0 0px 1000px transparent  inset !important;
          -webkit-text-fill-color: #fff !important;
          background-color: transparent!important;
          background-image: none;
          transition: background-color 50000s ease-in-out 0s; //背景色透明  生效时长  过渡效果  启用时延迟的时间
        }
      }

      .van-field__left-icon {
        display: -webkit-box; /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
        display: -moz-box; /* Firefox 17- */
        display: -webkit-flex; /* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
        display: -moz-flex; /* Firefox 18+ */
        display: -ms-flexbox; /* IE 10 */
        display: flex; /* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */
      
        align-items:center;
        -webkit-align-items:center;
        box-align:center;
        -moz-box-align:center;
        -webkit-box-align:center;
      }

      .van-field__right-icon {
        color: #fff;
      }
    }
  }
</style>