import { createRouter, createWebHistory } from 'vue-router';
// import { Toast } from "vant";
// 待定是否需要
import store from "@/store/index";
import maintainData from "@/assets/maintain";
import home from "../pages/home/index.vue";
import homeBanner from "../pages/home/bannerDetail.vue";
import leaderboard from "../pages/home/leaderboard.vue";
import discount from "../pages/discount/index.vue";
import customerService from "../pages/customerService/index.vue";
import game from "../pages/game/index.vue";
import my from "../pages/my/index.vue";
import bindPhone from "../pages/my/bind/phone.vue";
import userInfo from "../pages/my/userInfo.vue"
import online from "../pages/customerService/sevice.vue";
import editUserInfo from "../pages/my/editUserInfo.vue";
import login from "../pages/login.vue";
import accountSecurity from "../pages/accountSecurity.vue";
import register from "../pages/register.vue";
import setting from "../pages/my/setting.vue";
import setting_next from "../pages/my/setting_next.vue";
import blackList from "../pages/my/blacklist.vue";
import setting_next_edit from "../pages/my/setting_next_edit.vue";
import mail from "../pages/my/mail.vue";
import mailDetail from "../pages/my/mailDetail.vue";
import addCards from "../pages/my/addCards.vue";
import gameReport from "../pages/record/gameReport.vue";
import gameReportNext from "../pages/record/gameReportNext.vue";
import recordBetDetail from "../pages/record/betDetail.vue";
import recordTransaciton from "../pages/record/transaciton.vue";
import recordTransacitonDetail from "../pages/record/orderDetail.vue";
import myDeposit from "../pages/my/finance/myDeposit.vue";
// import myDepositInfo from "../pages/my/finance/myDepositInfo.vue";
import myDepositOrder from "../pages/my/finance/myDepositOrder.vue";
import withdraw from "../pages/my/finance/withdraw.vue";
import withdrawNext from "../pages/my/finance/withdrawNext.vue";
import withdrawOrder from "../pages/my/finance/withdrawOrder.vue";
import transfer from "../pages/my/finance/transfer.vue";
import about from "../pages/my/about.vue";
import deviceManagement from "../pages/my/deviceManagement.vue";
// import protocol from "../components/protocol.vue";
import findPassword from "../pages/findPassword.vue";
import noble from "../pages/my/noble/index.vue";
import myNoble from "../pages/my/noble/myNoble.vue";
import follow from "../pages/my/follow.vue";
import wallet from "../pages/my/wallet.vue";
import mall from "../pages/my/mall.vue";
import myMall from "../pages/my/myMall.vue";
import invite from "../pages/my/invite/index.vue";
import inviteExchangeRecord from "../pages/my/invite/record.vue";
import inviteWithdraw from "../pages/my/invite/withdraw.vue";
// import inviteSpreadTools from "../pages/my/invite/spreadTools.vue";
import exchange from "../pages/my/invite/exchange.vue";
import exchangeRecord from "../pages/record/exchange.vue";
import exchangeWithdraw from "../pages/record/withdraw.vue";
import lottery from "../pages/lottery/index.vue";
import lotteryYuxx from "../pages/lottery/fish.vue";
import lotteryJsks from "../pages/lottery/big.vue";
import lotterySd from "../pages/lottery/color.vue";
import lotteryPk10 from "../pages/lottery/pk10.vue";
import lotteryTxssc from "../pages/lottery/txssc.vue";
import lotteryYflhc from "../pages/lottery/yflhc.vue";
import lotteryDetail from "../pages/lottery/betDetail.vue";
import proxy from "../pages/my/proxy/index.vue";
import joinProxy from "../pages/my/proxy/joinProxy.vue";
import myProxy from "../pages/my/proxy/myProxy.vue";
import proxyExchange from "../pages/my/proxy/exchange.vue";
import proxyWithdraw from "../pages/my/proxy/withdraw.vue";
import proxyRecord from "../pages/my/proxy/record.vue";
import proxyMyMember from "../pages/my/proxy/myMember.vue";
import proxyMyMemberInfo from "../pages/my/proxy/myMemberInfo.vue";
import proxyMemberRecord from "../pages/my/proxy/memberRecord.vue";
import proxyBrokerageRoport from "../pages/my/proxy/brokerageRoport.vue";
import proxyPlatformFee from "../pages/my/proxy/platformFee.vue";
import proxyAcountAdjust from "../pages/my/proxy/acountAdjust.vue";
import proxyBrokerageRule from "../pages/my/proxy/brokerageRule.vue";
import proxyMemberReprot from "../pages/my/proxy/memberReport.vue";
import proxySpreadTools from "../pages/my/proxy/spreadTools.vue";
import maintain from "../components/maintain.vue";
// import maintainOnline from "../components/sevice.vue";
import SubAgentReport from "../pages/my/proxy/SubAgentReport.vue";
import SubAgent from "../pages/my/proxy/SubAgent.vue";
import liveRoom from "../pages/liveRoom/index.vue";
import offRoom from "../pages/liveRoom/offRoom.vue";
import entryLiveBefore from "../pages/liveRoom/entryBefore.vue";
// import toPayLiveRoom from "../pages/liveRoom/toPay.vue";
import live from "../pages/live/index.vue";

const routes = [
  {
    path: "/",
    redirect: "/home"
  },
  {
    path: "/home",
    name: "home",
    component: home
  },
  {
    path: "/leaderboard",
    name: "leaderboard",
    component: leaderboard
  },
  {
    path: "/liveRoom",
    name: "liveRoom",
    component: liveRoom,
  },
  {
    path: "/offRoom",
    name: "offRoom",
    component: offRoom,
  },
  {
    path: "/entryLiveBefore",
    name: "entryLiveBefore",
    component: entryLiveBefore,
  },
  {
    path: "/home/bannerDetail/:name",
    name: "homeBanner",
    component: homeBanner, meta: { keepAlive: true }
  },
  {
    path: "/discount",
    name: "discount",
    component: discount, meta: { keepAlive: true }
  },
  {
    path: "/customerService",
    name: "customerService",
    component: customerService
  },
  {
    path: "/live",
    name: "live",
    component: live
  },
  {
    path: "/game",
    name: "game",
    component: game, meta: { keepAlive: true }
  },
  {
    path: "/customerService/online",
    name: "online",
    component: online
  },
  {
    path: "/mine",
    name: "mine",
    component: my
  },
  {
    path: "/mine/about",
    name: "about",
    component: about
  },
  {
    path: "/mine/bindPhone",
    name: "bindPhone",
    component: bindPhone
  },
  {
    path: "/mine/userInfo",
    name: "userInfo",
    component: userInfo
  },
  {
    path: "/mine/userInfo/edit",
    name: "editUserInfo",
    component: editUserInfo
  },
  {
    path: "/mine/set",
    name: "set",
    component: setting
  },
  {
    path: "/mine/set/blackList",
    name: "blackList",
    component: blackList
  },
  {
    path: "/mine/setting/:type",
    name: "setting",
    component: setting_next
  },
  {
    path: "/mine/setting/edit/next",
    name: "setting_next_edit",
    component: setting_next_edit
  },
  {
    path: "/mine/mail",
    name: "mail",
    component: mail
  },
  // {
  //   path: "/mine/mail/detail",
  //   name: "mailDetail",
  //   component: mailDetail
  // },
  {
    path: "/mine/proxy",
    name: "proxy",
    component: proxy
  },
  {
    path: "/mine/myProxy",
    name: "myProxy",
    component: myProxy
  },
  {
    path: "/mine/myProxy/exchange",
    name: "proxyExchange",
    component: proxyExchange
  },
  {
    path: "/mine/myProxy/withdraw",
    name: "proxyWithdraw",
    component: proxyWithdraw
  },
  {
    path: "/mine/myProxy/record/:type",
    name: "proxyRecord",
    component: proxyRecord
  },
  {
    path: "/mine/myProxy/myMember",
    name: "proxyMyMember",
    component: proxyMyMember
  },
  {
    path: "/mine/myProxy/myMember/info",
    name: "proxyMyMemberInfo",
    component: proxyMyMemberInfo
  },
  {
    path: "/mine/myProxy/myMember/record/:type",
    name: "proxyMemberRecord",
    component: proxyMemberRecord
  },
  {
    path: "/mine/myProxy/brokerageRoport",
    name: "proxyBrokerageRoport",
    component: proxyBrokerageRoport
  },
  {
    path: "/mine/myProxy/brokerageRoport/platformFee",
    name: "proxyPlatformFee",
    component: proxyPlatformFee
  },
  {
    path: "/mine/myProxy/brokerageRoport/acountAdjust",
    name: "proxyAcountAdjust",
    component: proxyAcountAdjust
  },
  {
    path: "/mine/myProxy/brokerageRlue",
    name: "proxyBrokerageRule",
    component: proxyBrokerageRule
  },
  {
    path: "/mine/myProxy/memberReprot",
    name: "proxyMemberReprot",
    component: proxyMemberReprot
  },
  {
    path: "/mine/myProxy/SubAgentReport",
    name: "SubAgentReport",
    component: SubAgentReport
  },
  {
    path: "/mine/myProxy/SubAgent",
    name: "SubAgent",
    component: SubAgent
  },
  {
    path: "/mine/myProxy/spreadTools",
    name: "proxySpreadTools",
    component: proxySpreadTools
  },
  {
    path: "/mine/proxy/join",
    name: "joinProxy",
    component: joinProxy
  },
  {
    path: "/mine/finance/myDeposit",
    name: "myDeposit",
    component: myDeposit
  },
  {
    path: "/mine/finance/myDeposit/order/:type",
    name: "myDepositOrder",
    component: myDepositOrder
  },
  {
    path: "/mine/finance/withdraw",
    name: "withdraw",
    component: withdraw
  },
  {
    path: "/mine/finance/withdraw/next",
    name: "withdrawNext",
    component: withdrawNext
  },
  {
    path: "/mine/finance/myDeposit/withdraw/:type",
    name: "withdrawOrder",
    component: withdrawOrder
  },
  {
    path: "/mine/finance/transfer",
    name: "transfer",
    component: transfer
  },
  {
    path: "/mine/deviceManagement",
    name: "deviceManagement",
    component: deviceManagement
  },
  {
    path: "/mine/addCards/:type",
    name: "addCards",
    component: addCards
  },
  {
    path: "/mine/noble",
    name: "noble",
    component: noble
  },
  {
    path: "/mine/myNoble",
    name: "myNoble",
    component: myNoble
  },
  {
    path: "/mine/follow",
    name: "follow",
    component: follow
  },
  {
    path: "/mine/wallet",
    name: "wallet",
    component: wallet
  },
  {
    path: "/mine/mall",
    name: "mall",
    component: mall
  },
  {
    path: "/mine/myMall",
    name: "myMall",
    component: myMall
  },
  {
    path: "/mine/invite",
    name: "invite",
    component: invite
  },
  {
    path: "/mine/invite/exchange",
    name: "exchange",
    component: exchange
  },
  {
    path: "/mine/invite/withdraw",
    name: "inviteWithdraw",
    component: inviteWithdraw
  },
  {
    path: "/mine/invite/record/:type",
    name: "inviteExchangeRecord",
    component: inviteExchangeRecord
  },
  {
    path: "/record/exchange",
    name: "exchangeRecord",
    component: exchangeRecord
  },
  {
    path: "/record/withdraw",
    name: "exchangeWithdraw",
    component: exchangeWithdraw
  },
  {
    path: "/record/gameReport",
    name: "gameReport",
    component: gameReport
  },
  {
    path: "/record/gameReport/next",
    name: "gameReportNext",
    component: gameReportNext
  },
  {
    path: "/record/bet/detail",
    name: "recordBetDetail",
    component: recordBetDetail
  },
  {
    path: "/record/transaciton",
    name: "recordTransaciton",
    component: recordTransaciton
  },
  {
    path: "/record/transaciton/orderDetail",
    name: "recordTransacitonDetail",
    component: recordTransacitonDetail
  },
  {
    path: "/login",
    name: "login",
    component: login
  },
  {
    path: "/accountSecurity",
    name: "accountSecurity",
    component: accountSecurity
  },
  {
    path: "/register",
    name: "register",
    component: register
  },
  // {
  //   path: "/protocol/:type",
  //   name: "protocol",
  //   component: protocol
  // },
  {
    path: "/findPassword",
    name: "findPassword",
    component: findPassword
  },
  {
    path: "/games/:name/:type/:remark",
    name: "lottery",
    component: lottery
  },
  {
    path: "/lottery/yuxx",
    name: "lotteryYuxx",
    component: lotteryYuxx
  },
  {
    path: "/lottery/jsks",
    name: "lotteryJsks",
    component: lotteryJsks
  },
  {
    path: "/lottery/sd",
    name: "lotterySd",
    component: lotterySd
  },
  {
    path: "/lottery/pk10",
    name: "lotteryPk10",
    component: lotteryPk10
  },
  {
    path: "/lottery/txssc",
    name: "lotteryTxssc",
    component: lotteryTxssc
  },
  {
    path: "/lottery/yflhc",
    name: "lotteryYflhc",
    component: lotteryYflhc
  },
  {
    path: "/lottery/orderDetail/:type",
    name: "lotteryDetail",
    component: lotteryDetail
  }
]

const routesMaintain = [
  {
    path: "/",
    redirect: "/home"
  },
  {
    path: "/home",
    name: "home",
    component: maintain
  },
  {
    path: "/customerService",
    name: "customerService",
    component: customerService
  },
  {
    path: "/customerService/online",
    name: "online",
    component: online
  }
]

const router = createRouter({
  history: createWebHistory("/"),
  scrollBehavior() {
    // 始终滚动到顶部
    return { top: 0, left: 0 }
  },
  routes: maintainData.mainObj.isShow === '0' ? routes : routesMaintain
})

router.beforeEach((to, from, next) => {
  if (to.path == '/login' || to.path == '/accountSecurity' || to.path == '/customerService' || to.path == '/customerService/online' || to.path == '/register' 
  || to.path == '/findPassword' || to.path == '/home' || to.path == '/live' || to.path == '/home/bannerDetail' || to.path == '/customerService/line') {
    if (maintainData.mainObj.isShow === '1' && to.path !== '/home' &&  to.path !== '/customerService/online' && to.path != '/customerService' && to.path != '/customerService/line') {
      next('/home');
    } else {
      next();
    }
  } else {
    const token = sessionStorage.getItem('token'); 
    if ((token === null || token === '' || !store.state.member) && maintainData.mainObj.isShow === '0') {
      if (store.state.member) {
        store.commit("setState", { member: null });
      }
      next('/login');
    } else {
      next();
    }
  }
});

export default router
