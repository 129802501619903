<template>
    <div class="wallet next">
        <div class="title">
            <span> {{ $t('my.accountBalance') }} </span>
            <div class="fixed" @click="goBack">
                <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    p-id="12456" width="0.3rem" height="0.3rem">
                    <path
                        d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z"
                        fill="#666" p-id="12457"></path>
                </svg>
            </div>
        </div>

        <div class="walletTop">
            <div class="flexCenterBL">
                {{ $t('my.wallet.centerWallet') }}
                <img src="../../assets/img/gold.png" />
            </div>

            <div class="flexCenterBL">
                {{ member && member.goldCoin ? numFormat(member.goldCoin) : '0.00' }}
                <img :class="{'rotate': isLoadForLoad }" @click="refreshAll" src="../../assets/img/my/next/refresh.png" />
            </div>
            <div>
                <span @click="recycle">{{ $t('my.recycle') }}</span>
            </div>
        </div>

        <div v-if="activeObj && activeObj.logo" class="transferBox">
            <div class="flexBetween transferSelect">
                <div class="walletName flexCenter">
                    <img src="../../assets/img/my/next/centerIcon.png" />
                    {{ $t('my.wallet.centerWallet') }}
                </div>

                <img v-show="!walletType" @click="changeWalletType" class="transferIcon"
                    src="../../assets/img/my/next/transfer.png" />
                <img v-show="walletType" @click="changeWalletType" class="transferIcon opposite"
                    src="../../assets/img/my/next/transfer.png" />

                <div @click="showWalletList = true" class="flexBetween walletName">
                    <div class="flexCenterBL">
                        <img v-real-img="activeObj.logo" :errorimg="require(`../../assets/img/errorImg/2.png`)"/>
                        {{ activeObj.remark }}
                    </div>

                    <img src="../../assets/img/my/right.png" />
                </div>
            </div>

            <p class="tips"> {{ $t('my.wallet.arrowSwitchTips') }} </p>

            <van-field autocomplete="off" class="walletMoneyInput" v-model.trim="walletNum" clearable
                @keyup="walletNum = walletNum.replace(/[^\d]/g, '')" :left-icon="require('../../assets/img/gold.png')"
                :placeholder="$t('my.wallet.enterTransferNum')">
                <template #extra>
                    <span @click="all" v-show="!walletNum">{{ $t('common.all') }}</span>
                </template>
            </van-field>
        </div>

        <div v-if="activeObj && activeObj.logo" class="walletList">
            <p class="tips"> {{ $t('my.wallet.transferTips') }} </p>

            <div v-for="(item, index) in suWalletList" :key="`${index}--suWalletList`" class="walletListItem flexBetween">
                <div class="flexCenter">
                    <img v-real-img="item.logo" :errorimg="require(`../../assets/img/errorImg/2.png`)"/>
                    {{ item.remark }}
                </div>
                <div> 
                    <span v-if="item.resultStatus || isGetData">{{ numFormat(item.balance) }}</span>
                    <img class="maintain" v-else src="../../assets/img/maintain.png"/>
                </div>
                <div class="flexCenter">
                    <img :class="{'rotate': rotateActive == index}" @click="refreshItem(item, index)" src="../../assets/img/my/next/refreshGray.png" />
                    <span @click="itemClick(item)">{{ $t('my.wallet.transfer') }}</span>
                </div>
            </div>

            <div @click="moreClick" class="more" :class="{ 'puAway': isMore }">
                <span>
                    {{ isMore ? $t("my.finance.putAway") : $t('common.more') }}
                    <img src="../../assets/img/down.png" />
                </span>
            </div>
        </div>


        <div class="submitBtn">
            <div v-show="!walletNum" class="confirmBtn">{{ $t('my.wallet.transfer') }}</div>
            <div v-show="walletNum" @click="goSubmit" class="confirmBtn hasValue">{{ $t('my.wallet.transfer') }}</div>
        </div>

        <!--三方钱包列表弹框 -->
        <van-action-sheet v-model:show="showWalletList" :title="$t('my.wallet.selectTransferPlatform')"
            class="currencyModel">
            <div class="bankListCont">
                <div v-for="(item, index) in walletList" :key="`${index}--walletList`" @click="bankItem(item, index)"
                    class="bankListCont_item flexBetween">
                    <div class="bankListCont_item_left flexCenter">
                        <img v-real-img="item.logo" :errorimg="require(`../../assets/img/errorImg/2.png`)"/>
                        <div>
                            {{ item.remark }}
                        </div>
                    </div>
                    <div v-show="walletActive == index">
                        <img src="../../assets/img/my/next/select2.png" />
                    </div>
                </div>
            </div>
        </van-action-sheet>

        <!-- 双向转账弹框 -->
        <van-dialog class="twoWayTransferDialog" v-model:show="showTwoWayTransfer" show-cancel-button
            confirm-button-Color="#eb457e" cancel-button-Color="#515151" @confirm="transfer"
            :confirmButtonText="$t('common.confirm')"
        :cancelButtonText="$t('common.cancel')">
            <template v-slot:title>
                {{ $t('my.wallet.centerWallet') }}
                <van-icon v-show="walletType"  class="goRight" name="down" />
                <van-icon v-show="!walletType" class="goLeft" name="down" />
                {{ activeObj.remark }}
            </template>
            <div class="twoWayTransfer">
                <p>{{ $t('my.transferAmount') }}</p>
                <p> {{ walletNum }}</p>
                <p>1 {{ $t("my.finance.currency") }} = {{ activeObj.rate }} {{ activeObj.remark }}</p>
            </div>
        </van-dialog>

        <!-- 单向转账弹框 -->
        <van-dialog class="transferDialog" v-model:show="showTransfer" show-cancel-button 
            confirm-button-Color="#eb457e"
            cancel-button-Color="#515151"
            :confirm-button-text="$t('my.wallet.transferIn')"
            :cancel-button-text="$t('my.wallet.transferOut')"
            :before-close="onBeforeClose"
            @confirm="singleTransfer(1)"
            @cancel="singleTransfer(2)"
            >
            <template v-slot:title>
                <img :src="activeSuObj.logo" :errorimg="require(`../../assets/img/errorImg/3.png`)"/>
            </template>
            <div class="twoWayTransfer">
                <van-icon @click="showTransfer=false" class="colseIcon" name="cross" />
                <van-field class="transferInput" v-model.trim="transferNum" autocomplete="off" :placeholder="$t('my.wallet.enterTransferNum')" />
                <p>1{{ $t("my.finance.currency") }} = {{ activeSuObj.rate }} {{ activeSuObj.remark }}</p>
            </div>
        </van-dialog>
        <load></load>
    </div>
</template>
  
<script>
import { gamePlatforms, getBalance, backAllCoin, transfer } from "@/api/index";
import { mapState } from 'vuex';
import load from "@/components/load.vue"
export default {
    components: {
        load
    },
    data() {
        return {
            showWalletList: false,
            walletList: [],
            walletActive: 0,
            walletNum: "",
            showTwoWayTransfer: false,
            showTransfer: false,
            transferNum: "",
            walletType: false,
            activeObj: {},
            isMore: false,
            rotateActive: -1,
            activeSuObj: {},
            isGetData: true, //获取数据中
        }
    },
    computed: {
        ...mapState(['member', 'entry', 'isLoadForLoad', 'systemConfig']),
        suWalletList() {
            let arr = []
            if (this.isMore || this.walletList.length <= 5) {
                arr = JSON.parse(JSON.stringify(this.walletList));
            } else {
                arr = JSON.parse(JSON.stringify(this.walletList)).slice(0, 5);
            }

            return arr;
        },
    },
    created() {
        this.$store.commit("setState", { isLoadForLoad: false });
    },
    mounted() {
        this.gamePlatforms();
    },
    methods: {
        // 钱包方向
        changeWalletType() {
            this.walletNum = "";
            this.walletType = !this.walletType;
        },
        // 提交
        goSubmit() {
            if (this.walletNum <= 0) return this.$toast(this.$t('my.wallet.transferNum0'));
            let balance = !this.walletType ? parseInt(this.member.goldCoin) : Math.floor(this.activeObj.balance/this.activeObj.rate)

            if (this.walletNum <= balance) {
                this.showTwoWayTransfer = true;
            } else {
                this.$toast(this.$t('my.finance.insufficientBalance'))
            }
        },
        onBeforeClose(action) {
            new Promise((resolve) => {
                resolve(false); 
            });
        },
        // 返回
        goBack() {
            if (this.$route.query.lottery) {
                this.$router.push(`/lottery/${this.$route.query.lottery}`);
            } else {
                this.$router.push(this.entry);
            }
        },
        itemClick(item) {
            this.activeSuObj = JSON.parse(JSON.stringify(item));
            this.transferNum = "";
            this.showTransfer = true;
        },
        // 单项转账
        singleTransfer(type) {
            if (this.transferNum <= 0) return this.$toast(this.$t('my.wallet.transferNum0'));
            let amount = this.transferNum.toString().replace(/[^\d]/g, '');
            if (type == 1 && amount > parseInt(this.member.goldCoin)) return this.$toast(this.$t('my.finance.insufficientBalance'));
            if (type == 2 && amount > Math.floor(this.activeSuObj.balance/this.activeSuObj.rate)) return this.$toast(this.$t('my.finance.insufficientBalance'));

            let pramas = {
                gamePlatform: this.activeSuObj.name,
                gameTransferType: type,  // 1：从中心转入三方  2：从三方转出
                transferAmount: amount,
                uid: this.member.uid
            }
            this.$store.commit("setState", { isLoadForLoad: true });
            transfer(pramas).then(res => {
                if (res.code === 0 || res.code === '0') {
                    if (!res.data.msg) {
                        // let walletList = JSON.parse(JSON.stringify(this.walletList)),
                        // index = walletList.findIndex(xitem => {
                        //     return xitem.name == res.data.gamePlatform || xitem.gamePlatform == res.data.gamePlatform
                        // });

                        // if (index != -1) {
                        //     this.walletList[index].balance = res.data.dsfBalance;
                        // }
                        this.getBalance();
                        this.$toast(this.$t('my.finance.transferSuccessTips'));
                        this.showTransfer = false;
                    } else {
                        this.$toast(res.data.msg);
                        this.$store.commit("setState", { isLoadForLoad: false });
                    }
                } else {
                    if (res.msg) this.$toast(res.msg);
                    if (this.isfresh) {
                        this.$toast(this.$t('common.refreshFailed'));
                    }
                    this.isfresh = false;
                    this.$store.commit("setState", { isLoadForLoad: false });
                }
            }).catch(() => {
                if (this.isfresh) {
                    this.$toast(this.$t('common.refreshFailed'));
                }
                this.isfresh = false;
                this.$store.commit("setState", { isLoadForLoad: false });
            });
        },
        // 转账
        transfer() {
            if (this.walletNum <= 0) return;
            let amount = this.walletNum.toString().replace(/[^\d]/g, ''),
            pramas = {
                gamePlatform: this.activeObj.name,
                gameTransferType: !this.walletType ? 1 : 2,  // 1：从中心转入三方  2：从三方转出
                transferAmount: amount,
                uid: this.member.uid
            }
            
            this.$store.commit("setState", { isLoadForLoad: true });
            transfer(pramas).then(res => {
                if (res.code === 0 || res.code === '0') {
                    if (!res.data.msg) {
                        // let walletList = JSON.parse(JSON.stringify(this.walletList)),
                        // index = walletList.findIndex(xitem => {
                        //     return xitem.name == res.data.gamePlatform || xitem.gamePlatform == res.data.gamePlatform
                        // });

                        // if (index != -1) {
                        //     this.walletList[index].balance = res.data.dsfBalance;
                        // }

                        this.walletNum = "";
                        this.getBalance();
                        this.$toast(this.$t('my.finance.transferSuccessTips'));
                        this.showTwoWayTransfer = false;
                    } else {
                        this.$toast(res.data.msg);
                        this.$store.commit("setState", { isLoadForLoad: false });
                    }
                    
                } else {
                    if (res.msg) this.$toast(res.msg);
                    if (this.isfresh) {
                        this.$toast(this.$t('common.refreshFailed'));
                    }
                    this.isfresh = false;
                    this.$store.commit("setState", { isLoadForLoad: false });
                }
            }).catch(() => {
                if (this.isfresh) {
                    this.$toast(this.$t('common.refreshFailed'));
                }
                this.isfresh = false;
                this.$store.commit("setState", { isLoadForLoad: false });
            });
        },
        // 全部
        all() {
            if (!this.walletType) {
                let obj = JSON.parse(JSON.stringify(this.member));
                this.walletNum = parseInt(obj.goldCoin);
            } else {
                this.walletNum = Math.floor(this.activeObj.balance/this.activeObj.rate);
            }
        },
        // 选择钱包
        bankItem(item, index) {
            this.activeObj = JSON.parse(JSON.stringify(item));
            this.walletActive = index;
            this.showWalletList = false;
        },
        // 更多点击
        moreClick() {
            this.isMore = !this.isMore;
        },
        // 一键回收
        recycle() {
            if (!this.isLoadForLoad) {
                let params = {
                    uid: this.member.uid
                }
                this.$store.commit("setState", { isLoadForLoad: true });
                backAllCoin(params).then(res => {
                    if (res.code === 0 || res.code === '0') {
                        let walletList = JSON.parse(JSON.stringify(this.walletList));
                        walletList.forEach(item => {
                            res.data.forEach(xitem => {
                                if (item.name == xitem.gamePlatform) {
                                    item.balance = xitem.balance;
                                    item.resultStatus = xitem.resultStatus;
                                }
                            });
                        });
                        this.walletList = walletList;
                    } else {
                        if (res.msg) this.$toast(res.msg);
                    }
                    this.$store.commit("setState", { isLoadForLoad: false});
                }).catch(err => {
                    this.$store.commit("setState", { isLoadForLoad: false});
                    console.log(err);
                });
            }
            
        },
        // 钱包分类
        gamePlatforms() {
            gamePlatforms(this.member.uid, 1).then(res => {
                if (res.code === 0 || res.code === '0') {
                    this.walletList = res.data;
                    if (res.data.length > 0) {
                        this.activeObj = res.data[0];
                    }
                    this.getBalance();
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(() => {
                console.log('catch');
            });
        },
        // 单个钱包刷新
        refreshItem(item, index) {
            let pramas = {
                uid: this.member.uid,
                gamePlatform: item.name
            }
            this.rotateActive = index;
            getBalance(pramas).then(res => {
                if (res.code === 0 || res.code === '0') {
                    let walletList = JSON.parse(JSON.stringify(this.walletList)),
                    index = walletList.findIndex(xitem => {
                        return xitem.name == res.data[0].gamePlatform
                    });
                    if (index != -1) {
                        this.walletList[index].balance = res.data[0].balance;
                        this.walletList[index].resultStatus = res.data[0].resultStatus;
                        this.walletList[index].msg = res.data[0].msg;
                    }

                    this.$toast(this.$t('common.refreshSuccess'));
                } else {
                    if (res.msg) this.$toast(res.msg);
                }

                this.isfresh = false;
                this.rotateActive = -1;
            }).catch(() => {
                if (this.isfresh) {
                    this.$toast(this.$t('common.refreshFailed'));
                }
                this.isfresh = false;
                this.rotateActive = -1;
                console.log('catch');
            });
        },
        // 刷新所有
        refreshAll() {
            if (!this.isLoadForLoad) {
                this.$store.commit("setState", { isLoadForLoad: true });
                this.isfresh = true;
                this.getBalance();
                this.$store.dispatch('refreshUserInfo');
            } else {
                this.$toast('操作频繁')
            }
        },
        // 获取三方钱包余额
        getBalance() {
            getBalance({ uid: this.member.uid }).then(res => {
                if (res.code === 0 || res.code === '0') {
                    let walletList = JSON.parse(JSON.stringify(this.walletList));
                    walletList.forEach(item => {
                        res.data.forEach(xitem => {
                            if (item.name == xitem.gamePlatform) {
                                item.balance = xitem.balance;
                                item.resultStatus = xitem.resultStatus;
                            }
                        });
                    });

                    this.walletList = walletList;
                    this.activeObj = walletList[0];

                    if (this.isfresh) {
                        this.$toast(this.$t('common.refreshSuccess'));
                    }
                } else {
                    if (res.msg) this.$toast(res.msg);
                }

                this.isfresh = false;
                this.isRefreshAll = false;
                this.isGetData = false;
                this.$store.commit("setState", { isLoadForLoad: false });
            }).catch(() => {
                if (this.isfresh) {
                    this.$toast(this.$t('common.refreshFailed'));
                }
                this.isfresh = false;
                this.isRefreshAll = false;
                this.isGetData = false;
                this.$store.commit("setState", { isLoadForLoad: false });
                console.log('catch');
            });
        },
    }
}
</script>
  
<style lang="scss" scoped>
.wallet {
    padding-top: 0.88rem;
    font-size: 0.28rem;

    .walletTop {
        width: calc(100% - 0.56rem);
        height: 2.6rem;
        margin: 0.28rem 0.28rem 0.2rem;
        background-image: url("../../assets/img/my/next/walletBg.png");
        background-repeat: no-repeat;
        background-size: contain;
        color: #fff;


        div:nth-child(1) {
            padding-top: 0.36rem;

            img {
                width: 0.28rem;
                height: 0.28rem;
                margin-left: 0.1rem;
            }
        }

        div:nth-child(2) {
            font-size: 0.48rem;
            margin-top: 0.36rem;

            img {
                width: 0.32rem;
                height: 0.32rem;
                margin-left: 0.2rem;

                &.rotate {
                    -webkit-animation: spin 1s linear 1s 5 alternate;
                    animation: spin 1s linear infinite;
                }
            }
        }

        div:nth-child(3) {
            text-align: right;

            span {
                display: inline-block;
                background: -webkit-linear-gradient(left, #ffc0d6, #ffeff5);
                background: linear-gradient(90deg, #ffc0d6, #ffeff5);
                padding: 0.12rem 0.4rem;
                margin-right: 0.28rem;
                border-radius: 0.3rem;
                color: #f55794;
                font-weight: 500;
                box-shadow: 0px 1px 2px 1px rgba(252, 65, 143, 0.2);
            }
        }
    }

    .transferBox {
        padding: 0.4rem 0.28rem;
        background-color: #fff;
        border-top-left-radius: 0.2rem;
        border-top-right-radius: 0.2rem;

        .transferSelect {
            margin-bottom: 0.2rem;
        }

        .walletName {
            box-shadow: 0 0 0.1rem 0 rgba(0, 0, 0, 0.1);
            color: #333;
            width: calc(100% - 2rem);
            padding: 0.24rem 0;
            text-align: left;
            border-radius: 0.2rem;

            &:nth-child(1) {
                img {
                    width: 0.44rem;
                    height: 0.44rem;
                    margin: 0 0.2rem;
                }
            }

            &:last-child {
                img:nth-child(1) {
                    width: 0.4rem;
                    margin: 0 0.2rem;
                }

                img:nth-child(2) {
                    width: 0.4rem;
                    margin-right: 0.1rem;
                }
            }
        }

        .transferIcon {
            margin: 0 0.4rem;
            width: 0.48rem;

            &.opposite {
                -moz-transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
                filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
            }
        }
    }

    .walletList {
        margin-top: 0.2rem;
        background-color: #fff;
        padding: 0.2rem 0.28rem 1.6rem;

        .walletListItem {
            height: 1.28rem;
            border-bottom: 1px solid #f1f1f1;

            &:last-child {
                border-color: #fff;
            }

            div {
                color: #333;

                &:nth-child(1) {
                    color: #626872;
                    width: 2rem;

                    img {
                        margin-right: 0.2rem;
                        width: 0.64rem;
                        height: 0.48rem;
                    }
                }

                &:last-child {
                    width: 3rem;
                    -webkit-justify-content: flex-end;
                    justify-content: flex-end;
                    -moz-box-pack: flex-end;
                    -webkit-moz-box-pack: flex-end;
                    box-pack: flex-end;

                    img {
                        width: 0.32rem;
                        margin-right: 0.2rem;

                        &.rotate {
                            -webkit-animation: spin 1s linear 1s 5 alternate;
                            animation: spin 1s linear infinite;
                        }
                    }

                    span {
                        display: inline-block;
                        color: #fff;
                        padding: 0.05rem 0.4rem;
                        background: -webkit-linear-gradient(#f870ab, #fa8faf);
                        background: linear-gradient(#f870ab, #fa8faf);
                        border-radius: 0.4rem;
                    }
                }

                .maintain {
                    width: 0.32rem;
                }
            }
        }

        .more {
            color: #788597;
            font-size: 0.24rem;
            margin-top: 0.2rem;

            &.puAway img {
                -moz-transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
                filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
            }

            img {
                margin-left: 0.2rem;
                width: 0.26rem;
                height: 0.26rem;
            }
        }
    }

    .bankListCont {
        margin-bottom: 0.3rem;

        .bankListCont_item {
            padding: 0.35rem 0;
            margin: 0 0.28rem;
            border-bottom: 1px solid #EBEFF1;

            .bankListCont_item_left {
                text-align: left;
                color: #788597;

                img {
                    width: 0.64rem;
                    height: 0.48rem;
                    margin-right: 0.7rem;
                }
            }

            &:last-child {
                img {
                    width: 0.4rem;
                }
            }
        }
    }

    .tips {
        color: #f870ab;
        font-size: 0.2rem;
        text-align: left;
    }

    .submitBtn {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 8;
        width: 100%;
        background-color: #fff;
        padding-top: 0.1rem;
    }

    .twoWayTransfer {
        p {
            color: #333;
            font-size: 0.24rem;
            padding: 0.2rem 0.4rem;

            &:nth-child(2) {
                font-size: 0.4rem;
                padding: 0 0.4rem;
            }

            &:last-child {
                color: #999;
            }
        }
    }

    @-webkit-keyframes spin {
        from {
            -webkit-transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(360deg);
        }
    }
    
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}
</style>
<style lang="scss">
.wallet {
    .currencyModel {
        .van-action-sheet__header {
            font-size: 0.34rem;
            background-color: #F9F9F9;
            font-weight: bold;
        }

        .van-action-sheet__close {
            font-size: 0.34rem;
        }
    }

    .walletMoneyInput {
        background-color: #f1f1f1;
        margin-top: 0.4rem;
        border-radius: 0.1rem;

        .van-icon__image {
            margin-top: 0.06rem;
        }

        .van-field__control {
            color: #333;
        }
    }

    .twoWayTransferDialog {
        .van-dialog__header {
            padding-top: 0;
            background: -webkit-linear-gradient(#f870ab, #fa8faf);
            background: linear-gradient(#f870ab, #fa8faf);
            color: #fff;
            font-weight: bold;
            line-height: 1rem;
            vertical-align: middle;

            img {
                vertical-align: middle;
            }
        }

        .goRight {
            -webkit-transform:rotate(90deg); /*为Chrome/Safari*/
            -moz-transform:rotate(90deg); /*为Firefox*/
            -ms-transform:rotate(90deg); /*为IE*/
            -o-transform:rotate(90deg); /*为Opera*/
            transform:rotate(90deg);
            margin: 0 0.4rem;
            font-size: 0.4rem;
        }

        .goLeft {
            -webkit-transform:rotate(-90deg); /*为Chrome/Safari*/
            -moz-transform:rotate(-90deg); /*为Firefox*/
            -ms-transform:rotate(-90deg); /*为IE*/
            -o-transform:rotate(-90deg); /*为Opera*/
            transform:rotate(-90deg);
            margin: 0 0.4rem;
            font-size: 0.4rem;
        }
    }

    .transferDialog {
        .van-dialog__header {
            padding: 0.2rem 0;

            img {
                width: 0.84rem;
                height: 0.64rem;
            }
        }

        .colseIcon {
            position: absolute;
            top: 0.32rem;
            right: 0.4rem;
            color: #f74995;
            font-size: 0.32rem;
        }

        .transferInput {
            background-color: #f1f1f1;
            width: calc(100% - 0.8rem);
            margin: 0 auto;
            border-radius: 0.4rem;
        }

        p {
            text-align: right;
        }
    }
}
</style>