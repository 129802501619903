import { mapState } from 'vuex';
// import { bankSelected } from "@/api/index";
const rechangeEntry = {
    data() {
        return {
        }
    },
    computed: {
        ...mapState(['member']),
    },
    methods: {
        goRechangeComfirm() {
            if (this.$route.path === '/liveRoom') {
                this.showDeposit = true;
                this.$emit('openPopup', 'showDeposit')
            } else {
                this.$router.push('/mine/finance/myDeposit');
            }
            // 检验是否绑定银行卡
            // let params = {
            //     uid: this.member.uid
            // }
            // bankSelected(params).then(res => {
            //     if (res.code === 0 || res.code === '0') {
            //         if (res.data.trueName) {
            //             if (this.$route.path === '/liveRoom') {
            //                 this.showDeposit = true;
            //             } else {
            //                 this.$router.push('/mine/finance/myDeposit');
            //             }
            //         } else {
            //           this.$dialog.confirm({
            //               title: this.$t('common.completeInformation'),
            //               message: this.$t('my.invite.noHaveBankAccontTips'),
            //               className: "confirmHasHeaderDialog",
            //               confirmButtonText: this.$t('common.sure'),
            //               cancelButtonText: this.$t('common.cancel'),
            //               confirmButtonColor: "#eb457e"
            //           }).then(() => {
            //             if (this.$route.path === '/liveRoom') {
            //                 this.showAddCard = true;
            //             } else {
            //                 this.$router.push('/mine/addCards/addBankCards');
            //             }
            //           }).catch(() => {
            //               console.log("取消");
            //           });
            //         }
            //     } else {
            //       if (res.msg) this.$toast(res.msg);
            //     }
            // }).catch(err => {
            //     console.log(err);
            // });
        }
    }
}
export default rechangeEntry;