import axios from 'axios';
import md5 from 'js-md5';
// import jwt_decode from "jwt-decode";
import { Toast } from "vant";
import store from "../store/index";
import { guid } from "@/utils/index";
import router from "../router";
import Aes from "@/utils/aes.js";
// 创建axios实例
const service = axios.create({
  // [
  //   "http://config-test.uat-xusdt.com",
  //   "http://gateway-test.uat-xusdt.com"
  //  ]
  baseURL: process.env.NODE_ENV === 'production' ? 'https://config.th-live.online' : '/configApi', // api 的 base_url
  timeout: 50000, // 请求超时时间
  withCredentials: true
})

// request拦截器
service.interceptors.request.use(
  config => {
    let timer = new Date().getTime(), uid = localStorage.getItem('uid') || guid();
    config.headers['OS'] = "0";
    config.headers['X-Language'] = 'THAI';
    config.headers['Accept-Language'] = 'THAI';
    config.headers['X-AppVersion'] = `1.0.0.0`;
    config.headers['P-G'] = `N`;
    config.headers['appId'] = `ThiLive`;
    config.headers['X-UDID'] = uid;
    config.headers['X-Timestamp'] = timer;
    config.headers['X-Sign'] = md5(`${uid}jgyh,kasd${timer}`);
    config.headers["Content-type"] = "application/json;charset=UTF-8";
    config.headers['N-L'] = "Y";
    // config.headers['P-AE'] = `1`;

    if (sessionStorage.getItem('token')) {
      config.headers['Authorization'] = `HSBox ${sessionStorage.getItem('token')}`;
    }

    // 参数加密
    if (config.url.indexOf('/config-client/base/baseInfo/encrypt?') != -1) {
      config.data = Aes.encryptLast(config.data);
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const code = response.status;
    if (String(code) !== '200') {
      return Promise.reject('error');
    } else {
      if (response.config.url.indexOf('/config-client/base/baseInfo/encrypt?') != -1) {
        response.data = Aes.decryptLast(response.data);
      }
      return response.data;
    }
  },
  error => {
    console.log(error);
    if (error.config.url != '/config-client/base/baseInfo/encrypt?') {
      let code = (error.response.data && error.response.data.code) || 0;
      let msg = (error.response.data && error.response.data.msg) || (error.response.data && error.response.data.desc) || "";
      if (code == 991 || code == 992 || code == 993 || code == 1040 || code == 424) {
        router.replace('/login');
        store.dispatch("loginOutTm");
      }
  
      if (code == 1040 || code == 424) {
        if(msg) store.commit('setState', { loginOutInfo: msg, isLoginOutIm: true });
      } else {
        if (msg) {
          Toast(msg);
        }
      }

      if (msg) {
        Toast(msg);
      }
    }
    

    return Promise.reject(error)
  }
)
export default service
