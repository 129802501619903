import { createI18n } from 'vue-i18n';
import zhVue from '../assets/lang/zh.json';
import vnVue from '../assets/lang/vn.json';
import thVue from '../assets/lang/th.json';
const i18n = createI18n({
  legacy: false,
  // locale: getLanguage(store.state.supportLanguage),  // 设置语言,如果本地存储了则用本地的,没有则默认 'en'
  locale: "TH",  
  silentTranslationWarn: true, // 去除警告
  messages: { // set locale messages
    'ZH': zhVue,
    'VI': vnVue,
    'TH': thVue,
  }
});
 
//上面这个比较重要 按照官网提供的方法 new Vue({ i18n }).$mount('#app'); 运行会出错 提示无法找到  _t 属性。 按照上述方法配置可以解决此问题(网上找的方法,亲测没问题)
//将i18n 导出，在main.js 的 new Vue({}) 中需要配置
export default i18n;