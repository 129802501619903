<template>
    <div>
        <van-popup
            v-model:show="isShowPrivateLetter"
            position="bottom"
            :style="{ width: '100%', height: '100%' }"
            >
            <div class="next privateLetter">
                <div class="title privateLetterTitle">
                    <span>{{ betPramas.nickname }}</span>
                    <div class="fixed" @click="closePrivateLetter">
                    <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.35rem" height="0.35rem"><path d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z" fill="#666666" p-id="12457"></path></svg>
                    </div>
                    <div class="title_ellipsis" v-show="destLetterList.length > 0" @click="deleteClick">
                        {{ $t("my.mail.delete") }}
                    </div>
                </div>

                <van-pull-refresh v-model="loading" @refresh="onRefresh" :disabled="isRefreshDisable">
                    <template #pulling>
                        <van-loading />
                    </template>

                    <!-- 释放提示 -->
                    <template #loosing>
                        <van-loading />
                    </template>

                    <!-- 加载提示 -->
                    <template #loading>
                        <van-loading />
                    </template>
                    <div class="privateLetterBox" :style="{ 'height': privateLetterBoxHeight+'px' }">
                        <div v-for="(item, index) in destLetterList" :key="`${index} -- destLetterList`" class="cont" :class="`cont${index}`">
                            <div class="time">{{ getTime(item.sendTime ? item.sendTime : item.createTime) }}</div>
                            <!-- 发送方  接收方 -->
                            <div v-show="item.uid != member.uid" class="messageBox otherSide">
                                <img v-real-img="item.avatar" :errorimg="require(`../../assets/img/errorImg/3.png`)"/>

                                <div class="messageBody">
                                    {{ item.content }}
                                </div>
                            </div>

                            <div v-show="item.uid == member.uid" class="messageBox isSelf">
                                <div class="messageBody">
                                    {{ item.content }}
                                </div>

                                <img  v-real-img="member.avatar" />
                            </div>
                        </div>
                    </div>
                </van-pull-refresh>
                

                <div class="sendMsgBox flexBetween">
                    <div>
                        <van-form @submit="liveLetter">
                            <van-field v-model.trim="content" autocomplete="off" :placeholder="`${$t('my.mail.privateLetterTips')}`"/>
                        </van-form>
                    </div>
                    <div @click="liveLetter">{{ $t('my.mail.send') }}</div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import moment from "moment";
import { mapState } from 'vuex';
import { liveLetter, getUserLetterInfo, changeLetterStatusByOtherUid } from "@/api/index";
import utils from "@/utils/index";
import md5 from 'js-md5';
export default {
    name: "privateLetter",
    props: ['showPrivateLetter'],
    data() {
        return {
            isShowPrivateLetter: false,
            content: "",
            destLetterList: [],
            loading: false,
            finished: false,
            nextSrollHeight: 0,
            privateLetterBoxHeight: 0,
            scrollTop: 0,
            isRefreshDisable: true,
            isGetNewMsg: false
        }
    },
    computed: {
        ...mapState(['betPramas', 'member', 'letterList']),
    },
    created() {
        setTimeout(()=> {
            this.isShowPrivateLetter = true;
            this.loading = false;
            this.$store.commit("setState", { privateLetterUid: this.betPramas.uid });
        }, 10);
        this.getLetterList();
    },
    watch: {
        scrollTop(newval) {
            if (newval > 0) {
                this.isRefreshDisable = true
            } else {
                this.isRefreshDisable = false
            }
        },
        letterList(val) {
            if(val.length > 0){
                this.isGetNewMsg = true;
                let box = document.getElementsByClassName('privateLetterBox')[0],
                oldScrollHeight = box.scrollHeight;
                
                let destLetterList = this.destLetterList.length > 0 ? JSON.parse(JSON.stringify(this.destLetterList)) : [],
                letterList = val.length > 0 ? JSON.parse(JSON.stringify(val)) : [],
                allLetterList = [...destLetterList, ...letterList],
                mapList = new Map();
                for(let item of allLetterList) {
                    if(!mapList.has(item.letterId)) {
                        mapList.set(item.letterId, item)
                    }
                }
                let newLetterList = [...mapList.values()]
                this.destLetterList = JSON.parse(JSON.stringify(newLetterList));
                
               setTimeout(() => {
                box.scrollTop = box.scrollHeight;
               }, 10);
            }
        }
    },
    methods: {
        /**
     * @description: 下拉刷新和滚动事件冲突
     * @param {*}
     * @return {*}
     */
        resolveConflict(val) {
            const vantList = val;
            vantList.addEventListener('touchmove', () => {
                this.scrollTop = vantList.scrollTop
            })
            vantList.addEventListener('touchend', () => {
                this.scrollTop = vantList.scrollTop
            })
        },
        onRefresh() {
            if (this.finished) {
                this.$toast(this.$t('lottery.noMore'));
                this.loading = false;
            } else {
                this.getLetterList();
            }
        },
        getTime(val) {
          if (val) {
            return moment(val).format('HH:mm:ss DD-MM-YYYY');
          }
          return "";
        },
        // 关闭弹框
        closePrivateLetter() {
            this.isShowPrivateLetter = false;
            let that = this;
            this.$store.commit("setState", { privateLetterUid: '', letterList: [] });

            if (this.isGetNewMsg) {
                if (this.$route.path == '/mine/mail') {
                    let obj = {
                        content: this.destLetterList[this.destLetterList.length - 1].content,
                        sendTime: this.destLetterList[this.destLetterList.length - 1].sendTime
                    }
                    this.$emit('updatePrivateLetter', this.betPramas.mailIndex, obj)
                }

                let uid = localStorage.getItem('uid') || utils.guid(),
                timestamp = new Date().getTime(),
                letterIdList = [],
                otherUidList = [];
                otherUidList.push(this.betPramas.uid);
                let params = {
                    domain: "",
                    language: "th",
                    letterIdList,
                    otherUidList,
                    os: 0,
                    sign: md5(`${uid}jgyh,kasd${timestamp}`),
                    timestamp: timestamp,
                    udid: uid,
                    status: 1
                }
                this.$store.commit("setState", { isLoadForLoad: true });
                changeLetterStatusByOtherUid(params).then(res => {
                    if (res.code === 0 || res.code === '0') {
                        setTimeout(()=> {
                            that.$emit('closePrivateLetter')
                        }, 1000)
                    } else {
                        if (res.msg) this.$toast(res.msg);
                    }
                    this.$store.commit("setState", { isLoadForLoad: false });
                }).catch(() => { 
                    this.$store.commit("setState", { isLoadForLoad: false });
                })
            } else {
                setTimeout(()=> {
                    that.$emit('closePrivateLetter')
                }, 1000)
            }
        },
        deleteClick() {
            this.$dialog.confirm({
                message: `${this.$t('my.mail.deleteTip')}`,
                showCancelButton: true,
                confirmButtonText: `${this.$t('common.confirm')}`,
                cancelButtonText:  `${this.$t('common.cancel')}`,
                closeOnClickOverlay: true,
                className: "confirmDialog",
                confirmButtonColor: "#eb457e"
                })
                .then(() => {
                    this.itemDelete();
                })
                .catch(() => {
                    // on cancel
                });
        },
        itemDelete() {
            let uid = localStorage.getItem('uid') || utils.guid(),
            timestamp = new Date().getTime(),
            otherUidList = [];
            otherUidList.push(this.betPramas.uid);
            let params = {
                domain: "",
                language: "th",
                letterIdList: [],
                otherUidList,
                os: 0,
                sign: md5(`${uid}jgyh,kasd${timestamp}`),
                timestamp: timestamp,
                udid: uid,
                status: 2
            }
            this.$store.commit("setState", { isLoadForLoad: true });
            changeLetterStatusByOtherUid(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    if(this.$route.path == '/mine/mail') this.$emit('updatePrivater', this.betPramas.mailIndex);
                    this.$toast(this.$t("common.eidtSuccess"));
                    this.closePrivateLetter();
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
                this.$store.commit("setState", { isLoadForLoad: false });
            }).catch(() => { 
                this.$store.commit("setState", { isLoadForLoad: false });
            })
        },
        // 发送私信
        liveLetter() {
            if (!this.content) return;
            let timestamp = new Date().getTime(),
             params = {
                uid: this.member.uid,
                content: this.content,
                destUid: this.betPramas.uid,
            }
            liveLetter(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    let old = this.destLetterList.length > 0 ? JSON.parse(JSON.stringify(this.destLetterList)) : [],
                    obj = {
                        avatar: this.betPramas.avatar,
                        content: params.content,
                        letterId: res.data,
                        nickname: this.betPramas.uid,
                        type: 2,
                        otherUid: this.betPramas.uid,
                        uid: this.member.uid,
                        sendTime: timestamp
                    }

                    old.push(obj);
                    this.destLetterList = JSON.parse(JSON.stringify(old));
                    this.content = "";

                    this.$nextTick(() => {
                        let box = document.getElementsByClassName('privateLetterBox')[0];
                        box.scrollTop = box.scrollHeight;
                    })

                    if (this.$route.path == '/mine/mail') {
                        let obj = {
                            content: this.destLetterList[this.destLetterList.length - 1].content,
                            sendTime: this.destLetterList[this.destLetterList.length - 1].sendTime
                        }
                        
                        this.$emit('updatePrivateLetter', this.betPramas.mailIndex, obj)
                    }
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        },
        getLetterList() {
            this.loading = true;
            let udid = localStorage.getItem('uid') || utils.guid(),
            timestamp = new Date().getTime(),
            params = {
                otherUid: this.betPramas.uid,
                language: "th",
                sendTime:  this.destLetterList.length > 0 ?  this.destLetterList[0].sendTime : 0, // 接口返回的数据是大于此时间戳
                os: 0,
                sign: md5(`${udid}jgyh,kasd${timestamp}`),
                udid: udid,
                timestamp: timestamp,
                domain: ""
            }
            this.$store.commit("setState", { isLoadForLoad: true });
            getUserLetterInfo(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    let old = this.destLetterList.length > 0 ? JSON.parse(JSON.stringify(this.destLetterList)) : [],
                    newData = [...res.data, ...old]
                    if (newData && newData.length > 0) {
                        newData = newData.sort((a, b) => {
                            return (a.sendTime - b.sendTime);
                        });
                    }
                    if(res.data.length < 20) this.finished = true;
                    this.destLetterList = JSON.parse(JSON.stringify(newData));

                    this.$nextTick(() => {
                        let box = document.getElementsByClassName('privateLetterBox')[0];
                        let titleBox = document.getElementsByClassName('privateLetterTitle')[0];
                        let sendMsgBox = document.getElementsByClassName('sendMsgBox')[0];
                        let windowHeight = window.innerHeight;
                        this.privateLetterBoxHeight = windowHeight - sendMsgBox.scrollHeight - titleBox.scrollHeight;
                        this.resolveConflict(box);
                        if (!params.sendTime) {
                            box.scrollTop = box.scrollHeight;
                        } else {
                            box.scrollTop = box.scrollHeight - this.nextSrollHeight;
                        }
                        this.nextSrollHeight = box.scrollHeight;
                    })
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
                this.loading = false;
                this.$store.commit("setState", { isLoadForLoad: false });
            }).catch(() => {
                this.loading = false;
                this.$store.commit("setState", { isLoadForLoad: false });
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.privateLetter {
    background-color: #f9f9f9;
    font-size: 0.28rem;
    color: #333;
    height: 100vh;
    overflow: hidden;

    .privateLetterBox {
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        padding-bottom: 0.4rem;
    }

    .title {
        padding-bottom: 0.88rem;
    }

    .cont {
      padding: 0 0.28rem;


      .time {
        display: inline-block;
        margin: 0.4rem auto 0.2rem;
        background-color: #c4c4c4;
        color: #fff;
        font-size: 0.2rem;
        padding: 0.01rem 0.1rem;
        border-radius: 0.05rem;
      }

      .messageBox {
        display: -webkit-box; /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
        display: -moz-box; /* Firefox 17- */
        display: -webkit-flex; /* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
        display: -moz-flex; /* Firefox 18+ */
        display: -ms-flexbox; /* IE 10 */
        display: flex; /* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */
      
        img {
          width: 0.98rem;
          height: 0.98rem;
          border-radius: 50%;
          object-fit: cover;
        }
        .messageBody {
          position: relative;
          display: table;
          max-width: 100%;
          padding:3px 6px;
          line-height: 0.6rem;
          border-radius: 4px;
          background-color: #fff;
          overflow-wrap: anywhere;
          font-size: 0.24rem;
          border: 1px solid #e5e5e5;
          min-height: 1.08rem;
          text-align: left;
          min-width: 1.5rem;

          &::before, &::after {
            content: "";
            width: 0;
            height: 0;
            font-size: 0;
            overflow: hidden;
            display: block;
            border-width: 6px 10px;
            border-style: dashed solid dashed dashed;
            position: absolute;
            top: 0.2rem;
          }

          &::after {
            border-style: dashed solid dashed dashed;
            position: absolute;
            top: 0.2rem;
          }
        }

        &.otherSide {
            -webkit-justify-content: flex-start;
            justify-content: flex-start;
            -moz-box-pack: flex-start;
            -webkit-moz-box-pack: flex-start;
            box-pack: flex-start;

            .messageBody {
                margin-left: 18px;

                &::before, &::after {
                    border-color: transparent #e5e5e5 transparent transparent;
                    left: -20px;
                }

                &::after {
                    border-color: transparent #fff transparent transparent;
                    left: -19px;
                }
            }
        }

        &.isSelf {
            -webkit-justify-content: flex-end;
            justify-content: flex-end;
            -moz-box-pack: flex-end;
            -webkit-moz-box-pack: flex-end;
            box-pack: flex-end;

            .messageBody {
                margin-right: 18px;
                background-color: #04f906;
                color: #fff;

                &::before, &::after {
                    border-color: transparent transparent transparent #e5e5e5;
                    right: -20px;
                }

                &::after {
                    border-color: transparent transparent transparent #04f906;
                    right: -19px;
                }
            }
        }
        
      }
    }

    .sendMsgBox {
        position: fixed;
        z-index: 2;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #fff;
        height: 0.88rem;
        box-shadow: -0.04rem 0 0.08rem 0 rgba(0, 0, 0, 0.05);
        padding: 0.1rem 0.28rem;

        .van-cell {
            background-color: #f9f9f9;
            width: calc(100vw - 1.8rem);
            height: 0.68rem;
            line-height: 0.68rem;
            padding: 0 0.2rem;
            border-radius: 0.1rem;
        }

        &>div:last-child {
            background-color: #ff54a8;
            color: #fff;
            padding: 0.1rem 0.2rem;
            border-radius: 0.1rem;
            font-size: 0.2rem;
            width: 1rem;
        }
    }
}
</style>
<style lang="scss">
.privateLetter {
    .van-pull-refresh {
        overflow-y: scroll;
    }
}
</style>