<template>
    <div class="next myMemberInfo">
        <div class="title">
            <span> {{$t('my.proxy.commissionReport')}} </span>
            <div class="fixed" @click="gaBack">
                <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.3rem" height="0.3rem">
                    <path
                        d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z"
                        fill="#666666" p-id="12457"></path>
                </svg>
            </div>
        </div>

        <van-dropdown-menu class="betSearch">
            <van-dropdown-item active-color="#6996FF" ref="searchItemDay">
                <template v-slot:title>
                    <van-search v-model.trim="value" readonly :right-icon="rightIcon" />
                </template>

                <div class="customizeSearch">
                    <div v-for="(item, index) in arrMonth" :key="`${index}--arrMonth`" @click="selectMonth(item)" class="customizeSearchItem">
                        {{item}}
                    </div>
                </div>
            </van-dropdown-item>
        </van-dropdown-menu>

        <div class="exchangeData">
            <div class="tableItem flexBetween">
                <div>{{$t('my.proxy.commission')}}：</div>
                <div class="blue">{{data.commission ? digitalNumber(data.commission) : '0.00'}}</div>
            </div>
            <div class="tableItem flexBetween">
                <div>{{$t('my.proxy.subordinateCommissionShare')}}：</div>
                <div>{{data.belowTotalFee ? data.belowTotalFee : 0}}</div>
            </div>
            <div class="tableItem flexBetween">
                <div>{{$t('my.proxy.commissionRate')}}：</div>
                <div>{{data.commissionPercent}}%</div>
            </div>
            <div class="tableItem flexBetween">
                <div>{{$t('my.proxy.activeMember')}}：</div>
                <div class="blue">{{data.activeNum}}</div>
            </div>
            <div class="tableItem flexBetween">
                <div>{{$t('my.proxy.addMember')}}：</div>
                <div class="blue">{{data.addUserNum}}</div>
            </div>
            <div class="tableItem flexBetween">
                <div>{{$t('my.proxy.netWinLoss')}}：</div>
                <div class="flexCenter">{{data.justifyNetWin ? digitalNumber(data.justifyNetWin) : '0.00'}}</div>
            </div>
            <div class="tableItem flexBetween">
                <div>{{$t('my.proxy.lastMonthBalance')}}：</div>
                <div class="flexCenter">{{data.lastMonthBalance ? digitalNumber(data.lastMonthBalance) : '0.00'}}</div>
            </div>
            <div class="tableItem flexBetween">
                <div>{{$t('my.proxy.netWin')}}：</div>
                <div class="flexCenter">{{data.netWin ? digitalNumber(data.netWin) : '0.00'}}</div>
            </div>
            <div class="tableItem flexBetween">
                <div>{{$t('my.proxy.companyProfit')}}：</div>
                <div class="flexCenter">{{data.companyProfit ? digitalNumber(data.companyProfit) : '0.00'}}</div>
            </div>
            <div class="tableItem flexBetween">
                <div>{{$t('my.proxy.platformFee')}}：</div>
                <div @click="goPlatformFee" class="flexCenter">
                    {{data.platformFee ? digitalNumber(data.platformFee) : '0.00'}}
                    <img src="../../../assets/img/my/right.png" />
                </div>
            </div>
            <div class="tableItem flexBetween">
                <div>{{$t('my.proxy.accountAdjustment')}}：</div>
                <div @click="goAcountAdjust" class="flexCenter">
                    {{data.accountAdjustment ? digitalNumber(data.accountAdjustment) : '0.00'}}
                    <img src="../../../assets/img/my/right.png" />
                </div>
            </div>
            <div class="tableItem flexBetween">
                <div>{{$t('my.proxy.totalRecharge')}}：</div>
                <div>{{data.totalRecharge ? digitalNumber(data.totalRecharge) : '0.00'}}</div>
            </div>
            <div class="tableItem flexBetween">
                <div>{{$t('my.proxy.totalWithdraw')}}：</div>
                <div>{{data.totalWithdraw ? digitalNumber(data.totalWithdraw) : '0.00'}}</div>
            </div>
            <div class="tableItem flexBetween">
                <div>{{$t('my.proxy.totalBalance')}}：</div>
                <div>{{data.totalBalance ? digitalNumber(data.totalBalance) : '0.00'}}</div>
            </div>
            <div class="tableItem flexBetween">
                <div>{{$t('my.proxy.liveProfit')}}：</div>
                <div>{{data.liveProfit ? digitalNumber(data.liveProfit) : '0.00'}}</div>
            </div>
            <div class="tableItem flexBetween">
                <div>{{$t('my.proxy.firstRechargeNum')}}：</div>
                <div>{{data.firstRechargeNum}}</div>
            </div>
            <div class="tableItem flexBetween">
                <div>{{$t('my.proxy.firstRechargeMoney')}}：</div>
                <div>{{data.firstRechargeMoney ? digitalNumber(data.firstRechargeMoney) : '0.00'}}</div>
            </div>
            <div class="tableItem flexBetween">
                <div>{{$t('my.proxy.transferNextMonth')}}：</div>
                <div>{{data.transferNextMonth ? digitalNumber(data.transferNextMonth) : '0.00'}}</div>
            </div>
            <div class="tableItem flexBetween">
                <div>{{$t('my.proxy.actualCommission')}}：</div>
                <div>{{data.actualCommission ? digitalNumber(data.actualCommission) : '0.00'}}</div>
            </div>
            <div class="tableItem flexBetween">
                <div>{{ $t('my.finance.status') }}：</div>
                <div>
                    <span v-show="String(data.status)==='0'" class="gray">{{$t('my.proxy.status.undistributed')}}</span>
                    <span v-show="String(data.status)==='1'" class="green">{{$t('my.proxy.status.dispatched')}}</span>
                    <span v-show="String(data.status)==='2'" class="red">{{$t('my.proxy.status.unsettlement')}}</span>
                </div>
            </div>
        </div>

        <div class="tips">
            <div class="head">
                {{ $t('common.kindTips') }}
            </div>
            <div>
                <p>{{$t('my.proxy.commissionReportWarn1')}}</p>
                <p>{{$t('my.proxy.commissionReportWarn2')}}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { queryCommissionReport, queryCommissionReportMonth } from "@/api/proxy";
export default {
    data() {
        return {
            rightIcon: require('../../../assets/img/select.png'),
            data: {},
            arrMonth: [], //月份
            value: '' //月份展示
        }
    },
    computed: {
        member() {
            return this.$store.state.member;
        },
    },
    created() {
       this.queryCommissionReportMonth(); 
    },
    methods: {
        // 选择月份
        selectMonth(item) {
            this.queryCommissionReport(item);
            this.value = item;
            this.$refs.searchItemDay.toggle();
        },
        // 获取月份
        queryCommissionReportMonth() {
            let params = {
                uid: this.member.uid
                // uid: 15055914
            }
            queryCommissionReportMonth(params).then(res => {
                if (res.code == 0) {
                    this.arrMonth = res.data;
                    if (res.data.length > 0) {
                        this.queryCommissionReport(res.data[0]);
                        this.value = res.data[0];
                    }
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        },
        gaBack() {
            this.$router.push(`/mine/myProxy`);
        },
        goPlatformFee() {
            this.$store.commit("setState", { brokerageRoportDetail: this.data });
            this.$router.push(`/mine/myProxy/brokerageRoport/platformFee`);
        },
        goAcountAdjust() {
            this.$store.commit("setState", { brokerageRoportDetail: this.data });
            this.$router.push(`/mine/myProxy/brokerageRoport/acountAdjust`);
        },
        queryCommissionReport(month) {
            let params = {
                uid: this.member.uid,
                // uid: 15055914,
                month
            }
            queryCommissionReport(params).then(res => {
                if (res.code == 0) {
                    this.data = res.data;
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.myMemberInfo {
    padding-top: 0.88rem;

    .title {
        height: auto;
    }

    .exchangeData {
        .tableItem {
            background-color: #fff;
            border-bottom: 1px solid #EBEFF1;
            padding: 0 0.28rem;
            height: 0.88rem;
            color: #626872;

            &:nth-child(even) {
                background-color: #F9F9F9;
            }

            div:nth-child(2) {
                color: #333;

                &.blue {
                    color: #eb457e;
                }
                
                .gray {
                    color: #788597;
                }

                .green {
                    color: #00B887;
                }

                .red {
                    color: #C41F1A;
                }
            }

            img {
                width: 0.28rem;
            }
        }
    }

    .tips {
        margin-top: 0.2rem;
        padding: 0 0.28rem;
        text-align: left;
        padding-bottom: 0.4rem;
        background-color: #fff;
        color: #788597;
        font-size: 0.24rem;

        .head {
            color: #333;
            font-size: 0.28rem;
            margin-bottom: 0.2rem;
        }
    }
}
</style>
<style lang="scss">
.myMemberInfo .betSearch {
    .van-dropdown-menu__bar {
        box-shadow: 0 0 0 0 rgb(0, 0, 0, 0) !important;
    }
    
    .van-dropdown-menu__title {
        width: 100%;
        padding: 0 !important;

        &:after {
            border-width: 0 !important;
        }
    }

    .customizeSearch {
        border-bottom: 1px solid #ddd;
        font-size: 0.28rem;
        position: relative;
        z-index: 2;

        .customizeSearchItem {
            padding: 0.28rem;
            text-align: left;
        }
    }
}
</style>