<template>
    <div class="next inviteRecord">
        <div class="title">
            {{$t('my.invite.withdrawalRecords')}}
            <div class="fixed" @click="gaBack">
                <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.3rem" height="0.3rem">
                    <path
                        d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z"
                        fill="#666666" p-id="12457"></path>
                </svg>
            </div>

            <div class="flexBetween inviteTab">
                <div :class="{'active': type=='withdraw'}" @click="changeTab('withdraw')">
                    <span>{{ $t('my.finance.withdrawal') }}</span>
                </div>
                <div :class="{'active': type=='exchange'}" @click="changeTab('exchange')">
                    <span>{{ $t('my.proxy.exchange') }}</span>
                </div>
            </div>

            <div v-show="type=='exchange'" class="searchItem">
                <div class="searchItem_item">
                    <span v-for="(item, index) in dayList2" :key="`${index}--dayList-inviteRecord`"
                        :class="searchDayActive == item.lable ? 'searchItem_item_active' : ''" @click="changeStatus(item.lable)">
                        {{ item.value }}
                    </span>
                </div>
            </div>

            <div v-show="type=='withdraw'" class="searchItem">
                <div class="searchItem_item">
                    <span v-for="(item, index) in dayList" :key="`${index}--dayList-inviteRecord`"
                        :class="searchDayActive == index ? 'searchItem_item_active' : ''" @click="changeStatus(index)">
                        {{ item }}
                    </span>
                </div>
            </div>
        </div>

        <div class="exchangeData">
            <div v-if="tableList.length > 0 && type=='withdraw'" class="withdrawRecordList">
                <div v-for="(item, index) in tableList" :key="`${index}`" class="withdrawRecordItem flexBetween">
                    <div>
                        <p>{{ getCardNo(item.cardNo) }}</p>
                        <p>{{ getTime(item.updateTime) }}</p>
                    </div>

                    <div>
                        <p>{{ numFormat(item.amount) }} {{ baseInfo.currencySymbol }}</p>
                        <p :class="{ 'green': item.status === 1, 'red': item.status === 2, 'blue': item.status === 0 }">{{ dayList[item.status + 1] }}</p>
                    </div>
                </div>
            </div>

            <div v-if="tableList.length > 0 && type=='exchange'" class="exchangeRecordList">
                <div v-for="(item, index) in tableList" :key="`${index}`" class="withdrawRecordItem flexBetween">
                    <div class="flexCenter">
                        <div>
                            <img src="../../../assets/img/gold.png" />
                        </div>
                        <div>
                            <p><span>-{{ getMoney(item.amount) }}</span> {{$t('my.invite.revenue')}}</p>
                            <p>{{ getTime(item.updateTime) }}</p>
                        </div>
                    </div>

                    <div>
                        <p> +{{ numFormat(item.goldCoin) }} {{ $t("my.finance.currency") }}</p>
                        <p>
                            <img src="../../../assets/img/gold.png" />
                            {{ numFormat(getMoney(item.amount)) }}
                        </p>
                    </div>
                </div>
            </div>


            <div v-if="tableList.length == 0" class="noData">
                <img src="../../../assets/img/record/noData.png"/>
                <p>{{$t('common.noData')}}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { userWithdrawLog } from "@/api/promotion";
import moment from "moment";
import { mapState } from 'vuex';
import utils from "@/utils/index";
export default {
    data() {
        return {
            dayList: [this.$t('my.invite.status.all'), this.$t('my.invite.status.underReview'), this.$t('my.invite.status.success'), this.$t('my.invite.status.turnDown')],
            dayList2: [
                { lable: 0, value: this.$t('my.invite.status.all') },
                { lable: 1, value: this.$t('my.invite.status.success') }
            ],
            searchDayActive: 0,
            tableList: [],
            allData: [],
            type: "",
            page: 0
        }
    },
    computed: {
        ...mapState(['member', 'baseInfo']),
    },
    created() {
        this.type = this.$route.params.type;
        this.getList();
    },
    methods: {
        // 银行卡处理
        getCardNo(cardNo) {
            return cardNo ? utils.bankCardHide(cardNo) : "";
        },
        // 金额计算
        getMoney(val) {
            let str = "0.00";
            if (val) {
                str = utils.keepTwoDecimalFull(val / this.baseInfo.currency);
            }

            return str;
        },
        getTime(val) {
            if (val) {
                return moment(val).format('HH:mm DD-MM-YYYY');
            }

            return "";
        },
        gaBack() {
            this.$router.push(`/mine/invite`);
        },
        changeStatus(num) {
            this.searchDayActive = num;
            let status = '';
            //0待审核 1通过 2驳回,不传查全部
            if (this.searchDayActive > 0 && this.type != 'exchange') {
                status = this.searchDayActive - 1;
            }

            if (this.searchDayActive > 0 && this.type == 'exchange') {
                status = this.searchDayActive;
            }

            if (this.searchDayActive > 0) {
                this.tableList = this.allData.filter(item => {
                    return item.status === status
                });
            } else {
                this.tableList = this.allData;
            }
        },
        // tab 切换
        changeTab(type) {
            this.type = type;
            this.searchDayActive = 0;
            this.getList();
        },
        // 驳回原因
        itemStatus(item, index) {
            this.tableList[index].flag = !item.flag;
        },
        // 获取列表
        getList() {
            let params = {
                uid: this.member.uid,
                pageSize: 20,
                page: this.page
            }
        	//0待审核 1通过 2驳回,不传查全部
            if (this.searchDayActive > 0 && this.type != 'exchange') {
                params.status = this.searchDayActive - 1;
            }

            if (this.searchDayActive > 0 && this.type == 'exchange') {
                params.status = this.searchDayActive;
            }

            if (this.type == 'exchange') {
                params.type = 0;
            } else {
                params.type = 1;
            }
            this.tableList = [];
            this.allData = [];
            this.$store.commit("setState", { isLoadForLoad: true });
            userWithdrawLog(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    this.tableList = res.data;
                    this.allData = res.data;
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
                this.$store.commit("setState", { isLoadForLoad: false });
            }).catch(err => {
                console.log(err);
                this.$store.commit("setState", { isLoadForLoad: false });
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.inviteRecord {
    padding-top: 2.9rem;
    background-color: #fff;

    .title {
        box-shadow: 0 0 0 0 rgb(0, 0, 0, 0);
        height: auto;
    }

    .inviteTab {
        div {
            flex: 1;
            text-align: center;
            color: #969696;

            span {
                display: inline-block;
                padding: 0rem 0.2rem;
                border-bottom: 2px solid #fff;
            }

            &.active {
                color: #ff7ab9;

                span {
                    border-color: #ff7ab9;
                }
            }
        }
    }

    .searchItem {
        background-color: #f4f4f4;
        .searchItem_item {
            padding: 0 0.28rem;
            text-align: left;
            overflow-x: scroll;
            white-space: nowrap;

            /* 隐藏滚动条 */
            scrollbar-width: none; /* firefox */
            -ms-overflow-style: none; /* IE 10+ */

            &::-webkit-scrollbar {
                width: 0;      /* Safari,Chrome 隐藏滚动条 */
                height: 0;     /* Safari,Chrome 隐藏滚动条 */
                display: none; /* 移动端、pad 上Safari，Chrome，隐藏滚动条 */
            }


            span {
                display: inline-block;
                height: 0.56rem;
                line-height: .56rem;
                border-radius: 0.4rem;
                text-align: center;
                margin-right: 0.2rem;
                color: #666;
                font-size: .28rem;
                box-sizing: border-box;
                -webkit-flex-grow: 0;
                flex-grow: 0;
                -webkit-flex-shrink: 0;
                flex-shrink: 0;
                -webkit-flex-basis: calc(25% - 0.2rem);
                flex-basis: calc(25% - 0.2rem);
                position: relative;
                white-space: nowrap;
                padding: 0 0.2rem;

                &:last-child {
                    margin-right: 0;
                }

                &.searchItem_item_active {
                    background: -webkit-linear-gradient(left, #f86fab, #fa8faf);
                    background: linear-gradient(90deg, #f86fab, #fa8faf);
                    color: #fff;
                    border: none;

                    &::after {
                        border: 1px solid #fff;
                    }
                }
            }
        }
    }

    .withdrawRecordList {
        padding: 0 0.28rem;

        .withdrawRecordItem {
            padding: 0.16rem 0;
            border-bottom: 1px solid #f5f5f5;

            div:nth-child(1) {
                text-align: left;
                color: #626872;
                font-size: 0.2rem;

                p:nth-child(1) {
                    color: #333;
                    font-size: 0.28rem;
                    margin-bottom: 0.12rem;
                }
            }

            div:last-child {
                text-align: right;
                font-size: 0.28rem;
                color: #eb457e;

                .green {
                    color: #00b82c;
                }

                .red {
                    color: #c41f1a;
                }

                .blue {
                    color: #2e8cf0;
                }

                p:nth-child(1) {
                    margin-bottom: 0.12rem;
                }
            }
        }
    }

    .exchangeRecordList {
        padding: 0 0.28rem;

        .withdrawRecordItem {
            padding: 0.16rem 0;
            border-bottom: 1px solid #f5f5f5;

            &>div:nth-child(1) {
                text-align: left;
                color: #626872;
                font-size: 0.2rem;

                p:nth-child(1) {
                    color: #333;
                    font-size: 0.28rem;
                    margin-bottom: 0.12rem;

                    span {
                        color: #eb457e;
                        margin-right: 0.1rem;
                    }
                }

                img {
                    width: 0.32rem;
                    height: 0.32rem;
                    margin-right: 0.2rem;
                }
            }

            &>div:last-child {
                text-align: right;
                font-size: 0.24rem;
                color: #666666;

                p:nth-child(1) {
                    color: #ffc603;
                    font-size: 0.28rem;
                }

                img {
                    width: 0.2rem;
                    height: 0.2rem;
                }

                .green {
                    color: #00b82c;
                }

                .red {
                    color: #c41f1a;
                }

                .blue {
                    color: #2e8cf0;
                }

                p:nth-child(1) {
                    margin-bottom: 0.12rem;
                }
            }
        }
    }

}
</style>