<template>
    <div class="next myProxy">
      <div class="title">
        <span> {{$t('my.proxy.myAgent')}} </span>
        <div class="fixed" @click="goOtherPage('/mine')">
        <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.3rem" height="0.3rem"><path d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z" fill="#666666" p-id="12457"></path></svg>
        </div>
      </div>

      <div class="brokerage">
        <div>
            {{$t('my.proxy.myCommission')}}
            <van-icon v-show="isShowMoney" @click="isShowMoney=!isShowMoney" name="eye-o" />
            <van-icon v-show="!isShowMoney" @click="isShowMoney=!isShowMoney" name="closed-eye" />
        </div>

        <div class="goldNum flexCenterBL">
            <img src="../../../assets/img/gold.png" />
            <span v-show="isShowMoney">{{detailData.totalCommission ? numFormat(digitalNumber(detailData.totalCommission)) : '0.00'}}</span>
            <span class="yinc" v-show="!isShowMoney">****</span>
        </div>

        <div class="btnBox flexBetween">
            <div @click="goExchange"> {{$t('my.proxy.exchange')}}</div>
            <div @click="goWithdraw" class="cash">{{$t('my.proxy.withdrawMoney')}}</div>
        </div>
      </div>

      <div class="dataBox">
        <div class="dataBoxTitle">
            <span>{{$t('my.proxy.dataMonth')}}：</span>
            <span>{{$t('my.proxy.dataMonthExplain')}}</span>
        </div>

        <div class="item flexBetween">
            <div>
                {{$t('my.proxy.currentCommission')}}：
                <van-popover class="myProxyPopover" v-model:show="showPopover" theme="dark" :actions="[{ text: $t('my.proxy.commissionCalculationMethodDetail1') }]"  placement="bottom-start">
                    <template #reference>
                        <span><van-icon name="info" /></span>
                    </template>
                </van-popover>
            </div>
            <div class="green">{{detailData.currentMonthCommission ? numFormat(digitalNumber(detailData.currentMonthCommission)) : '0.00'}}</div>
        </div>

        <div class="item flexBetween">
            <div>{{$t('my.proxy.companyProfit')}}：
                <van-popover class="myProxyPopover" v-model:show="showPopoverWin" theme="dark" :actions="[{ text: $t('my.proxy.commissionCalculationMethodDetail4') }]"  placement="bottom-start">
                    <template #reference>
                        <span><van-icon name="info" /></span>
                    </template>
                </van-popover>
            </div>
            <div>{{detailData.currentMonthCompanyProfit ? numFormat(digitalNumber(detailData.currentMonthCompanyProfit)) : '0.00'}}</div>
        </div>
        <div class="item flexBetween">
            <div>{{$t('my.proxy.activeNumber')}}：
                <van-popover class="myProxyPopover" v-model:show="showPopoverHuoye" theme="dark" :actions="[{ text: $t('my.proxy.activeNumberExplain')}]"  placement="bottom-start">
                    <template #reference>
                        <span><van-icon name="info" /></span>
                    </template>
                </van-popover>
            </div>
            <div>{{numFormat(detailData.currentMonthActiveNum)}}</div>
        </div>
        <div class="item flexBetween">
            <div>{{$t('my.proxy.commissionRate')}}：
                <van-popover class="myProxyPopover" v-model:show="showPopoverBl" theme="dark" :actions="[{ text: $t('my.proxy.commissionRateExplain') }]"  placement="bottom-start">
                    <template #reference>
                        <span><van-icon name="info" /></span>
                    </template>
                </van-popover>
            </div>
            <div>{{numFormat(detailData.currentCommissionPercent)}}%</div>
        </div>
        <div class="item flexBetween">
            <div>{{$t('my.proxy.addMember')}}：
                <van-popover class="myProxyPopover" v-model:show="showPopoverXz" theme="dark" :actions="[{ text: $t('my.proxy.addMemberExplain') }]"  placement="bottom-start">
                    <template #reference>
                        <span><van-icon name="info" /></span>
                    </template>
                </van-popover>
            </div>
            <div>{{numFormat(detailData.currentMonthAddUserNum)}}</div>
        </div>
        <div class="item flexBetween">
            <div>{{$t('my.proxy.numberFirstDepositors')}}：
                <van-popover class="myProxyPopover" v-model:show="showPopoverNum" theme="dark" :actions="[{ text: $t('my.proxy.numberFirstDepositorsExplain') }]"  placement="bottom-start">
                    <template #reference>
                        <span><van-icon name="info" /></span>
                    </template>
                </van-popover>
            </div>
            <div>{{numFormat(detailData.currentMonthFirstRechargeNum)}}</div>
        </div>

        <div class="item flexBetween">
            <div>{{$t('my.proxy.numberNewLowerLevel')}}：</div>
            <div>{{detailData.addTotalBelow}}</div>
        </div>
      </div>

      <div class="enteryBox">
        <div class="enteryBoxTitle">{{$t('my.proxy.dataReport')}}：</div>
        <div class="enteryBoxCont">
            <div @click="$router.push('/mine/myProxy/brokerageRlue')" class="icon3 item">
                <div>{{$t('my.proxy.commissionRules')}}</div>
            </div>
            <div @click="$router.push('/mine/myProxy/brokerageRoport')" class="icon2 item">
                <div>{{$t('my.proxy.commissionReport')}}</div>
            </div>

            <div @click="$router.push('/mine/myProxy/SubAgent')" class="icon5 item">
                <div>{{$t('my.proxy.SubAgent')}}</div>
            </div>

            <div @click="$router.push('/mine/myProxy/SubAgentReport')" class="icon6 item">
                <div>{{$t('my.proxy.SubAgentReport')}}</div>
            </div>

            <div @click="$router.push('/mine/myProxy/myMember')" class="icon1 item">
                <div>{{$t('my.proxy.myMember')}}</div>
            </div>
            
            
            <div @click="$router.push('/mine/myProxy/memberReprot')" class="icon4 item">
                <div>{{$t('my.proxy.memberReport')}}</div>
            </div>
        </div>
      </div>
  
      <div class="inviteFooter">
        <div @click="$router.push('/mine/myProxy/spreadTools')" class="confirmBtn hasValue">{{$t('my.proxy.promotionTool')}}</div>
      </div>
    </div>
  </template>
  
<script>
import { queryProxyBase } from "@/api/proxy";
import { mapState } from 'vuex';
export default {
    data() {
        return {
            isShowMoney: false, //余额是否明文展示
            showPopover: false, 
            showPopoverWin: false,
            showPopoverHuoye: false,
            showPopoverBl: false,
            showPopoverXz: false,
            showPopoverNum: false, //首页人数
            detailData: {}, // 界面数据
        }
    },
    computed: {
        ...mapState(['member']),
    },
    created() {
        this.$store.commit("setState", { suEntry: this.$route.path, betPramas: {} });
        this.getDetail();
    },
    methods: {
        // 兑换按钮
        goExchange() {
            this.$store.commit("setState", { detailData: this.detailData });
            this.$router.push('/mine/myProxy/exchange');
        },
        getDetail() {
            let params = {
                uid: this.member.uid
            }
            queryProxyBase(params).then(res => {
                if (res.code == 0) {
                   this.detailData = res.data; 
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        },
        goOtherPage(path) {
            this.$router.push(path);
        },
        // 提款按钮
        goWithdraw() {
            this.$store.commit("setState", { detailData: this.detailData });
            this.$router.push('/mine/myProxy/withdraw');
            // let uid = localStorage.getItem('uid') || utils.guid();
            // let params = {
            //     os: 0,
            //     sign: md5(`${uid}jgyh,kasd${new Date().getTime()}`),
            //     timestamp: new Date().getTime()
            // }

            // if (this.member) {
            //     params.uid = this.member.uid
            // }
            // bankInfo(params).then(res => {
            //     if (res.code == 0) {
            //         let arr = JSON.parse(JSON.stringify(res.data)), usdtList = [] ;
            //         arr.forEach(item => {
            //           if (item.type == 29) {
            //             usdtList.push(item);
            //           } 
            //         });
                    
            //         if (usdtList.length == 0) {
            //             this.$dialog.confirm({
            //                 title: this.$t('common.completeInformation'),
            //                 message: this.$t('my.invite.noHaveUsdtAccontTips'),
            //                 className: "confirmDialog",
            //                 confirmButtonText: this.$t('common.confirm'),
            //                 cancelButtonText: this.$t('common.cancel'),
            //                 confirmButtonColor: "#eb457e"
            //             }).then(() => {
            //                 this.$router.push('/mine/addCards/addVirtualCoin');
            //             }).catch(() => {
            //                 console.log("取消");
            //             });
            //         } else {
            //             this.$store.commit("setState", { detailData: this.detailData });
            //             this.$router.push('/mine/myProxy/withdraw');
            //         }

            //     } else {
            //         if (res.msg) this.$toast(res.msg);
            //     }
            // }).catch(err => {
            //     console.log(err);
            // });
        }
    }
}
</script>
  
<style lang="scss" scoped>
.myProxy {
    padding-top: 0.88rem;
    padding-bottom: 2rem;
    height: auto;
    height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    .brokerage {
        width: calc(100% - 0.56rem);
        height: 3.4rem;
        background: -webkit-linear-gradient(left,#eb5c88, #f9a396);
        background: linear-gradient(90deg,#eb5c88, #f9a396);
        margin: 0.16rem auto;
        color: #fff;
        padding: 0.32rem 0.4rem;
        border-radius: 0.1rem;

        i {
            font-size: 0.32rem;
        }

        .goldNum {
            margin: 0.4rem 0;
            font-size: 0.4rem;
            img {
                width: 0.4rem;
                margin-right: 0.1rem;
            }

            .yinc {
                height: 0.3rem;
            }
        }

        .btnBox {
            div {
                width: calc(50% - 0.2rem);
                line-height: 0.8rem;
                border: 1px solid #fff;
                border-radius: 0.2rem;

                &.cash {
                    background-color: #fff;
                    color: #eb457e;
                }
            }
        }
    }

    .dataBox {
        text-align: left;
        color: #626872;
        .dataBoxTitle {
            line-height: 0.98rem;
            background-color: #fff;
            width: 100%;
            padding: 0 0.28rem;
            border-bottom: 1px solid #EBEFF1;

            span:first-child {
                color: #333;
                font-weight: 700;
            }
        }

        .item {
            line-height: 0.88rem;
            padding: 0 0.28rem;
            border-bottom: 1px solid #EBEFF1;

            &:nth-child(even) {
                background-color: #fff;
            }

            i {
                color: #999;
            }

            div:last-child {
                color: #333;

                &.green {
                    color: #00B887;
                }
            }
            
        }
    }

    .enteryBox {
        margin-top: 0.4rem;
        .enteryBoxTitle {
            color: #333;
            text-align: left;
            padding: 0 0.28rem;
            font-weight: 700;
        }
       
        .enteryBoxCont {
            padding: 0 0.28rem;
            margin-top: 0.14rem;
            .item {
                display: inline-block;
                width: calc(33.3% - 0.2rem);
                height: 2.2rem;
                position: relative;
                margin-right: 0.2rem;
                margin-bottom: 0.15rem;

                &:nth-child(3n) {
                    margin-right: 0;
                }

                &.icon1 {
                    background-image: url('../../../assets/img/proxy/memberEntry.png');
                    background-size: 100% 100%;
                }

                &.icon2 {
                    background-image: url('../../../assets/img/proxy/reportEntry.png');
                    background-size: 100% 100%;
                }

                &.icon3 {
                    background-image: url('../../../assets/img/proxy/ruleEntery.png');
                    background-size: 100% 100%;
                }

                &.icon4 {
                    background-image: url('../../../assets/img/proxy/winLoseEntry.png');
                    background-size: 100% 100%;
                }

                &.icon5 {
                    background-image: url('../../../assets/img/proxy/SubAgent.png');
                    background-size: 100% 100%;
                }

                &.icon6 {
                    background-image: url('../../../assets/img/proxy/SubAgentReport.png');
                    background-size: 100% 100%;
                }

                div {
                    position: absolute;
                    left: 0;
                    top: 1.4rem;
                    width: 100%;
                    padding: 0 0.12rem;
                }
            }
        }
    }

    .inviteFooter {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 3;
        padding: 0.22rem 0.28rem;
        background-color: #fff;
        box-shadow: 0 -0.04rem 0.2rem 0 #e8edf0;
        
        .confirmBtn {
            width: 100%;
        }
    }
}

</style>
<style lang="scss">
.myProxyPopover {
    .van-popover__action {
        width: auto !important;
        height: auto !important;
        padding-top: 0.1rem;
        padding-bottom: 0.1rem;
    }
}
</style>