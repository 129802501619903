import md5 from 'js-md5';

export const isMobile = () => {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return flag;
  // return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
}

export const isJSON = (str) => {
  if (typeof str == 'string') {
    try {
      var obj=JSON.parse(str);
      if(typeof obj == 'object' && obj ){
        return true;
      }else{
        return false;
      }

    } catch(e) {
      return false;
    }
  }
  return false;
}

export const copy = (value) => {
  // 获取需要复制的文字
  const copyStr = value;
  // 创建input标签存放需要复制的文字
  const oInput = document.createElement("input");
  // 把文字放进input中，供复制
  oInput.value = copyStr;
  document.body.appendChild(oInput);
  // 选中创建的input
  if (navigator.userAgent.match(/(iPhone|iPod|iPad|Safari);?/i)) {
    oInput.setSelectionRange(0, oInput.value.length);
    oInput.focus();
  } else {
    oInput.select();
  }

  // 执行复制方法， 该方法返回bool类型的结果，告诉我们是否复制成功
  const copyResult = document.execCommand("copy");
  // 操作中完成后 从Dom中删除创建的input;
  document.body.removeChild(oInput);
  // 根据返回的复制结果 给用户不同的提示
  return copyResult;
}

export const timeforamt = (time, str='start') => {
  var date = new Date(time);
  let result = "";
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  var d = date.getDate();
  d = d < 10 ? "0" + d : d;
  var h = date.getHours();
  h = h < 10 ? "0" + h : h;
  var minute = date.getMinutes();
  var second = date.getSeconds();
  minute = minute < 10 ? "0" + minute : minute;
  second = second < 10 ? "0" + second : second;
  if (str == 'start') {
    result = y + "/" + m + "/" + d + " " + "00:00:00";
  } else {
    result =  y + "/" + m + "/" + d + " " + "23:59:59";
  }

  return new Date(result).getTime();
}

// 保留两位
export const keepTwoDecimalFull = (num) => {
  var result = parseFloat(num);
  if (isNaN(result)) {
    return false;
  }
  result = Math.floor(accMul(num, 100)) / 100;
  var s_x = result.toString();
  var pos_decimal = s_x.indexOf('.');
  if (pos_decimal < 0) {
    pos_decimal = s_x.length;
    s_x += '.';
  }
  while (s_x.length <= pos_decimal + 2) {
    s_x += '0';
  }
  return s_x;
}

//乘法函数，用来得到精确的乘法结果
export const accMul = (arg1,arg2) =>{
  var m=0,s1=arg1.toString(),s2=arg2.toString();
  try{m+=s1.split(".")[1].length}catch(e){}
  try{m+=s2.split(".")[1].length}catch(e){}
  return Number(s1.replace(".",""))*Number(s2.replace(".",""))/Math.pow(10,m)
}

//除法函数，用来得到精确的除法结果
// export const accDiv = (arg1,arg2) =>{
//   var t1=0,t2=0,r1,r2;
//   try{t1=arg1.toString().split(".")[1].length}catch(e){}
//   try{t2=arg2.toString().split(".")[1].length}catch(e){}
//   with(Math){
//     r1=Number(arg1.toString().replace(".",""))
//     r2=Number(arg2.toString().replace(".",""))
//     return (r1/r2)*pow(10,t2-t1);
//   }
// }

//加法函数，用来得到精确的加法结果
export const accAdd = (arg1,arg2) =>{
  var r1,r2,m;
  try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
  try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
  m=Math.pow(10,Math.max(r1,r2))
  return (arg1*m+arg2*m)/m
}

//减法函数，用来得到精确的减法结果
export const accSubtr = (arg1,arg2) =>{
  var r1,r2,m,n;
  try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
  try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
  m=Math.pow(10,Math.max(r1,r2));
  //动态控制精度长度
  n=(r1>=r2)?r1:r2;
  return ((arg1*m-arg2*m)/m).toFixed(n);
}

export const phoneHide = (tel) =>{
  tel = "" + tel;
  let lastindex = tel.length - 4;
  var newTel = tel.substr(0,3) + "****" + tel.substr(lastindex);
  return newTel;
}

export const emailHide = (email) =>{
  email = "" + email;
  let lastindex = email.indexOf('@');
  var newEmail = email.substr(0,3) + "****" + email.substr(lastindex)
  return newEmail;
}

export const bankCardHide = (cardNo) =>{
  cardNo = "" + cardNo;
  let newCardNo = "";
  if (cardNo.length > 4) {
    newCardNo = "**** " + cardNo.substr((cardNo.length - 4))
  } else {
    newCardNo = "**** " + cardNo
  }
  return newCardNo;
}

// 排序 从小到大
export const compare = (property) => {
  return function (a, b) {
      var value1 = a[property];
      var value2 = b[property];
      return value1 - value2;
  }
}

export const getLanguage = (supportLanguage) =>{
  // 默认VI,
  let locale = "TH";
  // let supportLanguage = store.state.supportLanguage;
  if (!localStorage.getItem('locale')) {
    let lang = "TH";
    let browserLang = (navigator.language || navigator.userLanguage).toUpperCase();
    if (browserLang.indexOf("-") != -1) {
      browserLang = browserLang.split("-")[0];
      let browserLang1 = browserLang.split("-")[1];

      let index = supportLanguage.findIndex(item => {
        return item == browserLang || item == browserLang1;
      });

      if (index != -1) {
        lang = supportLanguage[index];
      }
    } else {
      let index = supportLanguage.findIndex(item => {
        return item == browserLang;
      });

      if (index != -1) {
        lang = supportLanguage[index];
      }
    } 
    locale = lang.toUpperCase();
  } else {
    let language = localStorage.getItem('locale');
    let index = supportLanguage.findIndex(item => {
      return item == language;
    });

    if (index != -1) {
      locale = supportLanguage[index];
    }
  }

  return locale;
}

export const guid = () =>{
  // let uid = 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
  // var r = Math.random() * 16 | 0,
  // v = c == 'x' ? r : (r & 0x3 | 0x8);
  //   return v.toString(16);
  // });

  const timestamp = new Date().getTime();
  const random = Math.floor(Math.random() * 10000000000000000);
  const deviceInfo = "YourDeviceInfoHere";
  const combinedData = `${timestamp}${random}${deviceInfo}`;
  const deviceID = md5(combinedData).substr(0, 32);

  localStorage.setItem('uid', deviceID);
  return deviceID;
}

export const nextGoback = () =>{
  console.log('nextGoback');
  return 'nextGoback';
}

// 匹配徽章
export const matchBadgeList = (memberBadgeList, configBadge) =>{
  let badgeShowList = [];
  memberBadgeList.forEach(item => {
    configBadge.forEach(xitem => {
      if (xitem.bid == item) {
        badgeShowList.push(xitem)
      }
    })
  });
  
  return badgeShowList;
}

// url 获取参数
export const  GetRequest = (value)  => { 
  var url = decodeURI(window.location.search); 
  var object = {};
  if (url.indexOf("?") != -1)
  {
    var str = url.substr(1); 
    var strs = str.split("&"); 
    for (var i = 0; i < strs.length; i++) {
      object[strs[i].split("=")[0]] = strs[i].split("=")[1];
    }
  }
  return object[value];
}

export const getMobileOperatingSystem = ()  => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return 'Android';
  } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'IOS';
  } else {
    return 'PC';
  }
}

export default {
  isMobile,
  isJSON,
  copy,
  guid,
  timeforamt,
  compare,
  keepTwoDecimalFull,
  phoneHide,
  emailHide,
  getLanguage,
  nextGoback,
  matchBadgeList,
  GetRequest,
  bankCardHide,
  getMobileOperatingSystem,
}