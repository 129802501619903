<template>
    <div class="next lotteryTxssc">
        <div class="title">
            <span>{{configMen.chinese}}</span>
            <div class="fixed" @click="goBack">
                <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.35rem" height="0.35rem"><path d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z" fill="#666666" p-id="12457"></path></svg>
            </div>

            <div class="title_ellipsis flexCenterBL">
                <img @click="getLotteryResult" src="../../assets/img/lottery/betRecordIcon.png" />
            </div>

            <div class="mk-header">
                <div class="header-row flexBetween">
                    <div class="current-issue">
                        <div @click="showHall=true">
                            <span>{{configMen.chinese}}</span>
                            <img src="../../assets/img/lottery/down.png" />
                        </div>
                        <p>{{$t("lottery.No")}} {{issue.expect}} {{$t("lottery.expect")}}</p>
                    </div>
                    <div class="header-right flexCenter">
                        <div v-show="parseInt(second)<=54">{{$t('lottery.inBet')}}</div>
                        <div class="red" v-show="parseInt(second)>54">{{$t('lottery.closure')}}</div>
                        <div class="timer-wrap">
                            <span>00</span>
                            <span v-show="parseInt(second)<=54">{{second > 9 ? second : '0'+second}}</span>
                            <span v-show="parseInt(second)>54">00</span>
                        </div>
                    </div>
                </div>
                <div class="last-issue flexBetween">
                    <div class="last-issue-left">{{$t("lottery.No")}} {{lastResult.expect}} {{$t("lottery.expect")}}</div>
                    <div class="last-issue-rigth flexCenter">
                        <div class="numberItem">
                            <p><span v-for="(item, index) in lastResult.resultList" :key="`${index}--lastResult.resultList`"> {{item}}</span></p>
                        </div>

                        <van-dropdown-menu class="lotteryDropMenu">
                            <van-dropdown-item ref="item">
                                <div class="lotteryHistoryHead flexCenter">
                                    <div>{{$t('lottery.issue')}}</div>
                                    <div>{{$t('lottery.lotteryNumber')}}</div>
                                </div>
                                <div class="lotteryHistory">
                                    <div v-for="(item, index) in txsscHistory" :key="`${index}--txsscHistory`" class="lotteryItem flexCenter">
                                        <div>{{item.expect}}</div>
                                        <div v-if="item.resultList && item.resultList.length > 0">
                                            <span v-for="(item, index) in item.resultList" :key="`${index}--item.resultList-txssc`" > {{item}}</span>
                                        </div>
                                    </div>
                                </div>
                            </van-dropdown-item>
                        </van-dropdown-menu>
                    </div>
                </div>
            </div>
        </div>

        <van-tabs class="barTitle" v-model:active="betType" animated>
                <van-tab v-for="(item, index) in bar" :key="`${index}--bar`" :title="item.lotteryTypeShow">
                    <div  class="lotteryCont">
                        <p class="rule">{{$t("lottery.howToPlay")}} <van-icon @click="isShowRule=true" name="question-o" /></p>
                        <div class="betInfo" :class="{'betPad': item.type !='SW'}">
                            <div v-for="(xitem, xindex) in item.arr" :key="`${xindex}-item.arr-big`" class="betInfoItem" :class="{'active':getActive(xitem.playName), 'smallItem': item.lotteryType =='龙虎万千'}" @click="betInfo(xitem, index)">
                                <div class="margBT"> {{xitem.methodShow}}</div>
                                <p>{{xitem.lines}}</p>
                            </div>
                        </div>
                    </div>
                </van-tab>
            </van-tabs>

        <!-- 投注记录 底部-->
        <div class="lotteryBet">
            <div class="betNum flexCenter">
                <div v-for="(item, index) in betNum" :key="`${index}-lottery-betNum`" :class="betNumActive==item?'betNumActive':''" @click="betNumClick(item)">
                    X{{item}}
                </div>
            </div>
            <div class="betConfirm flexBetween">
                <div class="flexBetween">
                    <div class="betRecord">
                        <div class="flexCenterBL">
                            <img class="gold" src="../../assets/img/gold.png" />
                            <span>{{member&&member.goldCoin ?numFormat(member.goldCoin) : '0.00'}}</span>
                            <img  @click="refreshWallet" class="refresh" src="../../assets/img/record/refresh.png" />
                        </div>
                    </div>

                    <div @click="goDeposit(lotteryCode)" class="deposit">{{$t('my.menuList.recharge')}}</div>
                </div>
                <div class="flexBetween">
                    <div @click="showWeight" :class="`betWeight weight${weightList[weightListConfirm]}`">{{weightListConfirm==(weightList.length -1)&& customizeValueConfirm ? customizeValueConfirm : weightList[weightListConfirm]}}</div>
                    <div class="betConfirmBtn" @click="goBetInfo" :class="activeBet.length>0?'hasValue':''">
                        <p>{{$t('lottery.betNow')}}</p>
                        <p class="money" v-if="isLogin">{{weightListConfirm==(weightList.length -1) && customizeValueConfirm ? customizeValueConfirm*betNumActive*activeBet.length : weightList[weightListConfirm]*betNumActive*activeBet.length }}</p>
                        <p class="money" v-else>0.00</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- 左侧菜单 -->
        <van-popup v-model:show="showHall" position="left" style="height:100%;width: 4rem;">
            <div class="menuPanel">
                <div class="menuPanel_head flexBetween">
                    <div>{{$t('lottery.chooseLottery')}}</div>
                    <van-icon @click="showHall=false" name="cross" />
                </div>
                <div v-for="(item, index) in leftMenu" :key="`${index}--lottery-menu`" class="menuPanel_item flexBetween" :class="{'active': item.link == '/lottery/txssc'}" @click="menuItem(item)">
                    <span>{{item.chinese}}</span>
                    <van-icon name="arrow" />
                </div>
            </div>
        </van-popup>

        <!-- 砝码 -->
        <van-action-sheet class="weightModel" v-model:show="isShowWeight" :title="`${$t('lottery.setBetAmount')}`">
            <div class="weightList">
                <div class="weightItem" v-for="(item, index) in weightList" :key="`${index}--weightList`" :class="weightListActive == index ? `weightListActive weight${item}` : `weight${item}`"  @click="weightListClick(item, index)">
                    <span v-if="!isNaN(item)">{{ item }}</span>
                    <span v-else>{{weightListActive==(weightList.length - 1) && customizeValue ? customizeValue : $t('lottery.customize')}}</span>
                </div>
            </div>
            <div class="confirmBtn hasValue" @click="confirmWeight">{{$t('common.confirm')}}</div>
        </van-action-sheet>

        <!-- 投注记录 -->
        <van-action-sheet class="weightModel" v-model:show="isShowRecord" @close="colseRecord" :title="`${configMen.chinese}`">
            <van-list
                class="vanList"
                v-model="loading"
                :finished="finished"
                style="height:calc(70vh - 2rem);overflow:scroll;"
                :offset="10" 
                :immediate-check="check" 
                :finish-text="`${$t('lottery.noMore')}`"
                @load="onLoad" 
                >
                <div class="recordList">
                    <div v-show="recordList.length > 0">
                        <div v-for="(item, index) in recordList" :key="`${index}--recordList`" @click="goDetail(item)" class="recordItem flexBetween">
                            <div class="recordItemItem1">
                                <p>{{getTime(item.createTime)}}</p>
                                <p>{{item.nickName}}</p>
                            </div>
                            <div class="recordItemItem2">
                                <p>{{ $t('lottery.detail.betAmount') }}</p>
                                <p>{{ numFormat(item.betAmount)}}</p>
                            </div>

                            <div class="flexCenterBL">
                                <div v-show="item.awardStatus == 0">
                                    {{ $t('lottery.status.wait') }}
                                </div>
                                <img v-show="item.awardStatus == 1" src="../../assets/img/lottery/lose.png" />
                                <img v-show="item.awardStatus == 2" src="../../assets/img/lottery/win.png" />
                                <img v-show="item.awardStatus == 3" src="../../assets/img/lottery/tie.png" />
                            </div>
                        </div>

                        <div v-show="finished" class="showAllTip">{{$t('lottery.showAll')}}</div>
                    </div>

                    <div v-if="recordList.length == 0" class="noData">
                        <img src="../../assets/img/record/noData.png" />
                        <p>{{$t('lottery.newBetRecrod')}}</p>
                    </div>
                </div>

            </van-list>
            
        </van-action-sheet>

        <!-- 投注确认 -->
        <van-action-sheet class="weightModel" v-model:show="isBetList" :title="`${$t('lottery.betConfirm')}`">
            <div class="betList">
                <div class="betHead flexBetween">
                    <div>{{$t("lottery.No")}} {{issue.expect}} {{$t("lottery.expect")}}</div>
                    <div>
                        <span class="gray" v-show="parseInt(second)<=54">{{ $t('lottery.inBet')}}</span>
                        <span class="red" v-show="parseInt(second)>54">{{$t('lottery.closure')}}</span>
                        <span class="blue" v-show="parseInt(second)<=54">00:{{second > 9 ? second : '0'+second}}</span>
                        <span class="blue" v-show="parseInt(second)>54">00:00</span>
                    </div>
                </div>

                <div v-for="(item, index) in activeBet" :key="`${index}--activeBet`" class="betItem flexBetween">
                    <div>
                        <p class="blue">{{item.methodShow}}</p>
                        <p class="gray">{{item.lotteryTypeShow}}</p>
                    </div>
                    <div class="blue">{{weightListConfirm== (weightList.length -1) && customizeValueConfirm ? customizeValueConfirm : weightList[weightListConfirm]}} X {{betNumActive}}</div>
                    <div><van-icon @click="delChoose(index)" name="delete-o" /></div>
                </div>
            </div>
            <div class="betNumList flexCenter">
                <div v-for="(item, index) in betNum" :key="`${index}-lottery-betNum`" :class="betNumActive==item?'active':''" @click="betNumClick(item)">
                    X{{item}}
                </div>
            </div>
 
            <div class="total flexBetween">
                <div>
                    <span class="gray">{{$t('lottery.note')}}:</span>
                    <span class="blue">{{activeBet.length}}</span>
                </div>
                <div>
                    <span class="gray">{{$t('lottery.totalAmount')}}:</span>
                    <span class="blue">{{weightListConfirm== (weightList.length -1) && customizeValueConfirm ? customizeValueConfirm*betNumActive*activeBet.length : weightList[weightListConfirm]*betNumActive*activeBet.length }}</span>
                </div>
            </div>

            <div class="betRecord">
                <div>
                    <img class="gold" src="../../assets/img/gold.png" />
                    <span>{{member&&member.goldCoin ? numFormat(member.goldCoin) : '0.00'}}</span>
                    <img  @click="refreshWallet" class="refresh" src="../../assets/img/record/refresh.png" />
                </div>
            </div>
           <div class="confirmBtn hasValue" @click="confirmBet">{{$t('lottery.confirmBet')}}</div>
        </van-action-sheet>

        <!-- 自定义金额弹框 -->
        <van-dialog v-model:show="customize" className="customizeDialog" :title="`${$t('lottery.enterCustomAmount')}`" 
            :before-close="onBeforeClose"
            @confirm="handleConfirm"
            show-cancel-button 
            :confirmButtonColor="'#eb457e'"
            :confirmButtonText="$t('common.confirm')"
            :cancelButtonText="$t('common.cancel')"
            closeOnClickOverlay closeOnPopstate>
            <div class="customizeContent">
                <van-cell-group>
                    <van-field v-model.trim="customizeValue" @keyup="customizeValue=customizeValue.replace(/[^\d]/g,'')" placeholder="10-20000" autocomplete="off" clearable/>
                </van-cell-group>
            </div>
        </van-dialog>

        <!-- 玩法说明 -->
        <van-action-sheet class="weightModel" v-model:show="isShowRule" :title="`${configMen.chinese}${$t('lottery.howToPlay')}`">
            <div class="weightList ruleFont">
                <div v-html="rule"></div>
            </div>
        </van-action-sheet>
    </div>
</template>

<script>
import { getLotteryPower } from "@/api/index"
import { mapState } from 'vuex';
import lotteryData from '@/pages/mixin/lottery';
export default {
    name: "lotteryTxssc",
    mixins: [lotteryData],
    data() {
        return {
            lotteryCode: "txssc"
        }
    },
    computed: {
        ...mapState(['txsscHistory'])
    },
    methods: {
        // 获取赔率
        getLotteryPower() {
            let params = {
                name: "",
                issue: "202208241070"
            }
            getLotteryPower(params).then(res => {
                if (res.code == 0) {
                    let bar = [];
                    let detail = res.data.filter(item => {
                        return item.lottery == this.lotteryCode
                    });

                    detail.forEach(item => {
                        bar.push(item);
                    });

                    var obj = {};
                    bar = bar.reduce(function (item, next) {
                    obj[next.lotteryType] ? '' : obj[next.lotteryType] = true && item.push(next);
                        return item;
                    }, []);
                    this.bar = bar;

                    this.bar.forEach(item => {
                        item.arr = this.getDetail(res.data, item.lotteryType);
                    });
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        },
        getDetail(arr, val) {
            let detail = arr.filter(item => {
                return item.lotteryType == val && item.lottery.toLowerCase() == this.lotteryCode.toLowerCase()
            });

            return detail;
        },
    }
}
</script>

<style lang="scss" scoped>
.lotteryTxssc {
    text-align: left;
    padding-top: 3.4rem;

    .title {
        height: auto;

        .title_ellipsis {
            height: 0.56rem;
            text-align: center;
            top: 0.15rem;
            margin-right: 0.28rem;
            line-height: 0.6rem;
            font-size: 0.24rem;
            padding: 0;

            img {
                width: 0.4rem;
                height: 0.4rem;
            }
        }

        .mk-header {
            z-index: 103;
            background: linear-gradient(90deg,#69a5f8,#4e8ef7);
            background-image: url("../../assets/img/lottery/fishBg.png");
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            padding-bottom: 0.3rem;

            .header-row {
                width: 100%;
                height: 1.16rem;
                color: #fff;
                padding: 0 0.28rem;
                line-height: 0.35rem;
                font-size: 0.28rem;
                text-align: left;

                .current-issue {
                    img {
                        width: 0.28rem;
                        margin-left: 0.15rem;
                    }
                    span {
                        font-size: 0.32rem;
                    }
                }

                .header-right .timer-wrap {
                    margin-left: 0.2rem;

                    span{
                        display: inline-block;
                        width: 0.86rem;
                        height: 0.76rem;
                        line-height: 0.75rem;
                        text-align: center;
                        background-image: url("../../assets/img/lottery/timerBg.png");
                        background-repeat: no-repeat;
                        background-size: 100%;
                        font-size: 0.36rem;
                        color: #535d76;
                    }
                }
            }

            .last-issue {
                height: 1.06rem;
                width: calc(100% - 0.56rem);
                margin: 0 auto;
                background-color: rgba(255, 255, 255, 0.2);
                border-radius: 0.2rem;
                color: #fff;

                .last-issue-left {
                    width: 45%;
                    padding-left: 0.2rem;
                    text-align: left;
                }

                .last-issue-rigth {
                    width: 15%;
                    padding-left: 0.2rem;
                    -webkit-justify-content: fe;
                    justify-content: flex-end;
                    -moz-box-pack: flex-end;
                    -webkit-moz-box-pack: flex-end;
                    box-pack: flex-end;

                    img {
                        margin-right: 0.2rem;
                    }

                    .numberItem {
                        p {
                            line-height: 0.36rem;
                            width: 2.1rem;

                            span {
                                font-size: 0.24rem;
                                width: 0.36rem;
                                height: 0.36rem;
                                text-align: center;
                                margin-right: 0.04rem;
                                display: inline-block;
                                background-color: #eb457e;
                            }
                        }
                    }
                }
            }
        }
    }

    .menuPanel {
        .menuPanel_head {
            color: #333;
            border-bottom: 1px solid #d8ddf0;
            height: 1.1rem;
            padding: 0 0.28rem;
            border-bottom: 1px solid #d8ddf0;
            font-size: 0.32rem;
        }

        .menuPanel_item {
            padding: 0 0.28rem;
            color: #626872;
            line-height: 0.84rem;

            i {
                font-size: 0.36rem;
                color: #bbb;
            }

            &.active {
                background-color: #ecf4ff;
                color: #eb457e;

                i {
                    color: #eb457e; 
                }
            }
        }
    }

    .lotteryBet {
        position: fixed;
        bottom: 0;
        box-shadow: 0 0.53333vw 2.13333vw 0 rgba(0, 0, 0, 0.25);
        background-color: #fff;
        z-index: 2;
        width: 100%;
        padding: 0.26rem 0.28rem;

        .betNum {
            flex: 1;
            background-color: #F9F9F9;
            border: 1px solid #E5E5E5;
            font-size: 0.24rem;
            color: #969696;
            -webkit-justify-content: space-around;
            justify-content: space-around;
            -moz-box-pack: space-around;
            -webkit-moz-box-pack: space-around;
            box-pack: space-around;
            border-radius: 0.1rem;

            div {
                width: 100%;
                border-right: 1px solid #E5E5E5;
                text-align: center;
                padding: 0.16rem 0;

                &.betNumActive {
                    background-color: #eb457e;
                    color: #fff;
                }

                &:last-child {
                    border-right: none;
                }
            }
        }

        .betConfirm {
            margin-top: 0.36rem;

            .betRecord {
                color: #333;
                font-size: 0.28rem;
                position: relative;

                div {
                    min-width: 1.5rem;
                    height: 0.8rem;
                    background-color: #EBEFF1;
                    border-radius: 0.5rem;
                    padding: 0 0.2rem;
                }

                .refresh {
                    width: 0.28rem;
                    margin-left: 0.1rem;
                }

                .gold {
                    width: 0.28rem;
                    margin-right: 0.08rem;
                }

                span {
                    line-height: 0.2rem;
                }
            }

            .deposit {
                height: 0.64rem;
                line-height: 0.64rem;
                padding: 0 0.14rem;
                color: #eb457e;
                border: 1px solid #eb457e;
                margin-left: 0.1rem;
                border-radius: 0.5rem;
            }

            .betWeight {
                width: 0.86rem;
                height: 0.86rem;
                line-height: 0.86rem;
                text-align: center;
                color: #fff;
                font-size: 0.18rem;
                margin-right: 0.16rem;
            }

            .betConfirmBtn {
                padding: 0.08rem 0.3rem;
                background-color: #E5E5E5;
                color: #626872;
                font-size: 0.24rem;
                border-radius: 0.4rem;
                text-align: center;

                .money {
                    color: #eb457e;
                    margin-top: 0.08rem;
                }

                &.hasValue {
                    background-image: url("../../assets/img/lottery/qrBtn.png");
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    color: #fff;

                    .money {
                        color: #fff;
                    }
                }
            }
        }
    }

    .lotteryCont {
        padding: 0.28rem 0.28rem 2.5rem;
        // height: calc(100vh - 4.44rem);
        overflow-y: scroll;

        .rule {
            font-size: 0.24rem;
            color: #626872;

            i {
                font-size: 0.28rem;
                color: #333;
                font-weight: bold;
                margin-left: 0.1rem;
            }
        }

        .betInfo {
            text-align: center;
            
            &.betPad {
                padding: 0 0.34rem;
            }

            .betInfoItem {
                border-radius: 0.16rem;
                padding: 0.26rem 0;
                display: inline-block;
                width: calc(50% - 0.1rem);
                margin-top: 0.2rem;
                font-size: 0.24rem;
                color: #626872;
                background-color: #fff;
                border: 1px solid #f770ab;
                div:nth-child(1) {
                    font-size: 0.44rem;
                    color: #333;
                    
                    &.margBT {
                        margin-bottom: 0.2rem;
                    }
                }

                &:nth-child(odd) {
                    margin-right: 0.2rem;
                }

                &.smallItem {
                    width: calc(33.3% - 0.15rem);

                    &:nth-child(2) {
                        margin-right: 0.2rem;
                    }

                    &:nth-child(3) {
                        margin-right: 0;
                    }
                }

                img {
                    width: 0.56rem;
                    margin-bottom: 0.2rem;

                    &.imgFirst {
                        margin-right: 0.2rem;
                    }
                }

                &.active {
                    background-color: #f770ab;
                    color: #fff;

                    div:nth-child(1) {
                        color: #fff;
                    }
                }
            }
        }
    }

    .lotteryHistoryHead {
        font-size: 0.24rem;
        color: #626872;
        line-height: 0.2rem;
        text-align: left;
        padding: 0.24rem 0.28rem 0.25rem 0.68rem;
        border-bottom: 1px solid #CECED0;

        div:nth-child(1) {
            width: 2.68rem;
        }
    }

    .lotteryHistory {
        height: 6.95rem;
        font-size: 0.24rem;
        color: #626872;
        line-height: 0.2rem;
        text-align: left;
        overflow-y: scroll;

        div {
            div:nth-child(1) {
                width: 2.68rem;
            } 
        }

        .lotteryItem {
            padding: 0.1rem 0.28rem 0.12rem 0.68rem;
            span {
                font-size: 0.2rem;
                width: 0.32rem;
                height: 0.32rem;
                line-height: 0.32rem;
                text-align: center;
                margin-right: 0.04rem;
                display: inline-block;
                background-color: #eb457e;
                color: #fff;
            }

            &:nth-child(even) {
                background-color: #F9F9F9;
            }
        }
    }

    .weight1, .weight1000 {
        background-image: url("../../assets/img/lottery/weight/5.png");
        background-repeat: no-repeat;
        background-size: contain;
    }
    .weight10 {
        background-image: url("../../assets/img/lottery/weight/10.png");
        background-repeat: no-repeat;
        background-size: contain;
    }
    .weight20 {
        background-image: url("../../assets/img/lottery/weight/20.png");
        background-repeat: no-repeat;
        background-size: contain;
    }
    .weight50 {
        background-image: url("../../assets/img/lottery/weight/50.png");
        background-repeat: no-repeat;
        background-size: contain;
    }
    .weight100 {
        background-image: url("../../assets/img/lottery/weight/100.png");
        background-repeat: no-repeat;
        background-size: contain;
    }
    .weight200 {
        background-image: url("../../assets/img/lottery/weight/200.png");
        background-repeat: no-repeat;
        background-size: contain;
    }
    .weight500 {
        background-image: url("../../assets/img/lottery/weight/500.png");
        background-repeat: no-repeat;
        background-size: contain;
    }
    .weightcustomize {
        background-image: url("../../assets/img/lottery/weight/customize.png");
        background-repeat: no-repeat;
        background-size: contain;
    }

    .confirmBtn {
        margin: 0 auto 0.3rem;
    }

    .gray {
        color: #626872 !important;
        margin-right: 0.05rem
    }

    .red {
        color: #C41F1A !important;
        margin-right: 0.05rem;
    }

    .blue {
        color: #eb457e !important;
    }
}
</style>
<style lang="scss">
.lotteryTxssc {
    .barTitle {
        .van-tabs__wrap {
            box-shadow: 0 0.04rem 0.08rem 0 rgb(0, 0, 0, 0.05);
            height: 1.04rem !important;

            .van-tabs__nav {
                padding: 0 0.18rem;
            }
        }
        .van-tab {
            color: #535d76;
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 0.15rem;
            padding: 0;
            line-height: 0.4rem;
            height: 0.72rem;
            margin: auto 0.1rem;
            box-sizing: border-box;
            flex: auto;
            background-color: #e5e5e5;
            
            &.van-tab--active {
                background-image: url("../../assets/img/lottery/btnBg.png");
                background-position: 0 100%;
                background-size: cover;
                background-repeat: no-repeat;
                border: none;
                color: #fff;
            }
        }

        .van-tabs__line {
            background-color: transparent;
        }

        .van-tab__pane-wrapper {
            height: calc(100vh - 4.44rem);
        }
    }

    .weightModel {
        .van-action-sheet__header {
            font-size: 0.32rem;
            background-color: #F9F9F9;
            font-weight: bold;
        }

        .van-action-sheet__close {
            font-size: 0.34rem;
        }

        .weightList {
            padding: 0.34rem 0.4rem 0.14rem;
            min-height: 2rem;

            &.ruleFont {
                min-height: 60vh;
            }
        
            .weightItem {
                width: 1.5rem;
                height: 1.5rem;
                display: inline-block;
                line-height: 1.5rem;
                margin-right: 0.2rem;
                text-align: center;
                font-size: 0.36rem;
                color: #fff;
                margin-bottom: 0.2rem;
                border: 1px solid #fff;
                border-radius: 0.1rem;

                &.weightListActive {
                    border: 1px solid #f86fab;
                }

                &:nth-child(4n) {
                    margin-right: 0;
                }

                &.weightcustomize {
                    font-size: 0.28rem;
                }
            }
        }

        .recordList {
            padding: 0.2rem 0;
            color: #333;

            .recordItem {
                border-bottom: 1px solid #ebeff1;
                padding: 0.25rem 0.28rem 0.14rem;
                font-size: 0.28rem;
                
                &>div {
                    border-right: 1px solid #c5c5c5;
                    flex: 1;

                    &:last-child {
                        border-right: none;
                    }

                    p:first-child {
                        margin-bottom: 0.16rem;
                    }
                }

                .recordItemItem1 {
                    p {
                        &:first-child {
                            font-size: 0.2rem;
                        }
                    }
                }

                .recordItemItem2 {
                    text-align: center;

                    p:last-child {
                        color: #000;
                        font-weight: bold;
                    }
                }

                img {
                    width: 1.56rem;
                    height: 0.84rem;
                }
            }

            .showAllTip {
                margin: 0.42rem auto;
                font-size: 0.2rem;
                color: #626872;
                text-align: center;
            }
        }

        .betList {
            color: #243050;
            min-height: 3.48rem;
            max-height: 6.02rem;
            overflow-y: scroll;

            .betHead {
                padding: 0.24rem 0.28rem;
                background-color: #F1F1F1;
                line-height: 0.34rem;

                span {
                    font-size: 0.2rem;
                }
            }

            .betItem {
                padding: 0.16rem 0.28rem;
                border-bottom: 1px solid #E5E5E5;

                &:last-child {
                    border-color: transparent;
                }

                p:last-child {
                    font-size: 0.2rem;
                }

                i {
                    font-size: 0.32rem;
                    color: #626872;
                }

                div:nth-child(2) {
                    font-size: 0.4rem;
                    text-align: center;
                }

                div:nth-child(3) {
                    text-align: right;
                }

                div {
                    flex: 1;
                }
            }
        }

        .betNumList {
            margin: 0.2rem 0;
            padding: 0 0.28rem;

            div {
                width: calc(20% - 0.16rem);
                text-align: center;
                margin-right: 0.2rem;
                color: #788597;
                padding: 0.12rem 0;
                border: 1px solid #eb457e;
                border-radius: 0.1rem;

                &:last-child {
                    margin-right: 0;
                }

                &.active {
                    background-color: #eb457e;
                    border-color: #eb457e;
                    color: #fff;
                }
            }
        }

        .total {
            padding: 0 0.28rem;
            margin-bottom: 0.2rem;

            span:nth-child(1) {
                margin-right: 0.1rem;
            }
        }

        .betRecord {
            color: #333;
            font-size: 0.28rem;
            position: relative;
            margin-bottom: 0.2rem;

            div {
                min-width: 1.5rem;
                height: 0.6rem;
                line-height: 0.6rem;
                background-color: #EBEFF1;
                border-radius: 0.5rem;
                padding: 0 0.2rem;
                display: inline-block;
                margin-left: 0.28rem;
                vertical-align: middle;
            }

            .refresh {
                width: 0.28rem;
                margin-left: 0.1rem;
                vertical-align: middle;
            }

            .gold {
                width: 0.28rem;
                margin-right: 0.08rem;
                vertical-align: middle;
            }

            span {
                line-height: 0.2rem;
                vertical-align: middle;
            }
        }
    }

    .van-dropdown-menu {
        position: relative;

        .van-dropdown-menu__bar {
            background-color: transparent;
            height: 1.06rem;
            width: 0.76rem;
            box-shadow: 0 2px 12px rgba(100,101,102, 0);
        }

        .van-dropdown-menu__title::after {
            border: 0.1rem solid;
            border-color: transparent transparent #fff #fff;
            right: 0.2rem;
            margin-top: -0.1rem;
        }

        .van-dropdown-item--down {
            top: 3.4rem !important;
        }
    }

    .customizeDialog {
        .van-hairline--top-bottom::after, .van-hairline-unset--top-bottom::after {
            border-width: 0;
        }

        .van-dialog__confirm, .van-dialog__confirm:active {
            color: #eb457e;
        }

        .van-dialog__header {
            padding: 0.4rem 0;
            font-weight: bold;
        }

        .van-dialog__footer {
            border-top: 1px solid #EBEFF1;
        }

        .van-cell {
            margin: 0 0.4rem 0.4rem;
            border: 1px solid #DCE4F8;
            border-radius: 0.2rem;
            width: auto;
        }
    }
}
</style>