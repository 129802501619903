import CryptoJS from 'crypto-js/crypto-js'

//默认自定义的约定密钥(与后端密钥保持一致)
const DEFAULT_KEY  = CryptoJS.enc.Utf8.parse("nd2jd36z8dkumf94");// 密钥16位长度字符   内容可自定义 旧 1234567890abcdef 新 abcdef1234567890
const DEFAULT_IV = CryptoJS.enc.Utf8.parse("qk5g4py4pmranxn5"); //  密钥偏移量    16位长度字符 旧 0bNhv5FdTUcU3bYC 新 0142v5DFdGdd3gfC

/**
* AES对称加密 （CBC模式，需要偏移量）
* @param {Object} params 明文参数
*/
export const encrypt = (str, keyStr = null, ivStr = null) => {
    const key = keyStr ? CryptoJS.enc.Utf8.parse(keyStr) : DEFAULT_KEY;
    const iv = ivStr ? CryptoJS.enc.Utf8.parse(ivStr) : DEFAULT_IV;
  
    try {
      const srcs = CryptoJS.enc.Utf8.parse(str);
      const encrypt = CryptoJS.AES.encrypt(srcs, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
      return CryptoJS.enc.Base64.stringify(encrypt.ciphertext);
    } catch (error) {
      throw new Error('Encryption failed: ' + error.message);
    }
};

// 最后的加密
export const encryptLast = (data) => {
    let num = suijione(true, false, false, 16);
    let encryptData = {
        abc: new Date().getTime()+encrypt(num),
        qwe: encrypt(JSON.stringify(data), num, 'qk5g4py4pmranxn5')
    };
	return encryptData;
}

/**
* AES对称解密
* @param {Object} params 加密参数
*/
export const decrypt = (str, keyStr = null, ivStr = null) => {
    const key = keyStr ? CryptoJS.enc.Utf8.parse(keyStr) : DEFAULT_KEY;
    const iv = ivStr ? CryptoJS.enc.Utf8.parse(ivStr) : DEFAULT_IV;
  
    try {
      const base64 = CryptoJS.enc.Base64.parse(str);
      const src = CryptoJS.enc.Base64.stringify(base64);
      const decrypt = CryptoJS.AES.decrypt(src, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
      return CryptoJS.enc.Utf8.stringify(decrypt).toString();
    } catch (error) {
      throw new Error('Decryption failed: ' + error.message);
    }
};

// 最后的解密
export const decryptLast = (par) => {
    let qweKey = decrypt(par.abc.substring(13)),
        decryptData = decrypt(par.qwe, qweKey, 'qk5g4py4pmranxn5');
    if (isJSON(decryptData)) {
        decryptData = JSON.parse(decryptData);
    }
	return decryptData;
}

// 
export const decryptL = (par, key, iv) => {
    let decryptData = decrypt(par, key, iv);
	return decryptData;
}

export const suijione = (num,maxA,minlA,fqy) =>{
    let arr=[]
    let arr1=[]
    let arr2=[]
    if(num){
        for(let m=0;m<=9;m++){
            arr.push(m)
        }
    }
    if(maxA){
        for(let m=65;m<=90;m++){
            arr1.push(m)
        }
    }
    if(minlA){
        for(let m=97;m<=122;m++){
            arr2.push(m)
        }
    }
    if(!fqy){
        console.log('生成位数必传')
    }
    let mergeArr = arr.concat(arr1);
    let mergeArr1 = mergeArr.concat(arr2);
    let _length=mergeArr1.length
   let text=''
    for(let m=0;m<fqy;m++){
        let text1=''
        let random= getRandom(0,_length)
        if((mergeArr1[random])<=9){
            text1=mergeArr1[random]
        }else if((mergeArr1[random])>9){
            text1=String.fromCharCode(mergeArr1[random])
        }
        text+=text1
    }
    return text
}


export const getRandom = (a,b) =>{
    var max = a;
    var min = b;
    if(a<b){
        max = b;
        min = a;
    }
    return parseInt(Math.random()*(max-min))+min;
};

export const isJSON = (str) => {
    if (typeof str == 'string') {
        try {
        var obj=JSON.parse(str);
        if(typeof obj == 'object' && obj ){
            return true;
        }else{
            return false;
        }

        } catch(e) {
        return false;
        }
    }
    return false;
}

export default {
  encrypt,
  decrypt,
  decryptLast,
  decryptL,
  encryptLast,
  isJSON,
  suijione,
  getRandom
}