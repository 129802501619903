<template>
    <div class="next accountSecurity">
        <div class="title">
            <span> {{ $t('login.accountSecurity') }} </span>
            <div class="fixed" @click="goBack">
                <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    p-id="12456" width="0.35rem" height="0.35rem">
                    <path
                        d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z"
                        fill="#666666" p-id="12457"></path>
                </svg>
            </div>
        </div>

        <div class="accountSecurityBox">
            <div class="accountSecurityMBox">
                <img src="../assets/img/login/accountSecurity.png" />

                <div>{{ $t('login.verificationPrompt') }}</div>
                <div class="account">{{ showAccount }}</div>
            </div>

            <div class="accountSecurityInputBox">
                <van-field v-model.trim="code" :maxlength="4" autocomplete="off" :placeholder="$t('register.entryCodeTips')">
                    <template #button>
                        <van-button color="#f86fab" @click="confirmGetCode('sendVcode')" v-show="isGetCode" round size="small"
                            type="primary">{{ $t('common.getCode') }}</van-button>
                        <van-button v-show="!isGetCode" disabled color="#f86fab" round size="small"
                            type="primary">{{ getCodeVal }}</van-button>
                    </template>
                </van-field>
            </div>

            <div @click="confirm" :class="{ 'hasValue': isCanGo }" class="infoConfirm confirmBtn">{{ $t('common.confirm') }}</div>
        </div>
        <div style="display: none;" id="captcha"></div>
    </div>
</template>

<script>
import md5 from 'js-md5';
import utils from "@/utils/index";
import { sendVcode, sendEmailVcode, phoneLoginCheck, emailLoginCheck, getInfo } from "@/api/index";
import captcha from '@/pages/mixin/captcha';
import { mapState } from 'vuex';
export default {
    name: 'accountSecurity',
    mixins: [captcha],
    data() {
        return {
            code: "",

            isGetCode: true,
            getCodeVal: this.$t('common.getCode'),
            timer: null,
            // type: 1, //1手机 2邮箱
            fromPath: ""
        }
    },
    computed: {
        ...mapState(['loginAccount']),
        isCanGo() {
            let flag = false;
            if (this.code) {
                flag = true;
            }
            return flag;
        },
        showAccount() {
            let account = this.loginAccount;
            let phonerReg = /^0\d{9}$/,
                emailReg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
            if(phonerReg.test(account)) {
                account = utils.phoneHide(account);
            }

            if(emailReg.test(account)) {
                account = utils.emailHide(account);
            }

            return account;
        },
        type() {
            let account = this.loginAccount, type = 1;
            let emailReg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
            if(emailReg.test(account)) {
                type = 2;
            }

            return type;
        }
    },
    created() {
        // if (this.$route.query.username) {
        //     this.account = this.$route.query.username;
        //     let emailReg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
        //     console.log(emailReg.test(this.account));
        //     if(emailReg.test(this.account)) {
        //         this.type = 2;
        //     }
        // }
        this.userCaptcha('sendVcode');
    },
    methods: {
        goOtherPage(path) {
            this.$router.push(path);
        },
        // 返回逻辑
        goBack() {
            this.goOtherPage('/login');
        },
        // 获取 Ip
        getLocationIp() {
            fetch('https://api.ipify.org?format=json')
                .then(res => res.json())
                .then(data => {
                    console.log('Your IP address is:', data.ip);
                })
                .catch(error => {
                    console.error('Error fetching IP address:', error);
                });
        },
        // 确认
        confirm() {
            let fun = null,
            uid = localStorage.getItem('uid') || utils.guid(),
            timestamp = new Date().getTime(),
            param = {
                os: 0,
                sign: md5(`${uid}jgyh,kasd${timestamp}`),
                timestamp: timestamp,
                udid: uid,
                model: utils.getMobileOperatingSystem(),
                version: "1.0.2",
                softVersion: "1.0.0",
                vcode: this.code,
                domain: ''
            }

            if (this.type == 1) {
                param.mobile = this.loginAccount;
                fun = phoneLoginCheck;
            } else {
                param.email = this.loginAccount;
                fun = emailLoginCheck;
            }

            this.$store.commit("setState", { isLoadForLoad: true });
            fun(param).then(res => {
                if (res.code === 0 || res.code === '0') {
                    let dlInfo = {
                        account: this.loginAccount
                    },
                    liveAE = {
                        randomKey: res.data.randomKey,
                        randomVector: res.data.randomVector
                    }
                    sessionStorage.setItem("token", res.data.token);
                    localStorage.setItem('dlInfo', JSON.stringify(dlInfo));
                    this.$store.commit("setState", { liveAE });
                    setTimeout(() => {
                        this.getInfo();
                        sessionStorage.removeItem("showAdvertise");
                    }, 500);
                } else {
                    if (res.msg) this.$toast(res.msg);
                        this.$store.commit("setState", { isLoadForLoad: false });
                    }
            }).catch(() => {
                this.$store.commit("setState", { isLoadForLoad: false });
            })
        },
        // 获取用户详情
        getInfo() {
            getInfo().then(res => {
                if (res.code === 0 || res.code === '0') {
                    //, badgeShowList = []
                    let member = JSON.parse(JSON.stringify(res.data));
                    // 这里回导致下边代码 进行不下去
                    // if (member.badgeList && member.badgeList.length > 0 && this.configBadge.length > 0) {
                    //   badgeShowList = utils.matchBadgeList(member.badgeList, this.configBadge);
                    // }
                    // member.badgeShowList = JSON.parse(JSON.stringify(badgeShowList));
                    this.$store.commit("setState", { member });
                    let that = this;
                    setTimeout(() => {
                        if (that.$store.state.tim) {
                            that.$store.dispatch("loginTm");
                        } else {
                            that.$store.dispatch("initTm");
                        }
                    }, 100)
                    this.$router.push("/home");
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
                this.$store.commit("setState", { isLoadForLoad: false });
            }).catch(() => {
                this.$store.commit("setState", { isLoadForLoad: false });
            });
        },
        // 获取验证码
        sendVcode() {
            if (!this.isGetCode) return;
            let uid = localStorage.getItem('uid') || utils.guid(),
            param = { 
                mobile: this.loginAccount,
                type: 8,
                os: 0,
                sign: md5(`${uid}jgyh,kasd${new Date().getTime()}`),
                timestamp: new Date().getTime(),
                udid: localStorage.getItem('uid') || utils.guid()
            }, 
            fun = null;

            if (this.type == 1) {
                fun = sendVcode;
            } else {
                fun = sendEmailVcode;
            }

            fun(param).then(res => {
                if (res.code === 0 || res.code === '0') {
                    this.$toast(this.$t('common.getCodeSuccess'));
                    let time = 179;
                    let that = this;
                    this.isGetCode = false;
                    this.timer = setInterval(() => {
                        if (time > 0) {
                            time--;
                            that.getCodeVal = time + "s";
                        } else {
                            clearInterval(this.timer);
                            that.getCodeVal = this.$t('common.getCode');
                            that.isGetCode = true;
                        }
                    }, 1000);
                } else {
                    clearInterval(this.timer);
                    this.getCodeVal = this.$t('common.getCode');
                    this.isGetCode = true;
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(() => {
                clearInterval(this.timer);
                this.getCodeVal = this.$t('common.getCode');
                this.isGetCode = true;
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.accountSecurity {
    color: #333;
    padding-top: 0.88rem;

    .accountSecurityBox {

        .accountSecurityMBox {
            text-align: center;
            color: #666;
            padding: 0 0.28rem;
            margin-bottom: 0.2rem;
            background-color: #fff;

            img {
                width: 1.98rem;
                margin: 0.62rem 0 0.64rem;
            }

            .account {
                color: #333;
                padding: 0.2rem 0 0.66rem;
            }
        }

        .accountSecurityInputBox {
            background-color: #fff;
            padding: 0.2rem 0 0.2rem 0.28rem;
        }

        .infoConfirm {
            margin: 0.8rem auto 0;
        }
    }
}</style>
<style lang="scss">
.accountSecurity {
    .van-cell {
        padding-left: 0 !important;
    }
}</style>