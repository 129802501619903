import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './language/index';
import vant from 'vant';
import utils from '@/utils/index';
import 'vant/lib/index.css';
import './assets/css/reset.css';
import './assets/css/common.scss';

let webApp = createApp(App);

const digitalNumber = (num) => (num && !isNaN(num) ? utils.keepTwoDecimalFull(num) : '0.00');

const numFormat = (num) => {
  if (num) {
    let isNegativeNum = num<0 ? true : false;
    if (isNegativeNum) {
      num = num.replace(/^-/, '')
    }
    num = num.toString().split('.');
    const arr = num[0].split('').reverse();
    const res = [];
    for (let i = 0, len = arr.length; i < len; i++) {
      if (i % 3 === 0 && i !== 0) {
        res.push(',');
      }
      res.push(arr[i]);
    }
    res.reverse();

    let str = num[1] ? res.join('').concat(`.${num[1]}`) : res.join('');

    if (isNegativeNum) {
      return '-'+str;
    } else {
      return str;
    }
    
  } else {
    return 0;
  }
};

// import eruda from 'eruda'
// eruda.init()

async function loadImage(el, binding, vnode) {
    let defaultURL = require('@/assets/img/errorImg/3.png');
    if (el.width && el.height && el.width < el.height) {
      defaultURL = require('@/assets/img/errorImg/1.png');
    }
    if (vnode.props && vnode.props.errorimg) {
      defaultURL = vnode.props.errorimg;
    }
    const imgURL = binding.value;
    if (imgURL) {
      const exist = await imageIsExist(imgURL);
      el.setAttribute('src', exist ? imgURL : defaultURL);
    } else {
      el.setAttribute('src', defaultURL);
    }
  }
  
  webApp.directive('real-img', {
    beforeMount: loadImage,
    beforeUpdate: loadImage,
  });
  

/**
* 检测图片是否存在
* @param url
*/
let imageIsExist = function(url) {
    return new Promise((resolve) => {
        var img = new Image();
        img.onload = function () {
            if (this.complete == true){
                resolve(true);
                img = null;
            }
        }
        img.onerror = function () {
            resolve(false);
            img = null;
        }
        img.src = url;
    })
}
webApp.config.globalProperties.numFormat = numFormat;
webApp.config.globalProperties.digitalNumber = digitalNumber;
webApp.use(store).use(router).use(i18n).use(vant).mount('#app');


