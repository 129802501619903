import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { getInfo } from "@/api/index";
import { configBadge } from "@/api/config";
import tmModule from "@/store/im";
import liveModule from "@/store/live";
import { Toast } from 'vant';
import utils from "@/utils/index";
export default createStore({
  //拥有独立的命名空间
  namespaced: true,
  modules: {
    tmModule: tmModule,
    liveModule: liveModule
  },
  state: {
    member: null, //用户资料
    isLoadMember: false, // 用户资料接口请求中
    entry: "/home", //记录入口用于返回功能，目前只能记一层路由
    suEntry: "", //记录二级入口
    language: "VI",
    betDetail: {}, //投注详情数据
    setPasswordEntry: "", //设置密码 入口
    lotteryRouter: ['yuxx', 'jsks', 'sd', 'pk10', 'txssc', 'yflhc'],
    gameType: "", //用于首页跳转标识
    betPramas: {}, //用于子界面的参数
    suBetPramas: {}, // 二级界面参数
    cserver: {}, //客服信息
    bannerDetail: {}, //轮播详情
    // showAreaCode: false, //手机区号弹框是否展示
    // activeAreaCode: {}, // 当前选择的手机区号
    brokerageRoportDetail: {}, //佣金报表数据
    detailData: {}, //下级数据
    discountType: 0, //活动类型
    multiple: 1, //投注倍数下标
    multipleArr: [1, 2, 5, 10, 20], // 倍数
    weightList: [10, 20, 50, 100, 200, 500, 1000, 'customize'], //投注筹码
    weightListConfirm: 0, //选择的筹码下标
    customizeValueConfirm: '', // 自定义筹码
    supportLanguage: ['VI', 'ZH', 'TH'],
    systemConfig: [], //界面基础配置
    configBadge: [], //徽章图标
    isLoadForLoad: false, // 组件加载中动画开关
    letterList: [], //私信列表
    systemLetterLists: [], //活动通知列表
    isUnreadMsgFlag: false, //未读开关
    isShowOtherWeb: false, // 是否展示外部链接弹框
    otherWebUrl: "", //外部链接的地址
    scrollHeight: {}, // 存储界面高度
    isShowDownLoad: false, //是否展示下载app 弹框
    isShowDownLoadApp: false, //是否展示下载app 弹框 直播间外边
    fromPath: "", //进入直播间前地址
    showDownApp: true, // 是否展示头部app 下载
    activeBar: 1, // 首页主播列表标题选中
    TCPlayer: null,
    loginAccount: '', //正在登录的账号
    loginOutInfo: {} // 登出设备信息
    // isTransfer: true //是否开启转账
  },
  mutations: {
    setState(state, data = {}) {
      Object.keys(data).forEach(key => {
        state[key] = data[key];
      });
    }
  },
  getters: {
    isLogin(state) {
      if (state.member) {
        return true;
      } else {
        return false;
      }
    },
    avatar(state) {
      if (state.member && state.member.avatar) {
        return state.member.avatar;
      } else {
        return '';
      }
    },
  },
  actions: {
    async initData({ dispatch }) {
      await dispatch("getBaseInfo"); 
      await dispatch("getUserIdIcon");
      await dispatch("getLeftMenu");
      await dispatch('configProp');
      await dispatch('getLevelProp');
      // dispatch('getCountry');
      // dispatch('getCserver');
    },
    async refreshUserInfo({state, commit}, bol) {
      commit("setState", { isLoadMember: true });
      getInfo().then(res => {
        commit("setState", { isLoadMember: false });
        if (res.code === 0 || res.code === '0') {
          let info = JSON.parse(JSON.stringify(res.data)), badgeShowList = [], old = JSON.parse(JSON.stringify(state.member));
          info.goldCoin = info.goldCoin ? utils.keepTwoDecimalFull(info.goldCoin) : '0.00';

          if (info.badgeList && info.badgeList.length > 0 && state.configBadge.length > 0) {
            badgeShowList = utils.matchBadgeList(info.badgeList, state.configBadge);
          }
          info.badgeShowList = JSON.parse(JSON.stringify(badgeShowList));
          info.isUnreadMsg = old.isUnreadMsg;
          commit("setState", { member: info });
        } else {
          if (res.msg) Toast(res.msg);
        }
      }).catch(err => {
        // console.log(err);
        commit("setState", { isLoadMember: false });
      });
    },
    // 匹配用户身份标识
    async getUserIdIcon({state, commit},) {
      configBadge().then(res => {
        if (res.code === 0 || res.code === '0') {
          if (res.data && res.data.length > 0) {
            commit("setState", { configBadge: res.data });

            if (state.member && state.member.badgeList && state.member.badgeList.length > 0) {
              let arr = JSON.parse(JSON.stringify(state.member.badgeList)), badgeShowList = [], member = JSON.parse(JSON.stringify(state.member));
              badgeShowList = utils.matchBadgeList(arr, res.data);
              member.badgeShowList = JSON.parse(JSON.stringify(badgeShowList));
            }
          }
        } else {
          if (res.msg) Toast(res.msg);
        }
      }).catch(()=> {});
    },
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage,
    // 本地存储的key
    key: 'project-live',
    paths: [
      'member', 'entry', 'suEntry', 'betDetail', 'setPasswordEntry', 'betPramas', 'suBetPramas', 
      'cserver', 'bannerDetail', 'brokerageRoportDetail', 'detailData', 'multiple', 'weightListConfirm', 'customizeValueConfirm', 
      'systemConfig', 'configBadge', 'isUnreadMsgFlag', 'isShowOtherWeb', 'otherWebUrl', 'scrollHeight', 'isShowDownLoad', 'fromPath', 
      'showDownApp', 'baseInfo', 'tim', 'isLoginTm', 'leftMenu', 'adverts', 'liveInfo', 'liveList', 'liveAE', 'TCPlayer',
      'loginAccount']
  })]
})
