// 从v2.11.2起，SDK 支持了 WebSocket，推荐接入；v2.10.2及以下版本，使用 HTTP
import TIM from 'tim-js-sdk';
import TIMUploadPlugin from 'tim-upload-plugin';
import { getLotteryResultHistoryByName, getissue } from "@/api/index";
import { redJoinRoom, outerRoom } from "@/api/live";
import { getBaseInfo, cpList } from "@/api/config"
import utils from '@/utils/index';
import { Toast } from "vant";
import md5 from 'js-md5';
import router from "../router";
const tmModule = {
    state: {
      baseInfo: {},
      tim: null,
      isLoginTm: false,
      yuxxHistory: [], //鱼虾蟹开奖历史记录
      jsksHistory: [], //快三
      sdHistory: [], // 色蝶
      pk10History: [], // pk10
      txsscHistory: [], // 时时彩
      yflhcHistory: [], // 六合彩
      countTime: 0,
      issue: {}, //当前期号信息
      lotteryRouter: ['yuxx', 'jsks', 'sd', 'pk10', 'txssc', 'yflhc'],
      leftMenu: [], //彩票左侧菜单
      isLoginOutIm: false, //登出弹框
      chat: [], //直播间消息列表
      adverts: [], //公告
      winLotteryScreen: [], //游戏跑马灯中奖飘屏
      allGift: [], //礼物座驾
      levelEffects: [], //等级特效
      interUserInfo: {}, // 进房用户信息
      interUserArr: [], // 用户进房信息列表
      firstLottery: {}, //第一条开奖飘屏
      secondLottery: {}, //第二条开奖飘屏
      anchorUpgrade: {}, // 主播升级数据
      audienceUpgrade: {}, // 用户升级数据
      luxuryGift: [], //豪礼飘屏数据
      svgGift: [], //需要播放svg的礼物列表
      comboGift: [], //连击礼物
      redPacket: {}, //红包雨信息
      pkInfo: {}, //pk 信息 旧版本PK(多人PK版本之前) 
      avatarList: [], //头像列表
      voiceUserList: [], //麦上用户
      pkDataDetail: {}, // pk相关详情
      anchorInvit: false, // 主播邀请连麦
      anchorInvitObj: {},//主播邀请连麦信息
      privateLetterUid: null,
      letterList: []
    },
    mutations:{
      setState(state, data = {}) {
        Object.keys(data).forEach(key => {
          state[key] = data[key];
        });
      } 
    },
    actions:{
      // 初始化
      async initTm({ commit, dispatch, rootState }) {
        let token = sessionStorage.getItem("token");
        if (token) {
          await dispatch("getBaseInfo");
          let options = {
            SDKAppID: Number(rootState.baseInfo.sdkappid), // 接入时需要将0替换为您的即时通信 IM 应用的 SDKAppID
            oversea: true
          };
          // 创建 SDK 实例，`TIM.create()`方法对于同一个 `SDKAppID` 只会返回同一份实例
          let tim = TIM.create(options); // SDK 实例通常用 tim 表示
          commit("setState", { tim: tim, isLoginTm: false });
          // 设置 SDK 日志输出级别，详细分级请参见 setLogLevel https://web.sdk.qcloud.com/im/doc/zh-cn/SDK.html#setLogLevel 接口的说明</a>
          // tim.setLogLevel(0); // 普通级别，日志量较多，接入时建议使用
          tim.setLogLevel(1); // release 级别，SDK 输出关键信息，生产环境时建议使用
          // 注册腾讯云即时通信 IM 上传插件
          tim.registerPlugin({'tim-upload-plugin': TIMUploadPlugin});
          dispatch("timListenEvent");

          if (rootState.member && rootState.member.uid) {
            await dispatch("loginTm");
          } 
        }
      },
      // TIM 监听相关事件
      async timListenEvent({state, commit, dispatch}) {
        // SDK 进入 ready 状态时触发，接入侧监听此事件，然后可调用 SDK 发送消息等 API，使用 SDK 的各项功能
        let onSdkReady = function(event) {
          console.log(event);
            // let message = tim.createTextMessage({ to: 'user1', conversationType: 'C2C', payload: { text: 'Hello world!' }});
            // tim.sendMessage(message);
          };
          state.tim.on(TIM.EVENT.SDK_READY, onSdkReady);
          
          // SDK 进入 not ready 状态时触发，此时接入侧将无法使用 SDK 发送消息等功能。如果想恢复使用，接入侧需调用 login 接口，驱动 SDK 进入 ready 状态
          let onSdkNotReady = function(event) {
            console.log(event);
            // 如果想使用发送消息等功能，接入侧需驱动 SDK 进入 ready 状态，重新调用 login 接口即可，如下所示：
            // tim.login({userID: 'your userID', userSig: 'your userSig'});
          };
          state.tim.on(TIM.EVENT.SDK_NOT_READY, onSdkNotReady);
          
          // SDK 收到推送的单聊、群聊、群提示、群系统通知的新消息，接入侧可通过遍历 event.data 获取消息列表数据并渲染到页面
          let onMessageReceived = function(event) {
            // event.data - 存储 Message 对象的数组 - [Message]
            const messageList = event.data;
            messageList.forEach((message) => {
                if (message.type === TIM.TYPES.MSG_TEXT) {
                  dispatch('receiveMessage', message);
                // 文本消息 - https://web.sdk.qcloud.com/im/doc/zh-cn/Message.html#.TextPayload
                } 
                // else if (message.type === TIM.TYPES.MSG_IMAGE) {
                // // 图片消息 - https://web.sdk.qcloud.com/im/doc/zh-cn/Message.html#.ImagePayload
                // } else if (message.type === TIM.TYPES.MSG_SOUND) {
                // // 音频消息 - https://web.sdk.qcloud.com/im/doc/zh-cn/Message.html#.AudioPayload
                // } else if (message.type === TIM.TYPES.MSG_VIDEO) {
                // // 视频消息 - https://web.sdk.qcloud.com/im/doc/zh-cn/Message.html#.VideoPayload
                // } else if (message.type === TIM.TYPES.MSG_FILE) {
                // // 文件消息 - https://web.sdk.qcloud.com/im/doc/zh-cn/Message.html#.FilePayload
                // } else if (message.type === TIM.TYPES.MSG_CUSTOM) {
                // // 自定义消息 - https://web.sdk.qcloud.com/im/doc/zh-cn/Message.html#.CustomPayload
                // } else if (message.type === TIM.TYPES.MSG_MERGER) {
                // // 合并消息 - https://web.sdk.qcloud.com/im/doc/zh-cn/Message.html#.MergerPayload
                // } else if (message.type === TIM.TYPES.MSG_LOCATION) {
                // // 地理位置消息 - https://web.sdk.qcloud.com/im/doc/zh-cn/Message.html#.LocationPayload
                // } else if (message.type === TIM.TYPES.MSG_GRP_TIP) {
                // // 群提示消息 - https://web.sdk.qcloud.com/im/doc/zh-cn/Message.html#.GroupTipPayload
                // } else if (message.type === TIM.TYPES.MSG_GRP_SYS_NOTICE) {
                // // 群系统通知 - https://web.sdk.qcloud.com/im/doc/zh-cn/Message.html#.GroupSystemNoticePayload
                // }
            });
          };
          state.tim.on(TIM.EVENT.MESSAGE_RECEIVED, onMessageReceived);
          
          // SDK 收到消息被修改的通知，消息发送方可通过遍历 event.data 获取消息列表数据并更新页面上同 ID 消息的内容。
          // let onMessageModified = function(event) {
          //   // event.data - 存储被修改过的 Message 对象的数组 - [Message]
          // };
          // state.tim.on(TIM.EVENT.MESSAGE_MODIFIED, onMessageModified);
          
          // SDK 收到消息被撤回的通知，可通过遍历 event.data 获取被撤回的消息列表数据并渲染到页面，如单聊会话内可展示为 "对方撤回了一条消息"；群聊会话内可展示为 "XXX撤回了一条消息"。
          // let onMessageRevoked = function(event) {
          //   // event.data - 存储 Message 对象的数组 - [Message] - 每个 Message 对象的 isRevoked 属性值为 true
          // };
          // state.tim.on(TIM.EVENT.MESSAGE_REVOKED, onMessageRevoked);
          
          // SDK 收到对端已读消息的通知，即已读回执。可通过遍历 event.data 获取对端已读的消息列表数据并渲染到页面，如单聊会话内可将己方发送的消息由“未读”状态改为“已读”。
          // let onMessageReadByPeer = function(event) {
          //   // event.data - 存储 Message 对象的数组 - [Message] - 每个 Message 对象的 isPeerRead 属性值为 true
          // };
          // state.tim.on(TIM.EVENT.MESSAGE_READ_BY_PEER, onMessageReadByPeer);
          
          // SDK 收到了群消息的已读回执（v2.18.0起支持）
          // let onMessageReadReceiptReceived = function(event) {
          //   // event.data - 存储消息已读回执信息的数组
          //   const readReceiptInfoList = event.data;
          //   readReceiptInfoList.forEach((item) => {
          //     const { groupID, messageID, readCount, unreadCount } = item;
          //     const message = tim.findMessage(messageID);
          //     if (message) {
          //       if (message.readReceiptInfo.unreadCount === 0) {
          //         // 全部已读
          //       } else {
          //         // message.readReceiptInfo.readCount - 消息最新的已读数
          //         // 如果想要查询哪些群成员已读了此消息，请使用 [getGroupMessageReadMemberList] 接口
          //       }
          //     }
          //   });
          // };
          // state.tim.on(TIM.EVENT.MESSAGE_READ_RECEIPT_RECEIVED, onMessageReadReceiptReceived);
          
          // 会话列表更新，event.data 是包含 Conversation 对象的数组
          // let onConversationListUpdated = function(event) {
          //   console.log(event.data); // 包含 Conversation 实例的数组
          // };
          // state.tim.on(TIM.EVENT.CONVERSATION_LIST_UPDATED, onConversationListUpdated);
          
          // SDK 群组列表更新时触发，可通过遍历 event.data 获取群组列表数据并渲染到页面
          // let onGroupListUpdated = function(event) {
          //    console.log(event.data);// 包含 Group 实例的数组
          // };
          // state.tim.on(TIM.EVENT.GROUP_LIST_UPDATED, onGroupListUpdated);
          
          // // 群属性更新时触发，可通过 event.data 获取到更新后的群属性数据（v2.14.0起支持）
          // let onGroupAttributesUpdated = function(event) {
          //    const groupID = event.data.groupID // 群组ID
          //    const groupAttributes = event.data.groupAttributes // 更新后的群属性
          //    console.log(event.data);
          // };
          // state.tim.on(TIM.EVENT.GROUP_ATTRIBUTES_UPDATED, onGroupAttributesUpdated);
          
          // // 创建话题时触发（v2.19.1起支持）
          // let onTopicCreated = function(event) {
          //   const groupID = event.data.groupID // 话题所属社群 ID
          //   const topicID = event.data.topicID // 话题 ID
          //   console.log(event.data);
          // };
          // state.tim.on(TIM.EVENT.TOPIC_CREATED, onTopicCreated);
          
          // // 删除话题时触发（v2.19.1起支持）
          // let onTopicDeleted = function(event) {
          //   const groupID = event.data.groupID // 话题所属社群 ID
          //   const topicIDList = event.data.topicIDList // 删除的话题 ID 列表
          //   console.log(event.data);
          // };
          // state.tim.on(TIM.EVENT.TOPIC_DELETED, onTopicDeleted);
          
          // // 话题资料更新时触发（v2.19.1起支持）
          // let onTopicUpdated = function(event) {
          //    const groupID = event.data.groupID // 话题所属社群 ID
          //    const topic = event.data.topic // 话题资料
          //    console.log(event.data);
          // };
          // state.tim.on(TIM.EVENT.TOPIC_UPDATED, onTopicUpdated);
          
          // // 自己或好友的资料发生变更时触发，event.data 是包含 Profile 对象的数组
          // let onProfileUpdated = function(event) {
          //   console.log(event.data); // 包含 Profile 对象的数组
          // };
          // state.tim.on(TIM.EVENT.PROFILE_UPDATED, onProfileUpdated);
          
          // SDK 黑名单列表更新时触发
          // let onBlacklistUpdated = function(event) {
          //   console.log(event.data); // 我的黑名单列表，结构为包含用户 userID 的数组
          // };
          // state.tim.on(TIM.EVENT.BLACKLIST_UPDATED, onBlacklistUpdated);
          
          // 好友列表更新时触发
          // let onFriendListUpdated = function(event) {
          //   console.log(event.data);
          // }
          // state.tim.on(TIM.EVENT.FRIEND_LIST_UPDATED, onFriendListUpdated);
          
          // // 好友分组列表更新时触发
          // let onFriendGroupListUpdated = function(event) {
          //   console.log(event.data);
          // }
          // state.tim.on(TIM.EVENT.FRIEND_GROUP_LIST_UPDATED, onFriendGroupListUpdated);
          
          // // FRIEND_APPLICATION_LIST_UPDATED
          // let onFriendApplicationListUpdated = function(event) {
          //   // friendApplicationList - 好友申请列表 - [FriendApplication]
          //   // unreadCount - 好友申请的未读数
          //   const { friendApplicationList, unreadCount } = event.data;
          //   // 发送给我的好友申请（即别人申请加我为好友）
          //   const applicationSentToMe = friendApplicationList.filter((friendApplication) => friendApplication.type === TIM.TYPES.SNS_APPLICATION_SENT_TO_ME);
          //   // 我发送出去的好友申请（即我申请加别人为好友）
          //   const applicationSentByMe = friendApplicationList.filter((friendApplication) => friendApplication.type === TIM.TYPES.SNS_APPLICATION_SENT_BY_ME);
          // };
          // state.tim.on(TIM.EVENT.FRIEND_APPLICATION_LIST_UPDATED, onFriendApplicationListUpdated);
          
          // 用户被踢下线时触发
          let onKickedOut = function() {
            commit("setState", { isLoginOutIm: true });
            dispatch("loginOutTm");
            router.push('/login');
            // TIM.TYPES.KICKED_OUT_MULT_ACCOUNT,//(Web端，同一帐号，多页面登录被踢)
            // TIM.TYPES.KICKED_OUT_MULT_DEVICE//(同一帐号，多端登录被踢)
            // TIM.TYPES.KICKED_OUT_USERSIG_EXPIRED(签名过期)
            // TIM.TYPES.KICKED_OUT_REST_API(REST API kick 接口踢出。v2.20.0起支持)
          };
          state.tim.on(TIM.EVENT.KICKED_OUT, onKickedOut);
          
          // 网络状态发生改变
          let onNetStateChange = function(event) {
            switch (event.data.state) {
              case TIM.TYPES.NET_STATE_CONNECTED: //- 已接入网络
                break;
              case TIM.TYPES.NET_STATE_CONNECTING: // 连接中
                break;
              case TIM.TYPES.NET_STATE_DISCONNECTED: // 未接入网络
                break;
              default:
                break;
            }
            // v2.5.0 起支持
            // event.data.state 当前网络状态，枚举值及说明如下：
            // TIM.TYPES.NET_STATE_CONNECTED - 已接入网络
            // TIM.TYPES.NET_STATE_CONNECTING - 连接中。很可能遇到网络抖动，SDK 在重试。接入侧可根据此状态提示“当前网络不稳定”或“连接中”
            // TIM.TYPES.NET_STATE_DISCONNECTED - 未接入网络。接入侧可根据此状态提示“当前网络不可用”。SDK 仍会继续重试，若用户网络恢复，SDK 会自动同步消息
          };
          state.tim.on(TIM.EVENT.NET_STATE_CHANGE, onNetStateChange);
      },
      // 登进
      loginTm({state, commit, dispatch, rootState}) {
        if (state.tim && state.tim.login) {
          let promise = state.tim.login({
            userID: String(rootState.member.uid),
            userSig: rootState.member.imToken
          });
  
          promise.then(function(imResponse) {
            commit("setState", { isLoginTm: true });

            if (router.currentRoute._value.path == '/liveRoom') {
              dispatch("joinGroup", String(rootState.liveInfo.liveId));
            } else {
              if(state.leftMenu.length) dispatch("joinGroup", String(state.leftMenu[0].roomId));
            }

            if (imResponse.data.repeatLogin === true) {
              // 标识帐号已登录，本次登录操作为重复登录。v2.5.1 起支持
              console.log(imResponse.data.errorInfo);
              // dispatch("loginOutTm");
              // setTimeout(() => {
              //   dispatch("loginTm");
              // }, 10);
            }
          }).catch(function(imError) {
            console.warn('login error:', imError); // 登录失败的相关信息
          });
        } else {
          dispatch("initTm");
        }
        
      },
      async joinGroup({ state, commit, rootState }, roomId) {
        try {
          const imResponse = await state.tim.joinGroup({ groupID: roomId, type: TIM.TYPES.GRP_AVCHATROOM });
      
          switch (imResponse.data.status) {
            case TIM.TYPES.JOIN_STATUS_WAIT_APPROVAL:
              // Handle waiting for approval case
              break;
            case TIM.TYPES.JOIN_STATUS_SUCCESS:
              if (rootState.liveInfo && rootState.liveInfo.liveId) {
                const arr = [];
                const firstIndex = state.adverts.findIndex(item => item.type === 4);
                const interUserInfo = JSON.parse(JSON.stringify(rootState.member));
                const interUserArr = [];
      
                if (firstIndex !== -1) {
                  arr.push({
                    protocol: 13,
                    content: state.adverts[firstIndex].content.replace(/\n/g,'<br/>'),
                    msgId: new Date().getTime(),
                  });
                }
      
                arr.push({ protocol: -2, msgId: new Date().getTime() + 1 });
                commit("setState", { chat: arr });
      
                if (state.member && state.member.userLevel && state.member.userLevel >= 6) {
                  interUserArr.push(interUserInfo);
                  commit("setState", { interUserArr });
                }
              }
              break;
            case TIM.TYPES.JOIN_STATUS_ALREADY_IN_GROUP:
              // Handle already in the group case
              break;
            default:
              break;
          }
        } catch (imError) {
          console.warn('joinGroup error:', imError);
          // Handle the error case
        }
      },
      // 获取已经加入的群
      getGroupList({ dispatch, rootState }, joinGroupID) {
        // getJoinGroupList(rootState.member.uid).then(res => {
        //   if (res.code === 0 || res.code === '0') {
        //     if (res.data && res.data.length > 0) {
        //       res.data.forEach(item => {
        //         dispatch("quitGroup", item);
        //       });

        //       dispatch("joinGroup", joinGroupID);
        //     }
        //   } else {
        //     if (res.msg) Toast(res.msg);
        //   }
        // }).catch(() => {})
        let promise = rootState.tim.getGroupList({
          groupProfileFilter: [TIM.TYPES.GRP_PROFILE_OWNER_ID],
        });
        promise.then(function(imResponse) {
         if (imResponse.data.groupList.length > 0) {
          let obj = {
            leaveGroupID: String(imResponse.data.groupList[0].groupID)
          }
          if (joinGroupID) obj.joinGroupID = joinGroupID;

          if(String(imResponse.data.groupList[0].groupID) != joinGroupID) {
            dispatch("quitGroup", obj);
          }
         } else {
          if (joinGroupID) dispatch("joinGroup", joinGroupID);
         }
        }).catch(function(imError) {
         console.warn('getGroupList error:', imError); // 获取群组列表失败的相关信息
        });
      },
      // 退群 , dispatch
      quitGroup({ state, commit, dispatch }, params) {
        if (!state.tim || !state.tim && !state.tim.quitGroup) return;
        let promise = state.tim.quitGroup(params.leaveGroupID);
        promise.then(function() {
          commit("setState", { chat: [] });
          dispatch("goOuterRoom", params.leaveGroupID);
          if (params.joinGroupID) dispatch("joinGroup", params.joinGroupID);

        }).catch(function(imError){
          console.warn('quitGroup error:', imError); // 退出群组失败的相关信息
        });
      },
      // 直播间退房接口
      // eslint-disable-next-line no-empty-pattern
      goOuterRoom({ state, rootState }, leaveGroupID) {
        let leftMenu = state.leftMenu ? state.leftMenu : [],
        index = leftMenu.findIndex(item => {
          return item.roomId == leaveGroupID;
        });

        if (index == -1) {
          let uid = localStorage.getItem('uid') || utils.guid(),
          timestamp = new Date().getTime(),
          params = {
            domain: '',
            liveId: leaveGroupID,
            os: 0,
            sign: md5(`${uid}jgyh,kasd${timestamp}`),
            timestamp: timestamp,
            udid: uid,
            uid: rootState.member.uid
          }
          outerRoom(params).then(() => {}).catch(() => {})
        }
      },
      // 登出
      async loginOutTm({ state, commit }) {
        let promise = state.tim.logout();
        promise.then(function() {
          commit("setState", { member: null, letterList: [], systemLetterLists: [] });
          sessionStorage.setItem('token', '');
        }).catch(function(imError) {
          console.warn('logout error:', imError);
        });
      },
      // 销毁
      timDestroy({ commit, rootState }) {
        if (!rootState.tim || (rootState.tim && !rootState.tim.destroy)) return;
        let promise = rootState.tim.destroy();
        promise.then(function(imResponse) {
          console.log(imResponse.data); // 登出成功
          commit("setState", { tim: null, member: null });
        }).catch(function(imError) {
          console.warn('logout error:', imError);
        });
      },
      // 消息处理
      receiveMessage({state, commit, dispatch, rootState }, message) {
        if (message.payload.text && utils.isJSON(message.payload.text)) {
          let msg = JSON.parse(message.payload.text), protocol = msg.protocol || 0;
          switch(protocol) {
            // 关播
            case 2:{
              let offRoomInfo = { ...msg, ...state.liveInfo };
              commit("setState", { betPramas: offRoomInfo })
              router.push('/offRoom');
              break;
            }
            // 主播离开或返回
            case 3:
              dispatch('addChat', msg);
              break;
            // 关注
            case 4:{
              let index = state.chat.length > 0 ? state.chat.findIndex(item => {
                return item.uid == msg.uid && item.protocol == msg.protocol
              }) : -1;

              if (index == -1) {
                dispatch('addChat', msg);
              }
              break;
            }
            //进房或退房 
            case 5:{
              if (msg.isInter) {
                let flag = state.chat[state.chat.length - 1].uid && state.chat[state.chat.length - 1].uid == msg.uid;
                if (!flag) {
                  dispatch('addChat', msg);
                }

                let avatarIndex = state.avatarList.findIndex(item => {
                  return item.uid == msg.uid
                });

                if (avatarIndex == -1) {
                  let avatarList = state.avatarList.length > 0 ? JSON.parse(JSON.stringify(state.avatarList)): [];

                  if (avatarList.length < 10) {
                    avatarList.push(msg);
                    commit("setState", { avatarList });
                  }
                }

                // 多人进房
                let interIndex = state.interUserArr.findIndex(item => {
                  return item.uid == msg.uid
                });
                if (interIndex == -1 && msg.userLevel && msg.userLevel >= 6) {
                  let interUserArr = state.interUserArr.length > 0 ? JSON.parse(JSON.stringify(state.interUserArr)) : [];
                  if (interUserArr <= 30) {
                    interUserArr.push(msg);
                    commit("setState", { interUserArr });
                  }
                }

                // if (msg.carId) {
                //   let svgGift = state.svgGift.length > 0 ? JSON.parse(JSON.stringify(state.svgGift)): [];
                //   let aIndex = state.allGift.findIndex(item => {
                //     return item.gid == msg.carId
                //   });

                //   if (aIndex != -1 || svgGift.length > 30) {
                //     svgGift.push({ ...state.allGift[aIndex], ...msg });
                //     commit("setState", { svgGift })
                //   }
                // }
              } else {
                // 处理用户列表逻辑
                let avatarIndex = state.avatarList.findIndex(item => {
                  return item.uid == msg.uid
                });

                if (avatarIndex != -1) {
                  let avatarList = JSON.parse(JSON.stringify(state.avatarList));
                  avatarList.splice(avatarIndex, 1);
                  commit("setState", { avatarList });
                }

                // 处理连麦退房逻辑
                // let voiceUserList = JSON.parse(JSON.stringify(state.voiceUserList)),
                // index = voiceUserList.findIndex(item => {
                //   return item.uid == msg.uid;
                // });

                // if (index != -1) {
                //   voiceUserList.splice(index, 1);
                //   commit("setState", { voiceUserList });
                // }
              }
              break;
            }
            //主播或用户 升级 
            case 6:
              // levelType 1 主播  2 用户
              if (msg.levelType == 1) {
                commit("setState", { anchorUpgrade: msg })
              } else if(msg.levelType == 2) {
                commit("setState", { audienceUpgrade: msg })
                let obj = JSON.parse(JSON.stringify(rootState.member));
                obj.userLevel = msg.level;
                commit("setState", { member: obj });
              }
              
              break;
            //送礼消息
            case 7: {
                let giftMsg = JSON.parse(JSON.stringify(msg)),
                luxuryGift = state.luxuryGift.length > 0 ? JSON.parse(JSON.stringify(state.luxuryGift)) : [],
                liveInfo = JSON.parse(JSON.stringify(state.liveInfo)),
                svgGift = state.svgGift.length > 0 ? JSON.parse(JSON.stringify(state.svgGift)) : [],
                comboGift = state.comboGift.length > 0 ? JSON.parse(JSON.stringify(state.comboGift)) : [];

                liveInfo.rq = msg.rq;
                liveInfo.zb = msg.zb;
                liveInfo.playType = msg.playType;
                let getGiftIndex = state.allGift.findIndex(item => {
                  return item.gid === msg.gid;
                });
                if (getGiftIndex != -1) {
                  giftMsg.gname = state.allGift[getGiftIndex].gname;
                  giftMsg.cover = state.allGift[getGiftIndex].cover;

                  if (msg.tipType) {
                    luxuryGift.push({ ...state.allGift[getGiftIndex], ...msg });
                    commit("setState", { luxuryGift })
                  }
                  if ((state.allGift[getGiftIndex].playType == 1 || state.allGift[getGiftIndex].playType == 2) && msg.liveId == state.liveInfo.liveId) {
                    svgGift.push({ ...state.allGift[getGiftIndex], ...msg });
                    commit("setState", { svgGift })
                  }

                  let comboGiftIndex = comboGift.findIndex(item => {
                    return item.gid == msg.gid && item.uid == msg.uid;
                  });

                  msg.timeNum = 3000;
                  if (comboGiftIndex == -1) {
                    comboGift.push({ ...state.allGift[getGiftIndex], ...msg });
                  } else {
                    msg.combo = state.comboGift[comboGiftIndex].combo + 1;
                    comboGift.splice(comboGiftIndex, 1, { ...state.allGift[getGiftIndex], ...msg });
                  }
                }

                if (msg.liveId == state.liveInfo.liveId) {
                  commit("setState", { liveInfo, comboGift })
                  dispatch('addChat', giftMsg);
                }
              break;
            }
            //禁言和取消禁言消息
            case 8:
              dispatch('addChat', msg);
              break;
            //直播间聊天
            case 9:
              if (msg.uid != rootState.member.uid) {
                dispatch('addChat', msg);
              }
              break;
            //系统公告
            case 10:
              break;
            case 11: {  //私信
              if (state.privateLetterUid && state.privateLetterUid == msg.uid && msg.from == 2) {
                let letterList = state.letterList.length > 0 ? JSON.parse(JSON.stringify(state.letterList)) : [];
                let obj = {
                  avatar: msg.avatar,
                  content: msg.content,
                  letterId: msg.letterId,
                  nickname: msg.nickname,
                  sendTime: msg.timestamp,
                  uid: msg.uid,
                  selfFlag: 0
                }

                letterList.push(obj);
                commit("setState", { letterList });
                console.log(state.letterList);
              } else {
                commit("setState", { isUnreadMsgFlag: true });
              }
              // commit("setState", { isUnreadMsgFlag: true });
              // if (rootState.member.uid && String(rootState.member).isUnreadMsg !== '0' && msg.from == 2) {
              //   let letterList = state.letterList.length > 0 ? JSON.parse(JSON.stringify(state.letterList)) : [];
              //   let obj = {
              //     avatar: msg.avatar,
              //     content: msg.content,
              //     letterId: msg.letterId,
              //     nickname: msg.nickname,
              //     timestamp: msg.timestamp,
              //     type: 2,
              //     otherUid: msg.uid,
              //     sendUid: msg.uid,
              //     isUnreadMsg: 0
              //   }

              //   if (state.isUnreadMsgFlag && state.betPramas.uid == msg.uid) {
              //     obj.isUnreadMsg = 1;
              //   }

              //   letterList.push(obj);
              //   commit("setState", { letterList });
              // }

              // if (rootState.member.uid && msg.from == 1) {
              //   let systemLetterLists  = state.systemLetterLists.length > 0 ? JSON.parse(JSON.stringify(state.systemLetterLists)) : [];
              //   let obj = {
              //     avatar: msg.avatar,
              //     content: msg.content,
              //     letterId: msg.letterId,
              //     nickname: msg.nickname,
              //     timestamp: msg.timestamp,
              //     type: 2,
              //     otherUid: msg.uid,
              //     sendUid: msg.uid,
              //     isUnreadMsg: 0
              //   }

              //   if (state.isUnreadMsgFlag && state.betPramas.uid == msg.uid) {
              //     obj.isUnreadMsg = 1;
              //   }

              //   systemLetterLists.unshift(obj);
              //   commit("setState", { systemLetterLists });
              // }
              break;
            } 
            case 12: {  //账户金币变动
              if (msg.uid == rootState.member.uid) {
                let obj = JSON.parse(JSON.stringify(rootState.member));
                obj.goldCoin = msg.goldCoin ? utils.keepTwoDecimalFull(msg.goldCoin) : '0.00';
                commit("setState", { member: obj });
              }
              break;
            }
            //直播间公告
            case 13:
              dispatch('addChat', msg);
              break;
            //强制关播
            case 14:
              // eslint-disable-next-line no-case-declarations
              let offRoomInfo = { ...msg, ...state.liveInfo };
              commit("setState", { betPramas: offRoomInfo })
              router.push('/offRoom');
              break;
            //直播间踢人消息
            case 15: {
              if (msg.uid != rootState.member.uid) {
                dispatch('addChat', msg);
              } else {
                let offRoomInfo = { ...msg, ...state.liveInfo };
                commit("setState", { betPramas: offRoomInfo })
                router.push('/offRoom');
                dispatch('refreshUserInfo');
              }
              break;
            }
            //更新礼物资源消息
            case 16:
              dispatch('configProp');
              break;
            //守护购买成功
            case 17:
              break;
            //PK开启/关闭消息
            case 18:{
              let liveInfo = JSON.parse(JSON.stringify(state.liveInfo));
              liveInfo.pking = true;
              commit("setState", { liveInfo })
              break;
            }
            //PK请求响应消息
            case 19:
              break;
            //发放补贴成功消息
            case 20:
              break;
            //房间价格变动
            case 21:
              let liveInfo = JSON.parse(JSON.stringify(state.liveInfo));
              if (liveInfo.liveId == msg.liveId) {
                if (msg.type == 0){
                  msg.content = "วิจีได้เปลี่ยนเป็นห้องฟรี";
                }else if (msg.type == 1){
                  msg.content = `วิจีได้เปลี่ยนเป็นห้องจับเวลา ${msg.price} เหรียญ/ครั้ง`;
                }else if (msg.type == 2){
                  msg.content = `วิจีได้เปลี่ยนเป็นห้องชำระเงิน ${msg.price} เหรียญ/ครั้ง`;
                }else if (msg.type == 3){
                  msg.content = "วิจีได้เปลี่ยนเป็นห้องล็อต รหัส，กรุณากรอกรหัสผ่านเพื่อรับชมต่อ";
                }
                liveInfo.type = msg.type;
                if (msg.price) liveInfo.price = msg.price;
                commit("setState", { liveInfo });
                dispatch('addChat', msg);
              }
              break;
            //取消PK匹配
            case 22:
              break;
            //PK结果消息
            case 23:
              break;
            //PK比分变动消息
            case 24:
              break;
            //游戏跑马灯(飘屏)（彩票中奖消息）
            case 25: {
              if (msg.content) {
                let winLotteryScreen = state.winLotteryScreen.length > 0 ? JSON.parse(JSON.stringify(state.winLotteryScreen)) : [];
                winLotteryScreen.push(msg)
                commit("setState", { winLotteryScreen });
              }
              break;
            }
            case 26: //下注
              dispatch('addChat', msg);
              break;
            case 227: //中奖 消息体展示
              if (msg.uid == rootState.member.uid) {
                const audio = document.querySelector("#lotteryWinningSound");
                audio.play();
              }
              break;
            case 2227: { //中奖 消息体展示
              if (msg.cpMsgList && msg.cpMsgList.length > 0) {
                msg.cpMsgList.forEach(item => {
                  let obj = JSON.parse(JSON.stringify(item));
                  obj.protocol = msg.protocol;
                  if (item.name != 'สลากกินแบ่ง thlive' && item.name != 'สลากกินแบ่งรัฐบาล' && item.name != 'หวยไทย 3 นาที') {
                    dispatch('addChat', obj);
                  }
                })

                if (msg.cpMsgList[0].uid == rootState.member.uid) {
                  const audio = document.querySelector("#lotteryWinningSound");
                  audio.play();
                }
              }
              break;
            } 
            //设置或取消房管消息
            case 28: {
              let objLiveInfo = JSON.parse(JSON.stringify(state.liveInfo));
              if (msg.type === 1) {
                objLiveInfo.roomManager = true;
              } else {
                objLiveInfo.roomManager = false;
              }
              commit("setState", { liveInfo: objLiveInfo });
              dispatch('addChat', msg);
              break;
            }
            case 29: { //开奖
              // 直播间
              if (router.currentRoute._value.path == '/liveRoom') {
                dispatch("liveRoomLotteryScreen", msg);
              } else {
                // 彩票大厅的 
                dispatch("setResult", msg);
              }
              break;
            } 
            case 30: { //开奖
              if (router.currentRoute._value.path == '/liveRoom') {
                dispatch("liveRoomLotteryScreen", msg)
              } else {
                dispatch("setResult", msg);
              }
              break;
            }
            case 31: { //pk 旧版本 暂不做处理
              if (msg.code == 2) { //进行时
                commit("setState", { pkInfo: msg })
              }
              if (msg.code == 5) { //取消混流
                let liveInfo = JSON.parse(JSON.stringify(state.liveInfo));
                liveInfo.pking = false;
                commit("setState", { liveInfo })
              }
              
              break;
            }
            //关闭声音
            case 32:
              break;
            case 40: { //期号更新
              let obj = JSON.parse(JSON.stringify(state.issue));
              obj.expect = msg.expect;
              commit("setState", { issue: obj, countTime: msg.down_time });
              break;
            }

            case 42: { //主播开启、结束连麦
              let obj = JSON.parse(JSON.stringify(state.liveInfo));
              if(msg.liveId == obj.liveId) {
                obj.voiceBase = msg;
                obj.voiceBase.isVoice = msg.status;
                commit("setState", { liveInfo: obj });

                if (msg.status == 1) {
                  dispatch('addChat', msg);
                }

                if ((msg.status == 2 && msg.changeType) || (msg.status == 2 && !msg.changeType && msg.priceChange == 1)) {
                  dispatch('addChat', msg);
                }
              }
              break;
            }

            case 43: { //用户上麦、下麦
              if(msg.liveId == state.liveInfo.liveId) {
                let voiceUserList = JSON.parse(JSON.stringify(state.voiceUserList));
                let index = voiceUserList.findIndex(item => {
                  return item.uid === msg.userId;
                });
                let obj = {};
                if (msg.status) {
                  obj = {
                    banStatus: 0,
                    nickname: msg.userNickName,
                    avatar: msg.userAvatar,
                    uid: msg.userId,
                    roomHide: msg.roomHide,
                    voiceRecordId: msg.voiceRecordId,
                    pullSource: msg.pullSource,
                    sortNum: msg.sortNum
                  }
                }

                if (index == -1) {
                  if (msg.status && voiceUserList.length < state.liveInfo.voiceBase.num) voiceUserList.push(obj);
                } else {
                  if (msg.status) {
                    voiceUserList.splice(index, 0, obj);
                  } else {
                    voiceUserList.splice(index, 1);
                  }
                }
                commit("setState", { voiceUserList });
              }
              
              break;
            }

            case 44: { //连麦申请、同意、拒绝、取消、退出、踢出、付费修改、结束  只处理主播连麦申请
              if (msg.from && state.liveInfo.liveId == msg.liveId && msg.type === 0) {
                commit("setState", { anchorInvit: true, anchorInvitObj: msg });
              }
              
              break;
            }

            case 45: { //用户禁言、解除禁言
              if (msg.liveId == state.liveInfo.liveId) {
                let voiceUserList = JSON.parse(JSON.stringify(state.voiceUserList)),
                index = voiceUserList.findIndex(item => {
                  return item.uid === msg.targetId;
                });
                if (index != -1) {
                  voiceUserList[index].banStatus = msg.banStatus;
                  commit("setState", { voiceUserList });
                }
                
              }
              
              break;
            }

            case 48: { //通知直播间开始PK，结束PK
              if (msg.liveId == state.liveInfo.liveId) {
                let pkDataDetail = JSON.parse(JSON.stringify(state.pkDataDetail));
                if (msg.status) {
                  pkDataDetail.liveForEndSeconds = msg.pkLiveSeconds;
                  pkDataDetail.punishForEndSeconds = msg.pkPunishSeconds;
                  pkDataDetail.pkStatus = 2;
                  pkDataDetail.pkId = msg.pkId;
                } else {
                  pkDataDetail.pkStatus = 1;
                  commit("setState", { svgGift: [], comboGift: [] }); //pk 结束需要清除礼物特效
                }

                if (pkDataDetail.pkTeamList && pkDataDetail.pkTeamList.length > 0) {
                  pkDataDetail.pkTeamList.forEach(item => {
                    item.anchorScore = 0;
                    item.anchorRank = 0;
                    item.teamRank = 0;
                    item.teamScore = 0;
                    item.result = -1;
                  });
                }

                commit("setState", { pkDataDetail });
              }
              break;
            }

            case 49: { //主播加入，退出，修改队伍，修改队伍类型
              if (msg.liveId == state.liveInfo.liveId) {
                let flag = msg.status == 4 ? false : true,
                liveInfo = JSON.parse(JSON.stringify(state.liveInfo));
                if (flag) {
                  let pkDataDetail = JSON.parse(JSON.stringify(state.pkDataDetail));
                  pkDataDetail.reqId = msg.reqId;
                  pkDataDetail.teamType = msg.teamType;

                  if (pkDataDetail.pkTeamList && pkDataDetail.pkTeamList.length > 0) {
                    msg.pkTeamList.forEach( item => {
                      let index = pkDataDetail.pkTeamList.findIndex(xitem => {
                        return xitem.anchorId == item.anchorId;
                      })
  
                      if (index != -1) {
                        item.anchorScore = msg.pkNewStatusMap && msg.pkNewStatusMap[item.anchorId] ? msg.pkNewStatusMap[item.anchorId].anchorScore : pkDataDetail.pkTeamList[index].anchorScore;
                        item.anchorRank = msg.pkNewStatusMap && msg.pkNewStatusMap[item.anchorId] ? msg.pkNewStatusMap[item.anchorId].anchorRank : pkDataDetail.pkTeamList[index].anchorRank;
                        item.teamRank = msg.pkNewStatusMap && msg.pkNewStatusMap[item.anchorId] ? msg.pkNewStatusMap[item.anchorId].teamRank : pkDataDetail.pkTeamList[index].teamRank;
                        item.teamScore = msg.pkNewStatusMap && msg.pkNewStatusMap[item.anchorId] ? msg.pkNewStatusMap[item.anchorId].teamScore : pkDataDetail.pkTeamList[index].teamScore;
                        item.result = pkDataDetail.pkTeamList[index].result;
                      }
                    })
                  }

                  pkDataDetail.pkTeamList = JSON.parse(JSON.stringify(msg.pkTeamList));
                  
                  if (!liveInfo.pking) {
                    liveInfo.pking = true;
                  }
                  commit("setState", { pkDataDetail, liveInfo });
                  
                } else {
                  liveInfo.pking = false;
                  commit("setState", { pkDataDetail: {}, liveInfo, svgGift: [], comboGift: [] });
                }
              }
              
              
              break;
            }

            case 50: { //PK比分变动和结果通知
              if (msg.liveId == state.liveInfo.liveId) {
                let pkDataDetail = JSON.parse(JSON.stringify(state.pkDataDetail)),
                pkTeamList = pkDataDetail.pkTeamList;

                pkTeamList.forEach(item => {
                  item.anchorScore = msg.pkNewStatusMap[item.anchorId].anchorScore;
                  item.anchorRank = msg.pkNewStatusMap[item.anchorId].anchorRank;
                  item.teamRank = msg.pkNewStatusMap[item.anchorId].teamRank;
                  item.teamScore = msg.pkNewStatusMap[item.anchorId].teamScore;
                  if (msg.pkNewStatusMap[item.anchorId].result !== undefined) item.result = msg.pkNewStatusMap[item.anchorId].result;
                });

                pkDataDetail.teamMap = msg.teamMap;


                if (msg.status == 2) {
                  pkDataDetail.pkStatus = 3;
                  let index = pkTeamList.findIndex(item => {
                    return item.anchorId == state.liveInfo.anchorId;
                  });

                  if (index != -1) {
                    pkDataDetail.result = pkTeamList[index].result;
                  }
                }

                pkDataDetail.pkTeamList = JSON.parse(JSON.stringify(pkTeamList));
                commit("setState", { pkDataDetail });
              }
              break;
            }

            case 51: {
              if (msg.liveId == state.liveInfo.liveId) {
                let pkDataDetail = JSON.parse(JSON.stringify(state.pkDataDetail)),
                  pkTeamList = pkDataDetail.pkTeamList,
                  index = pkTeamList.findIndex(item => {
                    return item.anchorId == msg.targetAnchorId
                  });

                  if (index != -1) {
                    pkTeamList[index].banStatus = msg.banStatus;
                    pkDataDetail.pkTeamList = JSON.parse(JSON.stringify(pkTeamList));
                    commit("setState", { pkDataDetail });
                  }
              }
              break;
            }

            case 61: { //红包雨开始
              dispatch("refreshRedPacket");
              break;
            }
            case 62: { //红包雨结束
              dispatch("refreshRedPacket");
              break;
            }
            case 63: { //红包雨更改
              dispatch("refreshRedPacket");
              break;
            }
            case 64: { //是否支持h5观看
              let liveInfo = JSON.parse(JSON.stringify(state.liveInfo));
              liveInfo.webStatus = msg.newWebStatus;
              commit("setState", { liveInfo });
              break;
            }
            case 88: { 
              commit("setState", { loginOutInfo: msg });
              break;
            }
            default:
              // 默认
              break;
          } 
        }
      },
      // 红包雨相关信息
      refreshRedPacket({ commit, rootState }) {
        redJoinRoom(rootState.member.uid).then(res => {
          if (res.code === 0 || res.code === '0') {
            //activityStatus 1: 活动没有开始 2:活动进行中红包已经领完 3:活动正常进行中
            commit("setState", { redPacket: res.data });
          } else {
            commit("setState", { redPacket: {} });
          }
      }).catch(() => {
        commit("setState", { redPacket: {} });
      })
      },
      // 接口获取历史记录
      initHistory({ commit }, name) {
        let params = {
          issue: "",
          lotteryName: name,
          startTime: new Date().getTime(),
          page: 0,
          status: ""
        }
        getLotteryResultHistoryByName(params).then(res => {
          if (res.code == 0) {
            let arr = JSON.parse(JSON.stringify(res.data));
            arr.forEach(item => {
              item.resultList = item.lotteryResult;
            });
            commit("setState", { [`${name}History`]: arr });
          } else {
            if (res.msg) Toast(res.msg);
          }
        }).catch(err => {
          console.log(err);
        });
      },
      // 获取彩票期号
      getissue({ commit }, name) {
        getissue({ name }).then(res => {
          if (res.code === 0 || res.code === '0') {
            commit("setState", { issue: res.data[0], countTime: res.data[0].down_time });
            // dispatch('goDown', res.data[0].down_time);
          } else {
            if (res.msg)  Toast(res.msg);
          }
        }).catch(err => {
            console.log(err);
        });
      },
      // 获取基础信息配置
      async getBaseInfo({commit}) {
          getBaseInfo().then(res => {
            if (res.code === 0 || res.code === '0') {
              commit("setState", { baseInfo: res.data });
            } else {
              if (res.msg) Toast(res.msg);
            }
          }).catch(err => {
            console.log(err);
          });
      },
      getLeftMenu({state, commit}) {
        cpList().then(res => {
            if (res.code == 0) {
              let arr = JSON.parse(JSON.stringify(res.data));
              arr.forEach(item => {
                let index = state.lotteryRouter.findIndex(xitem => {
                  return xitem == item.name
                });

                if (index != -1) {
                  item.link = `/lottery/${item.name}`;
                }
              });
              arr = arr.filter(item => {
                return item.link;
              });
              commit("setState", { leftMenu: arr });
            } else {
              if (res.msg) Toast(res.msg);
            }
          }).catch(err => {
            console.log(err);
          });
      },
      // 开奖彩票数据处理
      setResult({ state, commit }, msg) {
        let arr = JSON.parse(JSON.stringify(state[`${msg.name}History`]));
        let index = arr.findIndex(item => {
          return item.expect == msg.expect;
        });

        if (index == -1) {
          if (arr.length > 0 && Number(arr[0].expect)+1 == Number(msg.expect)) {
            arr.unshift(msg);
          }
        }
        commit("setState", { [`${msg.name}History`]: arr })
      },
      // 添加直播间消息体
      addChat({ state, commit, rootState }, msg) {
        let chat = JSON.parse(JSON.stringify(state.chat)),  badgeShowList = [];
        if (msg.badgeList && msg.badgeList.length > 0 && rootState.configBadge.length > 0) {
          badgeShowList = utils.matchBadgeList(msg.badgeList, rootState.configBadge);
        }
        msg.badgeShowList = JSON.parse(JSON.stringify(badgeShowList));
        msg.msgId = new Date().getTime();
        if (chat.length > 0 && (msg.msgId - chat[chat.length - 1].msgId >= 1500 || msg.uid == rootState.member.uid || msg.protocol == 9 || msg.protocol == 21)) {
          chat.push(msg);
          if (chat.length > 100) {
            chat.shift();
          }
          commit("setState", { chat })
        } else {
          if (chat.length == 0) {
            chat.push(msg);
            commit("setState", { chat })
          }
        }
      },
      // 处理开奖飘屏数据
      liveRoomLotteryScreen({ state, commit }, msg) {
        if (state.liveInfo && state.liveInfo.liveStartLottery && state.liveInfo.liveStartLottery.length > 0) {
          if (state.lotteryRouter.indexOf(state.liveInfo.liveStartLottery[0].cpName) != -1) {
            if (state.liveInfo.liveStartLottery[0].cpName == msg.name) {
              commit("setState", { firstLottery: msg })
            }
          }

          if (state.liveInfo.liveStartLottery.length > 1 &&state.lotteryRouter.indexOf(state.liveInfo.liveStartLottery[1].cpName) != -1) {
            if (state.liveInfo.liveStartLottery[1].cpName == msg.name) {
              commit("setState", { secondLottery: msg })
            }
          }

        }
      }
    }
}

export default tmModule